import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { Message, MessageStatusEnum } from 'api/MessagesApi';
import { SAVE_EXIT_MESSAGE_MODAL_NAME } from 'components/Message/SaveExitMessageModalComponent/SaveExitMessageModalComponent';
import { TextToHtmlComponent } from 'components/UiKit/TextToHtmlComponent/TextToHtmlComponent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuthUser } from 'store/authSlice';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { displayDeliveryDate, getAccessLevelByMessageType, userHasAccess } from 'utils/utils';
import './StepReviewComponent.css';

type AllProps = {
  message: Message;
  onBack: () => void;
  onNext: () => void;
  onClose: (forceClose?: boolean) => void;
  isLoading?: boolean;
  goToMessageInfo: () => void;
  goToDeliveryInfo: () => void;
};

function StepReviewComponent({
  message,
  onBack,
  onNext,
  onClose,
  isLoading,
  goToMessageInfo,
  goToDeliveryInfo
}: AllProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectAuthUser);

  const handleGetCredits = () => {
    onClose();
    navigate('/pricing', { state: { messageId: message.id } });
  };

  const deliveryDate = displayDeliveryDate(message, '');

  return (
    <div className="step-review-component create-message-component-full-height">
      <Space direction="vertical" size={20} className="block-list">
        <div>
          <div className="create-message-component-title">Review your message information.</div>
          <div>
            Are you ready to complete? Please verify the information below. You will be able to edit or delete a
            completed message at any time.
          </div>
        </div>
        <div className="block message-data">
          <div className="title">
            <strong>Message Info</strong> <a onClick={goToMessageInfo}>edit</a>
          </div>
          <div className="content">
            <p>
              <strong>{message.name}</strong>
            </p>
            {message.fileName ? (
              <div className="message-file">
                <span className="selected-file">{message?.fileName}</span>
              </div>
            ) : (
              <TextToHtmlComponent text={message.content} />
            )}
          </div>
        </div>

        <div className="block delivery-data">
          <div className="title">
            <strong>Delivery Info</strong> <a onClick={goToDeliveryInfo}>edit</a>
          </div>

          <div className="content">
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <strong>Deliver on:</strong> {deliveryDate}
              </Col>
              {(message.contacts || []).map((contact) => (
                <Col span={24} key={contact.id}>
                  <strong>Recipient:</strong> {contact?.name} ({contact?.email}){/*<Row key={contact.id}>*/}
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Space>

      <Row className="create-message-component-footer">
        <Col xs={6}>
          <Button onClick={onBack} type="link">
            <ArrowLeftOutlined /> Back
          </Button>
        </Col>
        <Col xs={18} className="text-right">
          <Space>
            {message.status == MessageStatusEnum.Draft && (
              <Button onClick={() => dispatch(openModal({ name: SAVE_EXIT_MESSAGE_MODAL_NAME }))}>Save & Exit</Button>
            )}

            {userHasAccess(user, getAccessLevelByMessageType(message.type)) ? (
              <>
                {message.status == MessageStatusEnum.Draft ? (
                  <Button
                    type="primary"
                    onClick={onNext}
                    loading={isLoading}
                    disabled={!(message?.contacts || []).length}
                  >
                    Complete
                  </Button>
                ) : (
                  <Button type="primary" onClick={() => navigate('/dashboard')} loading={isLoading}>
                    Update
                  </Button>
                )}
              </>
            ) : (
              <Button type="primary" onClick={handleGetCredits}>
                Upgrade Plan
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </div>
  );
}

export default StepReviewComponent;
