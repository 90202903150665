import { createApi } from '@reduxjs/toolkit/query/react';
import { invalidateAdminTags } from 'api/AdminAccessApi';
import { baseQueryWithReAuth } from 'api/Api';
import { invalidateAuthTags, UserGenderEnum } from 'api/AuthApi';

export enum UserStatusEnum {
  ACTIVE = 'active',
  UNVERIFIED_DECEASED = 'unverified_deceased',
  DECEASED = 'deceased'
}

export enum UserAccessLevelPlanEnum {
  Free = 'free',
  Premium = 'premium',
  Unlimited = 'unlimited'
}

export enum UserAccessLevelPeriodEnum {
  Init = 'init',
  OneTime = 'one-time',
  Monthly = 'monthly',
  Yearly = 'yearly'
}

export enum UserAccessLevelStatusEnum {
  Active = 'active',
  Canceling = 'canceling',
  Canceled = 'canceled'
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  gender: UserGenderEnum;
  isInitialized: boolean;
  affiliateCode?: string | null;
  affiliateUser: User | null;
  status: UserStatusEnum;
  accessLevelPlan: UserAccessLevelPlanEnum;
  accessLevelPeriod: UserAccessLevelPeriodEnum;
  accessLevelStatus: UserAccessLevelStatusEnum;
  accessLevelValidUntil: Date | null;
  firstLoginDate: Date | null;
}

export const usersApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'usersApi',
  endpoints: (build) => ({
    initializeMeUser: build.mutation<boolean, void>({
      query: () => ({
        url: '/users/me/initialize',
        method: 'POST'
      }),
      transformResponse: (response: { success: boolean }) => response.success,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    updateAvatarMeUser: build.mutation<boolean, { formData: FormData }>({
      query: ({ formData }) => ({
        url: `/users/me/avatar`,
        method: 'POST',
        body: formData,
        formData: true
      }),
      transformResponse: (response: { success: boolean }) => response.success,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    updateMeUser: build.mutation<
      boolean,
      Pick<User, 'firstName' | 'lastName' | 'gender'> & { password?: string; email?: string; code?: string }
    >({
      query: (body) => ({
        url: `/users/me`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response: { success: boolean }) => response.success,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    updatePartnerMeUser: build.mutation<boolean, { partnerCode?: string }>({
      query: (body) => ({
        url: `/users/me/partner`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { success: boolean }) => response.success,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    changeStatusUser: build.mutation<boolean, { userId: string; status: UserStatusEnum }>({
      query: ({ userId, status }) => ({
        method: 'PATCH',
        url: `/users/${userId}/status`,
        body: { status }
      }),
      transformResponse: (response: { success: boolean }) => response.success,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
          dispatch(invalidateAdminTags());
        } catch (e) {}
      }
    })
  })
});

export const {
  useUpdateMeUserMutation,
  useUpdatePartnerMeUserMutation,
  useInitializeMeUserMutation,
  useUpdateAvatarMeUserMutation,
  useChangeStatusUserMutation
} = usersApi;
