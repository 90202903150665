import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'api/Api';
import { UserAccessLevelPlanEnum } from 'api/UsersApi';
import { invalidateAuthTags } from './AuthApi';

export interface PaymentPlan {
  stripeProductId: string;
  accessLevel: UserAccessLevelPlanEnum;
  name: string;
  extra?: string;
  isPopular?: boolean;
  highlights?: { text: string; disabled?: boolean }[];
  prices: PaymentPlanPrice[];
}

export interface PaymentPlanPrice {
  stripePriceId: string;
  period: 'one-time' | 'monthly' | 'yearly';
  amount: number;
  order: number;
}

export interface SetupIntent {
  clientSecret: string | null;
}

export type PaymentSubscriptionAction = 'switch' | 'downgrade' | 'upgrade' | 'current';

export interface PreviewOneTimePayment {
  total: number;
  lines: {
    amount: number;
    description: string | null;
    type: string;
    unit_amount: number | null;
  }[];
  discount: {
    name: string | null;
    percent_off: number | null;
    amount_off: number | null;
    amount: number | null;
  };
}

export interface PreviewSubscriptionPayment {
  isRenewing: boolean;
  isInTrialPeriod: boolean;
  action: PaymentSubscriptionAction;
  fromPlan: PaymentPlan;
  toPlan: PaymentPlan;
  nextPaymentDate: number | null;
  upgradeTotal?: number;
  upgradeLines?: {
    amount: number;
    description: string | null;
    type: string;
    unit_amount: number | null;
  }[];
}

export interface PaymentMethod {
  id: string;
  type: string;
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  };
}

export const paymentApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'paymentApi',
  tagTypes: ['PaymentPlans', 'PaymentMethods'],
  endpoints: (build) => ({
    getPaymentPlans: build.query<PaymentPlan[], void>({
      query: () => ({ url: `/payment/plans` }),
      transformResponse: (response: { data: PaymentPlan[] }) => response.data,
      providesTags: () => ['PaymentPlans']
    }),
    getPaymentMethods: build.query<PaymentMethod[], void>({
      query: () => ({ url: `/payment/payment-methods` }),
      transformResponse: (response: { data: PaymentMethod[] }) => response.data,
      providesTags: () => ['PaymentMethods']
    }),
    deletePaymentMethods: build.mutation<boolean, { id: string }>({
      query: ({ id }) => ({
        url: `/payment/payment-method/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { data: { deleted: boolean } }) => response.data.deleted,
      invalidatesTags: () => [{ type: 'PaymentMethods', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    createSetupIntent: build.mutation<SetupIntent, { email?: string }>({
      query: (body) => ({
        url: `/payment/setup-intent`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: SetupIntent }) => response.data
    }),
    cancelSubscription: build.mutation<boolean, void>({
      query: () => ({
        url: `/payment/subscription/cancel`,
        method: 'POST'
      }),
      transformResponse: (response: { data: boolean }) => response.data
    }),
    resumeSubscription: build.mutation<boolean, void>({
      query: () => ({
        url: `/payment/subscription/resume`,
        method: 'POST'
      }),
      transformResponse: (response: { data: boolean }) => response.data
    }),
    subscriptionInfo: build.query<any, void>({
      query: (params) => ({ url: `/payment/subscription/info`, params }),
      transformResponse: (response: { data: any }) => response.data
    }),
    previewSubscriptionPayment: build.query<PreviewSubscriptionPayment, { priceId: string }>({
      query: (params) => ({ url: `/payment/subscription/preview`, params }),
      transformResponse: (response: { data: PreviewSubscriptionPayment }) => response.data
    }),
    previewOneTimePayment: build.query<PreviewOneTimePayment, { priceId: string; couponName?: string }>({
      query: (params) => ({ url: `/payment/onetime/preview`, params }),
      transformResponse: (response: { data: PreviewOneTimePayment }) => response.data
    }),
    oneTimePayment: build.mutation<boolean, { paymentMethod: string; priceId: string; couponName?: string }>({
      query: (body) => ({
        url: `/payment/onetime/payment`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: boolean }) => response.data,
      invalidatesTags: () => [{ type: 'PaymentMethods', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    subscriptionPayment: build.mutation<boolean, { paymentMethod: string; priceId: string }>({
      query: (body) => ({
        url: `/payment/subscription/payment`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: boolean }) => response.data,
      invalidatesTags: () => [{ type: 'PaymentMethods', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    createGiftSetupIntent: build.mutation<SetupIntent, { email: string }>({
      query: (body) => ({
        url: `/payment/gift/setup-intent`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: SetupIntent }) => response.data
    }),
    previewGiftPayment: build.query<PreviewOneTimePayment, { priceId: string; couponName?: string }>({
      query: (params) => ({ url: `/payment/gift/preview`, params }),
      transformResponse: (response: { data: PreviewOneTimePayment }) => response.data
    }),
    giftPayment: build.mutation<
      boolean,
      {
        paymentMethod: string;
        priceId: string;
        couponName?: string;
        recipientName: string;
        recipientEmail: string;
        sendDate: string;
        senderEmail: string;
        senderName?: string;
        senderText?: string;
      }
    >({
      query: (body) => ({
        url: `/payment/gift/payment`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: boolean }) => response.data,
      invalidatesTags: () => [{ type: 'PaymentMethods', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    })
  })
});

export const {
  useGetPaymentPlansQuery,
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodsMutation,
  useSubscriptionInfoQuery,
  useCancelSubscriptionMutation,
  useResumeSubscriptionMutation,
  useCreateSetupIntentMutation,
  usePreviewOneTimePaymentQuery,
  usePreviewSubscriptionPaymentQuery,
  useOneTimePaymentMutation,
  useSubscriptionPaymentMutation,
  useCreateGiftSetupIntentMutation,
  usePreviewGiftPaymentQuery,
  useGiftPaymentMutation
} = paymentApi;
