import { ArrowLeftOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Row } from 'antd';
import {
  Message,
  MessageStatusEnum,
  MessageTemplate,
  MessageTypeEnum,
  UpsertMessage,
  useGetOneMessageQuery,
  useGetUploadSignedUrlMessageMutation,
  usePublishMessageMutation,
  useUpsertMessageMutation
} from 'api/MessagesApi';
import imageModalCongratulations from 'assets/modal-congratulations.svg';
import axios, { AxiosProgressEvent } from 'axios';
import confetti from 'canvas-confetti';
import StepEventComponent, {
  StepEventData
} from 'components/Message/CreateMessageComponent/StepEventComponent/StepEventComponent';
import StepMessageComponent, {
  StepMessageData
} from 'components/Message/CreateMessageComponent/StepMessageComponent/StepMessageComponent';
import StepRecipientComponent, {
  StepRecipientData
} from 'components/Message/CreateMessageComponent/StepRecipientComponent/StepRecipientComponent';
import StepReviewComponent from 'components/Message/CreateMessageComponent/StepReviewComponent/StepReviewComponent';
import StepTitleComponent, {
  StepTitleData
} from 'components/Message/CreateMessageComponent/StepTitleComponent/StepTitleComponent';
import SaveExitMessageModalComponent, {
  SAVE_EXIT_MESSAGE_MODAL_NAME
} from 'components/Message/SaveExitMessageModalComponent/SaveExitMessageModalComponent';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { logging } from 'utils/utils';
import './CreateMessageComponent.css';

export const enum CreateMessageStepsEnum {
  Recipient,
  Event,
  Message,
  Title,
  Review,
  Confirm,
  Success
}

type AllProps = {
  messageId?: string;
  messageType?: MessageTypeEnum;
  messageTemplate?: MessageTemplate;
  step?: CreateMessageStepsEnum;
};

function CreateMessageComponent({ messageId, messageType, messageTemplate, step }: AllProps) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadAttempts, setUploadAttempts] = useState(1);
  const [axiosProgressEvent, setAxiosProgressEvent] = useState<AxiosProgressEvent | null>(null);
  const [currentStep, setCurrentStep] = useState(step || CreateMessageStepsEnum.Recipient);
  const [message, setMessage] = useState<Message | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [upsertMessage, { error: errorUpsert }] = useUpsertMessageMutation();
  const [publishMessage, { error: errorPublish }] = usePublishMessageMutation();
  const [getUploadSignedUrlMessage, { error: errorUploadUrl }] = useGetUploadSignedUrlMessageMutation();

  const navigate = useNavigate();

  const axiosController = useMemo(() => new AbortController(), [uploadAttempts]);

  const { data: messageResponse, isLoading: isLoadingMessage } = useGetOneMessageQuery(
    { id: messageId! },
    { skip: !messageId }
  );

  useEffect(() => {
    if (messageResponse) {
      setMessage(messageResponse);
    }
  }, [messageResponse]);

  useEffect(() => {
    const error: any = errorUpsert || errorPublish || errorUploadUrl;
    if (error) {
      setErrorMessage(error.data.message);
    }
  }, [errorUpsert, errorPublish, errorUploadUrl]);

  useEffect(() => {
    if (currentStep === CreateMessageStepsEnum.Success) {
      confetti({
        particleCount: 150,
        spread: 60,
        zIndex: 99999
      });
    }
  }, [currentStep]);

  const goTo = (step: CreateMessageStepsEnum) => {
    setErrorMessage('');
    setCurrentStep(step);
  };

  const goNext = () => goTo(currentStep + 1);
  const goBack = () => goTo(currentStep - 1);

  const handleUpsertMessage = async (
    data: StepRecipientData | StepEventData | StepMessageData | StepTitleData,
    file: File | null | undefined = undefined,
    afterAction: 'next' | 'none' | 'exit' = 'next'
  ) => {
    setIsLoading(true);
    try {
      const messageData = {
        ...message,
        contactIds: (message?.contacts || []).map((contact) => contact.id)
      };

      const saveData = {
        ...messageData,
        ...data
      } as UpsertMessage;

      if (!message?.type) {
        saveData.type = messageType || MessageTypeEnum.Text;
      }

      let response = await upsertMessage(saveData).unwrap();

      if (typeof file !== 'undefined') {
        if (file) {
          const url = await getUploadSignedUrlMessage({
            id: response.id,
            fileName: file.name,
            contentType: file.type
          }).unwrap();

          const result = await axios.put(url, file, {
            signal: axiosController.signal,
            onUploadProgress: (upload) => {
              setAxiosProgressEvent(upload.progress !== 1 ? upload : null);
            }
          });

          if (result.status === 200) {
            response = await upsertMessage({
              ...saveData,
              id: response.id,
              content: '',
              fileName: file.name
            }).unwrap();
          }
        } else {
          response = await upsertMessage({
            ...saveData,
            id: response.id,
            fileName: ''
          }).unwrap();
        }
      }

      setMessage(response);

      if (afterAction === 'next') {
        goNext();
      } else if (afterAction === 'exit') {
        dispatch(openModal({ name: SAVE_EXIT_MESSAGE_MODAL_NAME }));
      }
    } catch (e) {
      logging(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePublishMessage = async () => {
    if (message!.status === MessageStatusEnum.Published) {
      navigate('/dashboard');
      return;
    }

    setIsLoading(true);
    try {
      let response = await publishMessage({ id: message!.id }).unwrap();
      setMessage(response);

      goNext();
    } catch (e) {
      logging(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAbortUploading = () => {
    setUploadAttempts((val) => val + 1);
    setAxiosProgressEvent(null);
    axiosController.abort();
  };

  return (
    <>
      <div className="create-message-component">
        {errorMessage && <Alert className="error-message" message={errorMessage} type="error" showIcon />}

        {currentStep === CreateMessageStepsEnum.Recipient && (
          <StepRecipientComponent
            message={message}
            onNext={handleUpsertMessage}
            isLoading={isLoading}
          ></StepRecipientComponent>
        )}
        {message && (
          <>
            {currentStep === CreateMessageStepsEnum.Event && (
              <StepEventComponent
                message={message}
                onNext={handleUpsertMessage}
                onBack={goBack}
                messageTemplate={messageTemplate}
                isLoading={isLoading}
              ></StepEventComponent>
            )}
            {currentStep === CreateMessageStepsEnum.Message && (
              <StepMessageComponent
                onNext={handleUpsertMessage}
                onBack={goBack}
                message={message}
                axiosProgressEvent={axiosProgressEvent}
                onAbortUploading={handleAbortUploading}
                messageType={message?.type || messageType || MessageTypeEnum.Text}
                messageTemplate={messageTemplate}
                isLoading={isLoading}
              ></StepMessageComponent>
            )}
            {currentStep === CreateMessageStepsEnum.Title && (
              <StepTitleComponent
                message={message}
                onNext={handleUpsertMessage}
                onBack={goBack}
                messageTemplate={messageTemplate}
                isLoading={isLoading}
              ></StepTitleComponent>
            )}
            {currentStep === CreateMessageStepsEnum.Review && (
              <StepReviewComponent
                message={message}
                onBack={goBack}
                onNext={goNext}
                onClose={() => {}}
                isLoading={isLoading}
                goToMessageInfo={() => goTo(CreateMessageStepsEnum.Message)}
                goToDeliveryInfo={() => goTo(CreateMessageStepsEnum.Recipient)}
              ></StepReviewComponent>
            )}
          </>
        )}
        {currentStep === CreateMessageStepsEnum.Confirm && (
          <div className="create-message-component-full-height">
            <div className="confirm-block">
              <p>
                Your messages will be sent out as scheduled after you're gone. Don't worry, you can still make changes
                or remove them later. Ready to complete your message?
              </p>
            </div>

            <Row className="create-message-component-footer">
              <Col span={12}>
                <Button onClick={goBack} type="link">
                  <ArrowLeftOutlined /> Back
                </Button>
              </Col>
              <Col span={12} className="text-right">
                <Button type="primary" onClick={handlePublishMessage}>
                  Complete
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {currentStep === CreateMessageStepsEnum.Success && (
          <div className="success-block">
            <div className="block-center">
              <h2>Thank You!</h2>
              <img src={imageModalCongratulations} alt="" className="image" />
              <p>
                You will now be able to see and edit the message in the <Link to={'/dashboard'}>Dashboard</Link> page.
              </p>
              <p>If have any questions or need any assistance, don't hesitate to reach out.</p>
              <p>Thanks again for choosing Eternal Applications!</p>
              <Button type="primary" size="large" onClick={() => navigate('/')}>
                Create new message
              </Button>
            </div>
          </div>
        )}
      </div>

      <SaveExitMessageModalComponent />
    </>
  );
}

export default CreateMessageComponent;
