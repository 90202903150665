import { FormInstance } from 'antd';
import { AuthUser } from 'api/AuthApi';
import { Message, MessageEventTypeEnum, MessageTypeEnum } from 'api/MessagesApi';
import { User, UserAccessLevelPeriodEnum, UserAccessLevelPlanEnum, UserAccessLevelStatusEnum } from 'api/UsersApi';
import dayjs from 'dayjs';

export const logging = (msg) => {
  console.log(msg);
};

export const getReferrer = (location: { pathname: string; search: string }): string =>
  `${window.location.origin}${location.pathname}${location.search}`;

export const generateRange = (start: number, stop: number, step = 1): number[] =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const getMonthNames = (): string[] => {
  const months: string[] = [];
  const date = new Date();

  for (let i = 0; i < 12; i++) {
    date.setMonth(i);
    months.push(date.toLocaleString('en-US', { month: 'long' }));
  }
  return months;
};

export const getFullName = (user?: User | null, defaultText = 'Unknown') => {
  const fullName = user ? `${user.firstName} ${user.lastName}`.trim() : '';
  return fullName || defaultText;
};

export const getFullNameOrEmail = (user?: User | null, defaultText = 'Unknown') => {
  return getFullName(user, '') || user?.email || defaultText;
};

export const getFullNameWithEmail = (user?: User | null, defaultText = 'Unknown') => {
  const fullName = getFullName(user, '');
  return user?.email ? (fullName ? `${fullName} (${user.email})` : user.email) : defaultText;
};

export const formatPrice = (price: number) => {
  return `${Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}`;
};

export const formatTime = (time: number) => {
  const seconds = time % 60;
  const minutes = Math.floor(time / 60);
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

function nth(n: number): string {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
}

export const getFileExtension = (fileName: string): string => fileName.split('.').pop() || '';

export const displayDeliveryDate = (message?: Message, defaultText = '-') => {
  if (message?.eventType === MessageEventTypeEnum.Final) {
    return 'At passing';
  }

  let deliveryDate = defaultText;
  if (message?.deliveryDay && message?.deliveryMonth) {
    deliveryDate = `${getMonthNames()[message.deliveryMonth - 1]} ${message.deliveryDay}${nth(message.deliveryDay)}`;
    if (message?.deliveryYear) {
      deliveryDate = `${deliveryDate}, ${message.deliveryYear}`;
    }
  }
  return deliveryDate;
};

export const isSupportedVideoFile = (fileName: string) =>
  ['mp4', 'mov', 'webm', 'ogv'].includes(getFileExtension(fileName));

export const isSupportedAudioFile = (fileName: string) =>
  ['mp3', 'wav', 'webm', 'm4a', 'ogg'].includes(getFileExtension(fileName));

export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(0)} ${sizes[i]}`;
};

export const validateForm = (form: FormInstance, response: any, formPrefix = '') => {
  if (response?.data?.status !== 422) {
    return;
  }

  const errors: { [key: string]: { name: string; errors: string[] } } = {};
  response?.data?.errors.forEach((error: { path: string; msg: string }) => {
    const path = formPrefix ? `${formPrefix}${error.path.charAt(0).toUpperCase() + error.path.slice(1)}` : error.path;

    if (!errors[path]) {
      errors[path] = {
        name: path,
        errors: [error.msg]
      };
    }
  });

  const formErrors = Object.values(errors);
  if (formErrors.length) {
    form.setFields(formErrors);
  }
};

export const getResponseErrors = (response: any) => {
  if (response?.data?.status !== 422) {
    return [];
  }

  const errors: string[] = [];
  response?.data?.errors.forEach((error: { path: string; msg: string }) => {
    errors.push(error.msg);
  });

  return errors;
};

export const getTimeRemaining = (endTime: Date, shiftTime: number) => {
  const total = +endTime - +new Date() + shiftTime;
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);

  return {
    total,
    hours,
    minutes,
    seconds
  };
};

export const checkIfDateValid = (day: number, month: number, year?: number | null) => {
  const leapYear = 2024;
  const fixedYear = year || leapYear;
  const date = dayjs(`${fixedYear}-${month}-${day}`, 'YYYY-M-D', true);
  return date.isValid();
};

export const getCurrentUrl = () => {
  return window.location.pathname + window.location.search;
};

export const getAccessLevelPlanPeriodName = (accessLevelPeriod: UserAccessLevelPeriodEnum): string => {
  if (accessLevelPeriod === UserAccessLevelPeriodEnum.Yearly) return 'Annual';
  if (accessLevelPeriod === UserAccessLevelPeriodEnum.Monthly) return 'Monthly';
  if (accessLevelPeriod === UserAccessLevelPeriodEnum.OneTime) return 'Forever';
  return '';
};

export const getAccessLevelPlanName = (accessLevel: UserAccessLevelPlanEnum): string => {
  if (accessLevel === UserAccessLevelPlanEnum.Premium) return 'Premium';
  if (accessLevel === UserAccessLevelPlanEnum.Unlimited) return 'Unlimited';
  return 'Starter';
};

export const getAccessLevelByMessageType = (type: MessageTypeEnum): UserAccessLevelPlanEnum => {
  if (type === MessageTypeEnum.Audio) return UserAccessLevelPlanEnum.Premium;
  if (type === MessageTypeEnum.Video) return UserAccessLevelPlanEnum.Unlimited;
  return UserAccessLevelPlanEnum.Free;
};

export const userHasAccess = (user: AuthUser | null, requiredAccessLevel: UserAccessLevelPlanEnum) => {
  const accessOrder = [
    UserAccessLevelPlanEnum.Free,
    UserAccessLevelPlanEnum.Premium,
    UserAccessLevelPlanEnum.Unlimited
  ];
  return (
    user &&
    user.accessLevelStatus !== UserAccessLevelStatusEnum.Canceled &&
    accessOrder.indexOf(user.accessLevelPlan) >= accessOrder.indexOf(requiredAccessLevel)
  );
};

export const repeatedCall = (callback: Function, refreshTimes = 5, timeout = 1000) => {
  setTimeout(() => {
    callback && callback();
    if (refreshTimes > 0) {
      repeatedCall(callback, refreshTimes - 1);
    }
  }, timeout);
};
