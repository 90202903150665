import { Button, Flex, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeModal, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './SaveExitMessageModalComponent.css';

export const SAVE_EXIT_MESSAGE_MODAL_NAME = 'SAVE_EXIT_MESSAGE_MODAL_NAME';

function SaveExitMessageModalComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSave = async () => {
    navigate('/dashboard');
    handleCloseModal();
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal open={true} title="Save Draft?" onCancel={handleCloseModal} footer={null} className="size-sm">
      <div className="remove-draft-message-modal-component">
        <p>Your message will be saved in your Message Dashboard. Do you want to save and exit?</p>
      </div>

      <Flex justify="space-between">
        <Button onClick={handleCloseModal}>Back</Button>

        <Button onClick={handleSave} type="primary">
          Save & Exit
        </Button>
      </Flex>
    </Modal>
  );
}

function SaveExitMessageModalComponentWrapper() {
  const modalName = useSelector(selectModalName);

  return modalName === SAVE_EXIT_MESSAGE_MODAL_NAME ? <SaveExitMessageModalComponent /> : null;
}

export default SaveExitMessageModalComponentWrapper;
