import { Alert, DatePicker, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SysAdminReport, useGetReportSysAdminQuery } from 'api/SysAdminApi';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectReportFromDate, selectReportToDate, setReportFromDate, setReportToDate } from 'store/reportSlice';
import { useAppDispatch } from 'store/store';
import './SysAdminReportComponent.css';

const PAGE_SIZE = 10;

function SysAdminReportComponent() {
  const dispatch = useAppDispatch();
  const reportFromDate = useSelector(selectReportFromDate);
  const reportToDate = useSelector(selectReportToDate);

  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState<string | null>(reportFromDate);
  const [toDate, setToDate] = useState<string | null>(reportToDate);

  const { data: sysAdminReportData, isFetching } = useGetReportSysAdminQuery(
    {
      limit: PAGE_SIZE,
      offset: (currentPage - 1) * PAGE_SIZE,
      fromDate: fromDate!,
      toDate: toDate!
    },
    { skip: !fromDate || !toDate }
  );

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const columns: ColumnsType<SysAdminReport> = [
    {
      title: 'Email',
      key: 'email',
      ellipsis: true,
      render: (report: SysAdminReport) => <Link to={`/sysadmin/report/${report.id}`}>{report.email}</Link>
    },
    {
      title: 'Amount',
      key: 'amountTotal',
      render: (report: SysAdminReport) => `$${(parseInt(report.amountTotal) / 100).toLocaleString()}`
    },
    {
      title: 'Commission',
      key: 'commission',
      render: (report: SysAdminReport) => `$${(Math.round(parseInt(report.amountTotal) * 0.03) / 100).toLocaleString()}`
    }
  ];

  return (
    <>
      <div className="sys-admin-report-component">
        <Space>
          <DatePicker.RangePicker
            size="large"
            defaultValue={[
              reportFromDate ? dayjs(reportFromDate, 'YYYY-MM-DD') : null,
              reportToDate ? dayjs(reportToDate, 'YYYY-MM-DD') : null
            ]}
            onChange={(dates, dateStrings) => {
              const [from, to] = dateStrings;

              setCurrentPage(1);
              setFromDate(from);
              setToDate(to);

              dispatch(setReportFromDate(from));
              dispatch(setReportToDate(to));
            }}
          />
          {!fromDate && !toDate && (
            <Alert message="Please select From and To dates to see results." type="warning" showIcon />
          )}
        </Space>

        <h2>Report</h2>

        <Table
          columns={columns}
          dataSource={sysAdminReportData?.list || []}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            current: currentPage,
            total: sysAdminReportData?.total || 0
          }}
          onChange={onTableChanged}
          rowKey="id"
          bordered
          loading={isFetching}
        />
      </div>
    </>
  );
}

export default SysAdminReportComponent;
