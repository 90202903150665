import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

type ModalState = {
  name: string | null;
  data: any;
  onClose?: () => void;
};

const slice = createSlice({
  name: 'modal',
  initialState: { name: null, data: null } as ModalState,
  reducers: {
    openModal(state, action: PayloadAction<{ name: string; data?: any; onClose?: () => void }>) {
      state.name = action.payload.name;
      state.data = action.payload.data || null;
      state.onClose = action.payload.onClose;
    },
    closeModal(state) {
      state.name = null;
      state.data = null;
      state.onClose = () => {};
    }
  }
});

export const { openModal, closeModal } = slice.actions;
export default slice.reducer;

export const selectModal = (state: RootState) => state.modal;
export const selectModalName = (state: RootState) => selectModal(state).name;
export const selectModalData = (state: RootState) => selectModal(state).data;
export const selectModalOnClose = (state: RootState) => selectModal(state).onClose;
