import { Input, Modal } from 'antd';
import { useChangeStatusUserMutation, User, UserStatusEnum } from 'api/UsersApi';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { getFullNameWithEmail, logging } from 'utils/utils';
import './ConfirmModal.css';

export const CONFIRM_DECEASED_MODAL_NAME = 'CONFIRM_DECEASED_MODAL_NAME';

export type ConfirmModalProps = {
  user: User;
};

function ConfirmModal({ user }: ConfirmModalProps) {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');

  const [changeStatusUser] = useChangeStatusUserMutation();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const moveToUnverifiedDeceased = async () => {
    if (!isValid) {
      return;
    }

    try {
      await changeStatusUser({ userId: user.id, status: UserStatusEnum.UNVERIFIED_DECEASED }).unwrap();

      handleCloseModal();
    } catch (e: any) {
      logging(e);
    }
  };

  const isValid = email != '' && email === user.email;

  return (
    <Modal open title="Status Update" onCancel={handleCloseModal} okText="Confirm" onOk={moveToUnverifiedDeceased}>
      <>
        <p>
          Before making changes to {getFullNameWithEmail(user)}'s account, we need to ensure the accuracy of our
          records.
        </p>
        <p>
          Please confirm if you wish to update the status of {getFullNameWithEmail(user)} to "Deceased". This step is
          essential to maintaining the integrity of our system.
        </p>

        <p>
          By marking the user as "Deceased", {getFullNameWithEmail(user)}'s messages will enter our messaging queue and
          begin delivery at the appropriate date. If you have any doubts or concerns, please click "Cancel" to review
          your decision.
        </p>
        <p>
          Please type in input: <b>{user.email}</b>
        </p>
        <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} status={isValid ? '' : 'error'} />
        {!isValid && <p className="alert-text">Please enter user's email</p>}
      </>
    </Modal>
  );
}

function AddAdminModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);

  const user = modalData?.user;

  return modalName === CONFIRM_DECEASED_MODAL_NAME ? <ConfirmModal user={user} /> : null;
}

export default AddAdminModalComponentWrapper;
