import { Form, Input, Modal } from 'antd';
import { SysAdminUser, useUpdateUserAffiliateSysAdminMutation } from 'api/SysAdminApi';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { logging, validateForm } from 'utils/utils';
import './UpdateUserAffiliateSysAdminModalComponent.css';

export const UPDATE_USER_AFFILIATE_MODAL_NAME = 'UPDATE_USER_AFFILIATE_MODAL_NAME';

type AllProps = {
  user: SysAdminUser;
};

function UpdateUserAffiliateSysAdminModalComponent({ user }: AllProps) {
  const dispatch = useAppDispatch();
  const [updateUserAffiliate, { isLoading: isLoadingRemove }] = useUpdateUserAffiliateSysAdminMutation();

  const [form] = Form.useForm();

  const handleUpdate = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      await updateUserAffiliate({ userId: user.id, affiliateCode: fields.affiliateCode }).unwrap();

      handleCloseModal();
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title="Update Affiliate"
      onOk={handleUpdate}
      onCancel={handleCloseModal}
      okText="Add"
      confirmLoading={isLoadingRemove}
      className="size-sm"
    >
      <div className="update-user-affiliate-sysadmin-modal-component">
        <p className="text">
          Assign affiliate code to admin
          <br />
          <strong>{user.email}</strong>
        </p>
        <Form
          name="basic"
          layout="vertical"
          autoComplete="off"
          requiredMark="optional"
          form={form}
          initialValues={{ affiliateCode: user.affiliateCode }}
        >
          <Form.Item name="affiliateCode">
            <Input size="large" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

function UpdateUserAffiliateSysAdminModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const user = modalData?.user || null;

  return modalName === UPDATE_USER_AFFILIATE_MODAL_NAME ? (
    <UpdateUserAffiliateSysAdminModalComponent user={user} />
  ) : null;
}

export default UpdateUserAffiliateSysAdminModalComponentWrapper;
