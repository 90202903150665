import { Space, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { DeleteOutlined, EditOutlined, TeamOutlined } from '@ant-design/icons';
import { SysAdminUser, useGetAllUsersSysAdminQuery } from 'api/SysAdminApi';
import { UserAccessLevelPlanEnum, UserStatusEnum } from 'api/UsersApi';
import RemoveSysAdminUserModalComponent, {
  DELETE_SYS_ADMIN_USER_MODAL_NAME
} from 'components/SysAdmin/RemoveSysAdminUserModalComponent/RemoveSysAdminUserModalComponent';
import UpdateUserAffiliateSysAdminModalComponent, {
  UPDATE_USER_AFFILIATE_MODAL_NAME
} from 'components/SysAdmin/UpdateUserAffiliateSysAdminModalComponent/UpdateUserAffiliateSysAdminModalComponent';
import UpdateUserSysAdminModalComponent, {
  UPDATE_USER_SYS_ADMIN_MODAL_NAME
} from 'components/SysAdmin/UpdateUserSysAdminModalComponent/UpdateUserSysAdminModalComponent';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { getFullName } from 'utils/utils';
import './SysAdminUserListComponent.css';

const PAGE_SIZE = 10;

function SysAdminUserListComponent() {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const { data: sysAdminUserListData, isFetching } = useGetAllUsersSysAdminQuery({
    limit: PAGE_SIZE,
    offset: (currentPage - 1) * PAGE_SIZE
  });

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const renderStatus = (status: UserStatusEnum) => {
    const statusComponent = {
      [UserStatusEnum.ACTIVE]: <Tag color="success">Active</Tag>,
      [UserStatusEnum.UNVERIFIED_DECEASED]: <Tag color="default">Unverified Deceased</Tag>,
      [UserStatusEnum.DECEASED]: <Tag color="error">Deceased</Tag>
    };

    return statusComponent[status];
  };

  const handleRemove = (user: SysAdminUser) => {
    dispatch(openModal({ name: DELETE_SYS_ADMIN_USER_MODAL_NAME, data: { user } }));
  };

  const handleEditUser = (user: SysAdminUser) => {
    dispatch(openModal({ name: UPDATE_USER_SYS_ADMIN_MODAL_NAME, data: { user } }));
  };

  const handleUpdateAffiliate = (user: SysAdminUser) => {
    dispatch(openModal({ name: UPDATE_USER_AFFILIATE_MODAL_NAME, data: { user } }));
  };

  const columns: ColumnsType<SysAdminUser> = [
    {
      title: 'User Name',
      key: 'name',
      ellipsis: true,
      render: (_, sysAdminUser) => <Link to={`/sysadmin/users/${sysAdminUser.id}`}>{getFullName(sysAdminUser)}</Link>
    },
    {
      title: 'Email',
      key: 'email',
      ellipsis: true,
      render: (_, sysAdminUser) => sysAdminUser.email
    },
    {
      title: 'Messages',
      key: 'messages',
      render: (_, sysAdminUser) => `${sysAdminUser.messagesPublishedCount} (${sysAdminUser.messagesCount})`
    },
    {
      title: 'Access Level',
      key: 'accessLevelPlan',
      dataIndex: 'accessLevelPlan',
      render: (accessLevelPlan: UserAccessLevelPlanEnum) => accessLevelPlan
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: UserStatusEnum) => renderStatus(status)
    },
    {
      title: 'Admin Info',
      key: 'info',
      render: (_, sysAdminUser) => (
        <>
          {sysAdminUser.userAdmins.length !== 0 && (
            <p>
              <strong>Administrators:</strong>
              {sysAdminUser.userAdmins.map((user) => (
                <div>{user.email}</div>
              ))}
            </p>
          )}
          {sysAdminUser.adminUsers.length !== 0 && (
            <p>
              <strong>Administrator of:</strong>
              {sysAdminUser.adminUsers.map((user) => (
                <div>{user.email}</div>
              ))}
            </p>
          )}
        </>
      )
    },
    {
      title: 'Affiliate',
      key: 'affiliate',
      dataIndex: 'affiliateCode',
      render: (affiliateCode: string) => affiliateCode
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, sysAdminUser) => (
        <Space size="small">
          <a onClick={() => handleEditUser(sysAdminUser)}>
            <Tooltip title="Edit User">
              <EditOutlined />
            </Tooltip>
          </a>
          <a onClick={() => handleUpdateAffiliate(sysAdminUser)}>
            <Tooltip title="Affiliate">
              <TeamOutlined />
            </Tooltip>
          </a>
          <a onClick={() => handleRemove(sysAdminUser)}>
            <Tooltip title="Remove User">
              <DeleteOutlined />
            </Tooltip>
          </a>
        </Space>
      )
    }
  ];

  return (
    <>
      <div className="sys-admin-user-list-component">
        <h3>Users ({sysAdminUserListData?.total || 0})</h3>

        <Table
          columns={columns}
          dataSource={sysAdminUserListData?.list || []}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            current: currentPage,
            total: sysAdminUserListData?.total || 0
          }}
          onChange={onTableChanged}
          rowKey="id"
          bordered
          loading={isFetching}
        />
      </div>

      <UpdateUserAffiliateSysAdminModalComponent />
      <UpdateUserSysAdminModalComponent />
      <RemoveSysAdminUserModalComponent />
    </>
  );
}

export default SysAdminUserListComponent;
