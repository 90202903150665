import { DeleteOutlined } from '@ant-design/icons';
import { Button, Divider, Flex } from 'antd';
import { authApi } from 'api/AuthApi';
import { PaymentMethod, useGetPaymentMethodsQuery, useSubscriptionInfoQuery } from 'api/PaymentApi';
import { UserAccessLevelPeriodEnum, UserAccessLevelPlanEnum, UserAccessLevelStatusEnum } from 'api/UsersApi';
import CancelSubscriptionModalComponent, {
  CANCEL_SUBSCRIPTION_MODAL_NAME
} from 'components/Account/BillingComponent/CancelSubscriptionModalComponent/CancelSubscriptionModalComponent';
import DeletePaymentMethodModalComponent, {
  DELETE_PAYMENT_METHOD_MODAL_NAME
} from 'components/Account/BillingComponent/DeletePaymentMethodModalComponent/DeletePaymentMethodModalComponent';
import ResumeSubscriptionModalComponent, {
  RESUME_SUBSCRIPTION_MODAL_NAME
} from 'components/Account/BillingComponent/ResumeSubscriptionModalComponent/ResumeSubscriptionModalComponent';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuthUser } from 'store/authSlice';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import {
  formatPrice,
  getAccessLevelPlanName,
  getAccessLevelPlanPeriodName,
  repeatedCall,
  userHasAccess
} from 'utils/utils';
import SpinComponent from '../../Tool/SpinComponent/SpinComponent';
import './BillingComponent.css';

function BillingComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectAuthUser);
  const { data: subscriptionInfo, isLoading: isLoadingSubscriptionInfo } = useSubscriptionInfoQuery();
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useGetPaymentMethodsQuery();

  const handleCancelSubscription = async () => {
    dispatch(
      openModal({
        name: CANCEL_SUBSCRIPTION_MODAL_NAME,
        data: {
          onOk: () => {
            repeatedCall(() => {
              dispatch(authApi.endpoints?.me.initiate(undefined, { forceRefetch: true }));
            });
          }
        }
      })
    );
  };

  const handleResumeSubscription = async () => {
    dispatch(
      openModal({
        name: RESUME_SUBSCRIPTION_MODAL_NAME,
        data: {
          onOk: () => {
            repeatedCall(() => {
              dispatch(authApi.endpoints?.me.initiate(undefined, { forceRefetch: true }));
            });
          }
        }
      })
    );
  };

  const handleDeletePaymentMethod = async (paymentMethod: PaymentMethod) => {
    dispatch(
      openModal({
        name: DELETE_PAYMENT_METHOD_MODAL_NAME,
        data: {
          paymentMethod
        }
      })
    );
  };

  const daysLeft: number | null = useMemo(() => {
    if (user?.accessLevelValidUntil) {
      return dayjs(user.accessLevelValidUntil).diff(dayjs(), 'days');
    }
    return null;
  }, [user?.accessLevelValidUntil]);

  return (
    <div className="billing-component">
      {user && (
        <div className="current-plan">
          <h3>Current Plan</h3>
          <SpinComponent isLoading={isLoadingSubscriptionInfo}>
            <p>
              {getAccessLevelPlanPeriodName(user.accessLevelPeriod)} {getAccessLevelPlanName(user.accessLevelPlan)}{' '}
              {daysLeft ? `(${daysLeft} ${daysLeft === 1 ? 'day left' : 'days left'})` : ''}
            </p>
            {user.accessLevelStatus === UserAccessLevelStatusEnum.Canceling ? (
              <div>
                <>Will be canceled at {dayjs(user.accessLevelValidUntil).format('MMM D, YYYY')}</>
              </div>
            ) : user.accessLevelStatus === UserAccessLevelStatusEnum.Active && subscriptionInfo ? (
              <div>
                Next payment: {formatPrice(subscriptionInfo.amount / 100)} on{' '}
                {dayjs(subscriptionInfo.next * 1000).format('MMM D, YYYY')}
              </div>
            ) : null}
            <br />

            <Flex gap={20}>
              <div>
                <Button onClick={() => navigate('/pricing')} type="primary">
                  Change Plan
                </Button>
              </div>
              <div>
                {userHasAccess(user, UserAccessLevelPlanEnum.Premium) &&
                  [UserAccessLevelPeriodEnum.Monthly, UserAccessLevelPeriodEnum.Yearly].includes(
                    user.accessLevelPeriod
                  ) &&
                  (user?.accessLevelStatus === UserAccessLevelStatusEnum.Active ? (
                    <Button onClick={handleCancelSubscription} danger>
                      Cancel Subscription
                    </Button>
                  ) : (
                    <Button onClick={handleResumeSubscription}>Resume Subscription</Button>
                  ))}
              </div>
            </Flex>
          </SpinComponent>
        </div>
      )}

      <Divider />
      <div className="billing-info">
        <h3>Billing Information</h3>
        <SpinComponent isLoading={isLoadingPaymentMethods}>
          {paymentMethods?.length ? (
            <>
              {paymentMethods.map((paymentMethod) => (
                <Flex gap={40} key={paymentMethod.id} className="card-block">
                  <Flex gap={5} vertical={true}>
                    <div>
                      {paymentMethod.card.brand.toUpperCase()} ending in <strong>{paymentMethod.card.last4}</strong>
                    </div>
                    <div>
                      Expires {String(paymentMethod.card.exp_month).padStart(2, '0')}/{paymentMethod.card.exp_year}
                    </div>
                  </Flex>
                  <DeleteOutlined className="icon" onClick={() => handleDeletePaymentMethod(paymentMethod)} />
                </Flex>
              ))}
            </>
          ) : (
            <>No payment methods</>
          )}
        </SpinComponent>
      </div>

      <CancelSubscriptionModalComponent />
      <ResumeSubscriptionModalComponent />
      <DeletePaymentMethodModalComponent />
    </div>
  );
}

export default BillingComponent;
