import { ArrowLeftOutlined, AudioOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Flex, Row, Tooltip } from 'antd';
import imageVideoPause from 'assets/video-pause-button.svg';
import imageVideoResume from 'assets/video-resume-button.svg';
import imageVideoRetake from 'assets/video-retake-button.svg';
import RecordingRecorderComponent from 'components/Message/CreateMessageComponent/StepMessageComponent/RecordingRecorderComponent/RecordingRecorderComponent';
import { useReactMediaRecorder } from 'components/Tool/ReactMediaRecorder/ReactMediaRecorder';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useRef, useState } from 'react';
import './AudioRecorderComponent.css';

const MAX_RECORD_TIME = 120;

type AllProps = {
  onDone: (file: File) => void;
  onCancel: () => void;
};

function AudioRecorderComponent({ onDone, retake, onCancel }: AllProps & { retake: () => void }) {
  const refStop = useRef({ isStopped: false });

  const { status, error, startRecording, stopRecording, pauseRecording, resumeRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({
      video: false,
      audio: true,
      askPermissionOnMount: true,
      mediaRecorderOptions: { mimeType: 'audio/wav' },
      onStop: (blobUrl: string, blob: Blob) => {
        if (refStop.current.isStopped) {
          onDone(new File([blob], `${new Date().getTime()}.wav`, { type: 'audio/wav' }));
        }
      }
    });

  const stopAudioRecording = () => {
    refStop.current.isStopped = true;
    stopRecording();
  };

  return (
    <div className="audio-recorder-component create-message-component-full-height">
      <SpinComponent isLoading={status === 'acquiring_media'}>
        {error ? (
          <Alert className="error-message" message={error} type="error" showIcon />
        ) : (
          <>
            <div className="audio-overlay">
              <RecordingRecorderComponent status={status} maxTime={MAX_RECORD_TIME} onTimeEnds={stopAudioRecording} />
              <AudioOutlined className="microphone" />
            </div>

            <Flex gap={30} className="audio-buttons" justify="center">
              <Tooltip title="Retake">
                <img src={imageVideoRetake} alt="Retake" onClick={retake} className="disabled" />
              </Tooltip>

              {status === 'recording' ? (
                <Tooltip title="Pause / Stop">
                  <img src={imageVideoPause} alt="Pause" onClick={pauseRecording} />
                </Tooltip>
              ) : (
                <Tooltip title={status === 'idle' ? 'Start Recording' : 'Continue Recording'}>
                  <img
                    src={imageVideoResume}
                    alt="Play"
                    onClick={() => (status === 'idle' ? startRecording() : resumeRecording())}
                  />
                </Tooltip>
              )}
            </Flex>
          </>
        )}
      </SpinComponent>

      <Row className="create-message-component-footer">
        <Col span={12}>
          <Button onClick={onCancel} type="link">
            <ArrowLeftOutlined /> Back
          </Button>
        </Col>

        <Col span={12} className="text-right">
          <Button
            type="primary"
            htmlType="submit"
            disabled={status !== 'paused' && status !== 'recording'}
            onClick={stopAudioRecording}
          >
            Preview
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function AudioRecorderComponentWrapper({ onDone, onCancel }: AllProps) {
  const [retakeCount, setRetakeCount] = useState(1);

  return (
    <AudioRecorderComponent
      key={retakeCount}
      onDone={onDone}
      onCancel={onCancel}
      retake={() => setRetakeCount((count) => count + 1)}
    />
  );
}

export default AudioRecorderComponentWrapper;
