import { ArrowLeftOutlined, InboxOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Input, Row, Upload } from 'antd';
import { Message, MessageTemplate, MessageTypeEnum } from 'api/MessagesApi';
import { detect } from 'detect-browser';
import { useState } from 'react';
import { bytesToSize } from 'utils/utils';
import './StepMessageOptionsComponent.css';

const browser = detect();

const uploadMimeTypes = {
  [MessageTypeEnum.Text]: '.txt,.pdf,.doc,.docx',
  [MessageTypeEnum.Audio]: 'audio/*',
  [MessageTypeEnum.Video]: 'video/*'
};

const uploadFileSize = {
  [MessageTypeEnum.Text]: 1 * 1024 * 1024,
  [MessageTypeEnum.Audio]: 100 * 1024 * 1024,
  [MessageTypeEnum.Video]: 1024 * 1024 * 1024
};

type AllProps = {
  message?: Message;
  messageType: MessageTypeEnum;
  messageTemplate?: MessageTemplate;
  onSelectFile: (file: File) => void;
  onBack: () => void;
  onCreating: () => void;
  onPreview: () => void;
};

function StepMessageOptionsComponent({ messageType, onSelectFile, onBack, onCreating, onPreview }: AllProps) {
  const [isFileSizeError, setIsFileSizeError] = useState(false);
  const [permissionState, setPermissionState] = useState<PermissionState | null>(null);

  const getSupportLink = (type: 'camera' | 'audio') => {
    switch (browser && browser.name) {
      case 'safari':
        return 'https://support.apple.com/en-ph/guide/mac-help/mchlf6d108da/mac';
      case 'chrome':
        return 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop';
      case 'firefox':
        return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions';
      case 'ie':
      case 'edge':
        return 'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857';
      default:
        return '';
    }
  };

  const checkPermissions = async (names: ('camera' | 'microphone')[]) => {
    let statuses: PermissionStatus[] = [];
    try {
      statuses = await Promise.all(names.map((name) => navigator.permissions.query({ name: name as PermissionName })));
    } catch (e) {}

    const allGranted = statuses.every((status) => status.state === 'granted');
    if (allGranted) {
      onCreating();
    } else {
      const notGranted = statuses.find((status) => status.state !== 'granted');
      setPermissionState(notGranted?.state || null);
    }
  };

  const requestPermissions = async (mediaConstraints: MediaStreamConstraints) => {
    try {
      const mediaStream = await window.navigator.mediaDevices.getUserMedia(mediaConstraints);
      if (mediaStream.active) {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => track.clone().stop());

        onCreating();
      }
    } catch (e: any) {
      if (e.name === 'NotAllowedError') {
        setPermissionState('denied');
      }
    }
  };

  return (
    <div className="step-message-options-component create-message-component-full-height">
      <div>
        {isFileSizeError && (
          <>
            <Alert
              message={<>File size should be less than {bytesToSize(uploadFileSize[messageType])}</>}
              type="error"
              showIcon
              className="error"
            />
          </>
        )}
        <div className="create-message-component-title">Drag and Drop your file or create your message.</div>
        <Upload.Dragger
          accept={uploadMimeTypes[messageType]}
          showUploadList={false}
          beforeUpload={(file) => {
            setIsFileSizeError(false);
            if (file.size > uploadFileSize[messageType]) {
              setIsFileSizeError(true);
              return false;
            }

            onSelectFile(file);
            return false;
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Drop file here that you wish to send as a message</p>
          <p className="ant-upload-hint">Allowed file size is {bytesToSize(uploadFileSize[messageType])}</p>
        </Upload.Dragger>
        <Divider plain>or</Divider>
        {messageType === MessageTypeEnum.Text && (
          <>
            <Input.TextArea rows={4} placeholder="Type your message here!" onClick={onPreview} />
          </>
        )}
        {messageType === MessageTypeEnum.Audio && (
          <div className="sub-action">
            {permissionState === 'prompt' ? (
              <div className="access-camera">
                <Alert
                  message="Provide Access to Microphone"
                  description={
                    <>
                      Click <strong>Allow</strong> in popup.
                    </>
                  }
                  type="info"
                  showIcon
                  action={
                    <Button type="primary" onClick={() => requestPermissions({ audio: true })}>
                      Allow Microphone
                    </Button>
                  }
                />
              </div>
            ) : permissionState === 'denied' ? (
              <div className="access-camera">
                <Alert
                  message="Access to Microphone is Denied!"
                  description={
                    <>
                      {getSupportLink('audio') ? (
                        <>
                          Follow{' '}
                          <a href={getSupportLink('audio')} target="_blank">
                            official documentation
                          </a>{' '}
                          to provide access to microphone.
                        </>
                      ) : (
                        <>Please provide access to your Microphone.</>
                      )}
                    </>
                  }
                  type="error"
                  showIcon
                />
              </div>
            ) : (
              <>
                <div className="sub-text">Record an audio message directly in app!</div>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => checkPermissions(['microphone'])}
                  className="sub-button"
                >
                  Create Your Own Audio
                </Button>
              </>
            )}
          </div>
        )}
        {messageType === MessageTypeEnum.Video && (
          <div className="sub-action">
            {permissionState === 'prompt' ? (
              <div className="access-camera">
                <Alert
                  message="Provide Access to Camera"
                  description={
                    <>
                      Click <strong>Allow</strong> in popup.
                    </>
                  }
                  type="info"
                  showIcon
                  action={
                    <Button type="primary" onClick={() => requestPermissions({ video: true, audio: true })}>
                      Allow Camera
                    </Button>
                  }
                />
              </div>
            ) : permissionState === 'denied' ? (
              <div className="access-camera">
                <Alert
                  message="Access to Camera is Denied!"
                  description={
                    <>
                      {getSupportLink('camera') ? (
                        <>
                          Follow{' '}
                          <a href={getSupportLink('camera')} target="_blank">
                            official documentation
                          </a>{' '}
                          to provide access to camera.
                        </>
                      ) : (
                        <>Please provide access to your Camera.</>
                      )}
                    </>
                  }
                  type="error"
                  showIcon
                />
              </div>
            ) : (
              <>
                <div className="sub-text">Record a video message directly in app!</div>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => checkPermissions(['camera', 'microphone'])}
                  className="sub-button"
                >
                  Create Your Own Video
                </Button>
              </>
            )}
          </div>
        )}
      </div>

      <Row className="create-message-component-footer">
        <Col span={12}>
          <Button onClick={onBack} type="link">
            <ArrowLeftOutlined /> Back
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default StepMessageOptionsComponent;
