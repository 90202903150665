import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectAuthToken } from 'store/authSlice';

const UnauthorizedRoutes = () => {
  const token = useSelector(selectAuthToken);
  const location = useLocation();

  return !token ? <Outlet /> : <Navigate to={location.state?.redirectUrl || '/'} />;
};

export default UnauthorizedRoutes;
