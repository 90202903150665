import SysAdminPaymentListComponent from 'components/SysAdmin/SysAdminPaymentListComponent/SysAdminPaymentListComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import SysAdminMenu, { SysAdminMenuEnum } from 'pages/SysAdmin/SysAdminMenu/SysAdminMenu';
import './Payments.css';

function Payments() {
  return (
    <>
      <div className="sys-admin-users layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <>
            <SysAdminMenu selectedMenu={SysAdminMenuEnum.PAYMENTS} />

            <SysAdminPaymentListComponent />
          </>
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default Payments;
