import BeneficiaryComponent from 'components/Admin/BeneficiaryComponent/BeneficiaryComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import './Beneficiary.css';

function Beneficiary() {
  return (
    <>
      <div className="beneficiary layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <>
            <BeneficiaryComponent />
          </>
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default Beneficiary;
