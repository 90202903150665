import { Alert, Modal } from 'antd';
import { PaymentMethod, useDeletePaymentMethodsMutation } from 'api/PaymentApi';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { getResponseErrors } from 'utils/utils';
import './DeletePaymentMethodModalComponent.css';

export const DELETE_PAYMENT_METHOD_MODAL_NAME = 'DELETE_PAYMENT_METHOD_MODAL_NAME';

type AllProps = {
  paymentMethod: PaymentMethod;
};

function DeletePaymentMethodModalComponent({ paymentMethod }: AllProps) {
  const dispatch = useAppDispatch();
  const [deletePaymentMethod, { isLoading, error }] = useDeletePaymentMethodsMutation();

  const handleDelete = async () => {
    try {
      await deletePaymentMethod({ id: paymentMethod.id }).unwrap();
      handleCloseModal();
    } catch (e) {}
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title="Remove Card"
      onOk={handleDelete}
      onCancel={handleCloseModal}
      okText="Remove"
      okButtonProps={{ danger: true }}
      confirmLoading={isLoading}
      className="size-sm"
    >
      <SpinComponent isLoading={isLoading}>
        <div className="delete-payment-method-modal-component">
          {error && <Alert className="message" message={getResponseErrors(error).join(', ')} type="error" showIcon />}
          <p>Do you want to remove card?</p>
          <div>
            {paymentMethod.card.brand.toUpperCase()} ending in <strong>{paymentMethod.card.last4}</strong>
          </div>
          <div>
            Expires {String(paymentMethod.card.exp_month).padStart(2, '0')}/{paymentMethod.card.exp_year}
          </div>
        </div>
      </SpinComponent>
    </Modal>
  );
}

function DeletePaymentMethodModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const paymentMethod = modalData?.paymentMethod || null;

  return modalName === DELETE_PAYMENT_METHOD_MODAL_NAME ? (
    <DeletePaymentMethodModalComponent paymentMethod={paymentMethod} />
  ) : null;
}

export default DeletePaymentMethodModalComponentWrapper;
