import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Col, DatePicker, Flex, Form, Input, Row } from 'antd';
import { PaymentPlanPrice, useGetPaymentPlansQuery } from 'api/PaymentApi';
import { UserAccessLevelPlanEnum } from 'api/UsersApi';
import imageModalCongratulations from 'assets/modal-congratulations.svg';
import confetti from 'canvas-confetti';
import classNames from 'classnames';
import GiftCheckoutComponent from 'components/CreateGiftComponent/GiftCheckoutComponent/GiftCheckoutComponent';
import {
  clearGiftStorage,
  retrieveGiftFromStorage,
  saveGiftToStorage
} from 'components/CreateGiftComponent/GiftStorage';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logging, validateForm } from 'utils/utils';
import './CreateGiftComponent.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

export interface GiftData {
  recipientName: string;
  recipientEmail: string;
  sendDate: string;
  senderName?: string;
  senderText?: string;
}

export const enum CreateGiftStepsEnum {
  Delivery,
  Select,
  Billing,
  Success
}

function CreateGiftComponent() {
  const [currentStep, setCurrentStep] = useState(CreateGiftStepsEnum.Delivery);
  const [price, setPrice] = useState<PaymentPlanPrice>();
  const [formData, setFormData] = useState<GiftData | null>(null);
  const [couponName, setCouponName] = useState('');

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { data: planList, isLoading } = useGetPaymentPlansQuery();

  const handleSaveData = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();
      const data: GiftData = {
        recipientName: fields.recipientName,
        recipientEmail: fields.recipientEmail,
        sendDate: fields.sendDate.format('YYYY-MM-DD'),
        senderName: fields.senderName,
        senderText: fields.senderText
      };
      setFormData(data);

      setCurrentStep(CreateGiftStepsEnum.Select);
      saveGiftToStorage({
        recipientName: data.recipientName,
        recipientEmail: data.recipientEmail,
        sendDate: data.sendDate,
        senderName: data.senderName,
        senderText: data.senderText
      });
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }

    return false;
  };

  useEffect(() => {
    if (price && formData) {
      saveGiftToStorage({ price });
      setCurrentStep(CreateGiftStepsEnum.Billing);
    }
  }, [price, couponName]);

  useEffect(() => {
    const savedGift = retrieveGiftFromStorage();
    if (savedGift?.recipientName && savedGift?.recipientEmail && savedGift?.sendDate) {
      form.setFieldsValue({
        recipientName: savedGift.recipientName,
        recipientEmail: savedGift.recipientEmail,
        sendDate: dayjs(savedGift.sendDate),
        senderName: savedGift.senderName,
        senderText: savedGift.senderText
      });
      setFormData({
        recipientName: savedGift.recipientName,
        recipientEmail: savedGift.recipientEmail,
        sendDate: savedGift.sendDate,
        senderName: savedGift.senderName,
        senderText: savedGift.senderText
      });

      if (savedGift.price) {
        setPrice(savedGift.price);
        setCurrentStep(CreateGiftStepsEnum.Billing);
      } else {
        setCurrentStep(CreateGiftStepsEnum.Select);
      }
    }
  }, []);

  useEffect(() => {
    if (currentStep === CreateGiftStepsEnum.Success) {
      confetti({
        particleCount: 150,
        spread: 60,
        zIndex: 99999
      });
    }
  }, [currentStep]);

  return (
    <div className="create-gift-component">
      {currentStep !== CreateGiftStepsEnum.Success && (
        <Row className="progress" wrap={false} gutter={10}>
          <Col
            span={8}
            className={classNames({
              active: currentStep >= CreateGiftStepsEnum.Delivery
            })}
          >
            <div className="title">Delivery</div>
            <div className="bar"></div>
          </Col>
          <Col
            span={8}
            className={classNames({
              active: currentStep >= CreateGiftStepsEnum.Select
            })}
          >
            <div className="title">Select</div>
            <div className="bar"></div>
          </Col>
          <Col
            span={8}
            className={classNames({
              active: currentStep >= CreateGiftStepsEnum.Billing
            })}
          >
            <div className="title">Billing</div>
            <div className="bar"></div>
          </Col>
        </Row>
      )}

      <div className="content">
        {currentStep === CreateGiftStepsEnum.Delivery && (
          <Row>
            <Col xs={24} lg={18}>
              <Form
                layout="vertical"
                onFinish={handleSaveData}
                autoComplete="off"
                requiredMark="optional"
                form={form}
                initialValues={{
                  senderText:
                    "I'm giving you a membership to Eternal Applications so you can leave us messages that we can hold on to forever."
                }}
              >
                <div className="main-title">How would you like the gift delivered?</div>
                <Form.Item
                  label="Gift Recipient Full Name:"
                  name="recipientName"
                  messageVariables={{ label: 'Recipient Full Name' }}
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Ex ) John Smith" />
                </Form.Item>

                <Form.Item
                  label="Gift Recipient Email Address:"
                  name="recipientEmail"
                  messageVariables={{ label: 'Recipient Email Address' }}
                  rules={[{ required: true }, { type: 'email' }]}
                >
                  <Input type="email" placeholder="first.last@example.com" />
                </Form.Item>

                <Form.Item
                  label="Send my gift on:"
                  name="sendDate"
                  messageVariables={{ label: 'Date' }}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    size="large"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    changeOnBlur={true}
                    disabledDate={(current) => current < dayjs().startOf('day')}
                  />
                </Form.Item>

                <div className="main-subtitle">Add a note to your gift</div>
                <Form.Item label="From:" name="senderName" messageVariables={{ label: 'Your Name(s)' }}>
                  <Input placeholder="Your name(s)" />
                </Form.Item>

                <Form.Item label="Gift Message:" name="senderText" messageVariables={{ label: 'Message' }}>
                  <Input.TextArea placeholder="Type your message here" autoSize={{ minRows: 3, maxRows: 5 }} />
                </Form.Item>

                <div className="text-right">
                  <Button type="primary" htmlType="submit" size="large" className="gift-button">
                    Continue
                  </Button>
                </div>
              </Form>
            </Col>
            <Col xs={0} lg={6}></Col>
          </Row>
        )}
        {currentStep === CreateGiftStepsEnum.Select && (
          <>
            <div className="main-title">Select From One of Our 3 Message Options</div>
            <Row className="payment-plans" gutter={[30, 30]} justify="center">
              {planList?.map((plan) => (
                <Col xs={24} md={12} lg={8} key={plan.name}>
                  <div className={classNames('payment-plan-item', { popular: !!plan.isPopular })}>
                    <Flex vertical={true} className="plan-block" gap={5}>
                      <div>
                        <div className="title">{plan.name}</div>
                        <div className="price-block">
                          <div className="price">
                            {plan.prices[0].amount ? <>${plan.prices[0].amount / 100}</> : <>Free</>}
                          </div>
                        </div>
                      </div>
                      {plan.highlights && (
                        <div className="highlights">
                          <ul>
                            {plan.highlights.map((highlight, index) => (
                              <li key={index} className={classNames({ disabled: highlight.disabled })}>
                                <Flex gap={10}>
                                  <div>
                                    {highlight.disabled ? <CloseOutlined /> : <CheckOutlined className="tick-icon" />}
                                  </div>
                                  <div dangerouslySetInnerHTML={{ __html: highlight.text }} />
                                </Flex>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <div className="button-block">
                        {plan.accessLevel !== UserAccessLevelPlanEnum.Free && (
                          <Button
                            type="primary"
                            onClick={() => setPrice(plan.prices[0])}
                            block
                            shape="default"
                            size="large"
                          >
                            Select Plan
                          </Button>
                        )}
                      </div>
                    </Flex>
                  </div>
                </Col>
              ))}
            </Row>

            <Button
              className="gift-button"
              size="large"
              onClick={() => {
                setCurrentStep(CreateGiftStepsEnum.Delivery);
              }}
            >
              Back
            </Button>
          </>
        )}
        {currentStep === CreateGiftStepsEnum.Billing && price && formData && (
          <>
            <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd', loader: 'auto' }}>
              <GiftCheckoutComponent
                price={price}
                data={formData}
                onPurchaseDone={() => {
                  clearGiftStorage();
                  setCurrentStep(CreateGiftStepsEnum.Success);
                }}
              />
            </Elements>
            <Button
              className="gift-button"
              size="large"
              onClick={() => {
                setCurrentStep(CreateGiftStepsEnum.Select);
              }}
            >
              Back
            </Button>
          </>
        )}

        {currentStep === CreateGiftStepsEnum.Success && (
          <div className="success-block text-center">
            <h2>Thank you for your purchase!</h2>

            <img src={imageModalCongratulations} alt="" className="image" />

            <p>If have any questions or need any assistance, don't hesitate to reach out.</p>
            <p>Thanks again for choosing Eternal Applications!</p>

            <Button size="large" onClick={() => navigate('/')} className="gift-button">
              Back to Home
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateGiftComponent;
