import classNames from 'classnames';
import { ReactElement } from 'react';
import './ContainerComponent.css';

type AllProps = {
  children: ReactElement;
  className?: string;
};

function ContainerComponent({ children, className }: AllProps) {
  return <div className={classNames('container-component', className)}>{children}</div>;
}

export default ContainerComponent;
