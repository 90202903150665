import { FacebookFilled } from '@ant-design/icons';
import { Alert, Flex } from 'antd';
import { useLoginFacebookMutation, useLoginGoogleMutation } from 'api/AuthApi';
import googleLogoImage from 'assets/google-icon-logo.svg';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { removeAffiliateCode, selectAffiliateCode } from 'store/affiliateSlice';
import { useAppDispatch } from 'store/store';
import { logging } from 'utils/utils';
import './SocialLogin.css';

function SocialLogin() {
  const dispatch = useAppDispatch();
  const affiliateCode = useSelector(selectAffiliateCode);

  const [loginGoogle, { isError: isErrorGoogle, error: errorGoogle }] = useLoginGoogleMutation();
  const [loginFacebook, { isError: isErrorFacebook, error: errorFacebook }] = useLoginFacebookMutation();

  const handleGoogleResponse = async (response: google.accounts.oauth2.TokenResponse) => {
    try {
      await loginGoogle({ accessToken: response.access_token, partnerCode: affiliateCode }).unwrap();

      dispatch(removeAffiliateCode());
    } catch (e) {
      logging(e);
    }
  };

  const handleFacebookResponse = async (response: fb.StatusResponse) => {
    try {
      await loginFacebook({ accessToken: response.authResponse.accessToken!, partnerCode: affiliateCode }).unwrap();

      dispatch(removeAffiliateCode());
    } catch (e) {
      logging(e);
    }
  };

  useEffect(() => {
    if (window.FB) {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID!,
        version: 'v18.0'
      });
    }
  }, []);

  const googleLogin = () => {
    const client = google.accounts.oauth2.initTokenClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
      scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
        // 'https://www.googleapis.com/auth/user.birthday.read',
        // 'https://www.googleapis.com/auth/user.gender.read'
      ].join(' '),
      callback: handleGoogleResponse
    });
    client.requestAccessToken();
  };

  const fbLogin = () => {
    window.FB.login(
      (response) => {
        handleFacebookResponse(response);
      },
      { scope: 'email,public_profile' } // user_birthday,user_gender
    );
  };

  return (
    <div className="social-login">
      {isErrorGoogle && (
        <Alert className="message" message={(errorGoogle as any)?.data?.message} type="error" showIcon />
      )}
      {isErrorFacebook && (
        <Alert className="message" message={(errorFacebook as any)?.data?.message} type="error" showIcon />
      )}

      <Flex vertical={true} justify="center" gap="middle">
        <div className="social-login-button google" onClick={googleLogin}>
          <img src={googleLogoImage} alt="" width={20} height={20} /> Continue with Google
        </div>
        <div className="social-login-button facebook" onClick={fbLogin}>
          <FacebookFilled /> Continue with Facebook
        </div>
      </Flex>
    </div>
  );
}

export default SocialLogin;
