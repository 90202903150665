import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'api/Api';
import { invalidateAuthTags } from 'api/AuthApi';
import { Contact, ContactRelationEnum } from 'api/ContactsApi';
import { User } from './UsersApi';

export interface MessageTemplates {
  messageTemplates: MessageTemplate[];
  messageIdeas: MessageIdeas[];
}

export interface MessageTemplate {
  type: MessageTypeEnum;
  recipients: ContactRelationEnum[];
  eventType: MessageEventTypeEnum;
  title: string;
  content: string;
}

export interface MessageIdeas {
  type: MessageTypeEnum;
  title: string;
  content: string;
}

export interface Message {
  id: string;
  type: MessageTypeEnum;
  status: MessageStatusEnum;
  name: string;
  content: string;
  contacts: Contact[];
  eventType: string;
  deliveryYear: number;
  deliveryMonth: number;
  deliveryDay: number;
  fileName: string;
  isActive: boolean;
  user?: User;
}

export interface UpsertMessage extends Omit<Message, 'contacts'> {
  contactIds: string[];
}

export interface GetFileMessage {
  fileName?: string;
  signedUrl?: string;
}

export enum MessageTypeEnum {
  Text = 'text',
  Audio = 'audio',
  Video = 'video'
}

export enum MessageStatusEnum {
  Draft = 'draft',
  Delivered = 'delivered',
  Published = 'published'
}

export enum MessageEventTypeEnum {
  Final = 'final',
  Birthday = 'birthday',
  Holiday = 'holiday',
  Anniversary = 'anniversary',
  Other = 'other'
}

export type ErrorResponseType = {
  status: number;
  data: { success: boolean; message: string; errors: { stack: string } };
};

export const invalidateMessagesTags = () => messagesApi.util.invalidateTags(['Messages']);

export const messagesApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'messagesApi',
  tagTypes: ['Messages', 'MessageFiles', 'MessageTemplates'],
  endpoints: (build) => ({
    getMessageTemplates: build.query<MessageTemplates, void>({
      query: () => ({ url: '/messages/templates' }),
      transformResponse: (response: { data: MessageTemplates }) => response.data,
      providesTags: () => ['MessageTemplates']
    }),
    getAllMessages: build.query<
      { list: Message[]; total: number },
      { limit?: number; offset?: number; status?: MessageStatusEnum }
    >({
      query: (params) => ({ url: '/messages', params }),
      transformResponse: (response: { data: [Message[], number] }) => ({
        list: response.data[0],
        total: response.data[1]
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.list.map(({ id }) => ({
                type: 'Messages' as const,
                id
              })),
              { type: 'Messages', id: 'LIST' }
            ]
          : [{ type: 'Messages', id: 'LIST' }]
    }),
    getOneMessage: build.query<Message, Pick<Message, 'id'>>({
      query: ({ id }) => ({ url: `/messages/${id}` }),
      transformResponse: (response: { data: Message }) => response.data,
      providesTags: (result, error, { id }) => [{ type: 'Messages', id }]
    }),
    getOneMessagePublic: build.query<Message, { messageContactId: string }>({
      query: ({ messageContactId }) => ({ url: `/public/messages/contacts/${messageContactId}` }),
      transformResponse: (response: { data: Message }) => response.data,
      providesTags: (result, error, { messageContactId }) => [{ type: 'Messages', messageContactId }]
    }),
    getFileMessage: build.query<GetFileMessage, Pick<Message, 'id'>>({
      query: ({ id }) => ({ url: `/messages/${id}/file` }),
      transformResponse: (response: { data: GetFileMessage }) => response.data,
      providesTags: (result, error, { id }) => [{ type: 'MessageFiles', id }]
    }),
    getFileMessagePublic: build.query<GetFileMessage, { messageContactId: string }>({
      query: ({ messageContactId }) => ({ url: `/public/messages/contacts/${messageContactId}/file` }),
      transformResponse: (response: { data: GetFileMessage }) => response.data,
      providesTags: (result, error, { messageContactId }) => [{ type: 'MessageFiles', messageContactId }]
    }),
    upsertMessage: build.mutation<Message, UpsertMessage>({
      query: (body) => ({
        url: `/messages/upsert`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: Message }) => response.data,
      invalidatesTags: (result, error, { id }) =>
        id
          ? [
              { type: 'Messages', id },
              { type: 'MessageFiles', id }
            ]
          : [
              { type: 'Messages', id: 'LIST' },
              { type: 'MessageFiles', id: 'LIST' }
            ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    publishMessage: build.mutation<Message, Pick<Message, 'id'>>({
      query: ({ id }) => ({
        url: `/messages/${id}/publish`,
        method: 'POST'
      }),
      transformResponse: (response: { data: Message }) => response.data,
      invalidatesTags: (result, error, { id }) => [id ? { type: 'Messages', id } : { type: 'Messages', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    getUploadSignedUrlMessage: build.mutation<string, Pick<Message, 'id'> & { fileName: string; contentType: string }>({
      query: ({ id, ...body }) => ({
        url: `/messages/${id}/upload-signed-url`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: { uploadSignedUrl: string } }) => response.data.uploadSignedUrl
    }),
    removeMessage: build.mutation<boolean, Pick<Message, 'id'>>({
      query: ({ id }) => ({
        url: `/messages/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { data: { deleted: boolean } }) => response.data.deleted,
      invalidatesTags: () => [
        { type: 'Messages', id: 'LIST' },
        { type: 'MessageFiles', id: 'LIST' }
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    })
  })
});

export const {
  useGetMessageTemplatesQuery,
  useGetAllMessagesQuery,
  useGetOneMessageQuery,
  useGetOneMessagePublicQuery,
  useGetFileMessagePublicQuery,
  useUpsertMessageMutation,
  usePublishMessageMutation,
  useGetUploadSignedUrlMessageMutation,
  useGetFileMessageQuery,
  useRemoveMessageMutation
} = messagesApi;
