import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'api/Api';
import { invalidateMessagesTags } from 'api/MessagesApi';

export interface Contact {
  id: string;
  name: string;
  email: string;
  phone: string;
  relationship: ContactRelationEnum;
}

export enum ContactRelationEnum {
  Spouse = 'spouse',
  Child = 'child',
  Friend = 'friend',
  Sibling = 'sibling',
  Parent = 'parent',
  Family = 'family',
  Grandparent = 'grandparent',
  Grandchild = 'grandchild',
  SignificantOther = 'significant_other',
  AuntUncle = 'aunt_uncle',
  Cousin = 'cousin',
  Other = 'other'
}

export const contactsApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'contactsApi',
  tagTypes: ['Contacts'],
  endpoints: (build) => ({
    getAllContacts: build.query<{ list: Contact[]; total: number }, { limit?: number; offset?: number }>({
      query: (params) => ({ url: '/contacts', params }),
      transformResponse: (response: { data: [Contact[], number] }) => ({
        list: response.data[0],
        total: response.data[1]
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.list.map(({ id }) => ({
                type: 'Contacts' as const,
                id
              })),
              { type: 'Contacts', id: 'LIST' }
            ]
          : [{ type: 'Contacts', id: 'LIST' }]
    }),
    getOneContact: build.query<Contact, Pick<Contact, 'id'>>({
      query: ({ id }) => ({ url: `/contacts/${id}` }),
      transformResponse: (response: { data: Contact }) => response.data,
      providesTags: (result, error, { id }) => [{ type: 'Contacts', id }]
    }),
    createContact: build.mutation<Contact, Omit<Contact, 'id'>>({
      query: (body) => ({
        url: '/contacts',
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: Contact }) => response.data,
      invalidatesTags: () => [{ type: 'Contacts', id: 'LIST' }]
    }),
    editContact: build.mutation<Contact, Contact>({
      query: ({ id, ...body }) => ({
        url: `/contacts/${id}`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: { contact: Contact } }) => response.data.contact,
      invalidatesTags: (result, error, { id }) => [{ type: 'Contacts', id }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateMessagesTags());
        } catch (e) {}
      }
    }),
    removeContact: build.mutation<boolean, Pick<Contact, 'id'>>({
      query: ({ id }) => ({
        url: `/contacts/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { data: { deleted: boolean } }) => response.data.deleted,
      invalidatesTags: () => [{ type: 'Contacts', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateMessagesTags());
        } catch (e) {}
      }
    })
  })
});

export const {
  useGetAllContactsQuery,
  useGetOneContactQuery,
  useCreateContactMutation,
  useEditContactMutation,
  useRemoveContactMutation
} = contactsApi;
