import { Button, Flex, Space, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
  AdminAccess,
  AdminAccessListEnum,
  AdminAccessRoleEnum,
  AdminAccessStatusEnum,
  useAcceptAdminAccessMutation,
  useDeclineAdminAccessMutation,
  useGetAllAdminAccessQuery
} from 'api/AdminAccessApi';
import { User, UserStatusEnum } from 'api/UsersApi';
import ConfirmModal, { CONFIRM_DECEASED_MODAL_NAME } from 'components/Admin/ConfirmModal/ConfirmModal';
import { ResponsiveTable } from 'components/UiKit/ResponsiveTable/ResponsiveTable';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { getFullNameWithEmail, logging } from 'utils/utils';
import './BeneficiaryListComponent.css';

const PAGE_SIZE = 10;

export type BeneficiaryListComponentProps = {
  title: string;
  isAcceptedAdminStatus: boolean;
};

function BeneficiaryListComponent(props: BeneficiaryListComponentProps) {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const { data: beneficiariesData, isFetching } = useGetAllAdminAccessQuery({
    list: AdminAccessListEnum.Users,
    limit: PAGE_SIZE,
    filter: { status: props.isAcceptedAdminStatus ? AdminAccessStatusEnum.Accepted : AdminAccessStatusEnum.Invited },
    offset: (currentPage - 1) * PAGE_SIZE
  });

  const [acceptAdminAccess, { isLoading: isLoadingAccept }] = useAcceptAdminAccessMutation();
  const [declineAdminAccess, { isLoading: isLoadingDecline }] = useDeclineAdminAccessMutation();

  const handleAccept = async (id: string) => {
    try {
      await acceptAdminAccess({ id }).unwrap();
    } catch (e: any) {
      logging(e);
    }
  };

  const handleDecline = async (id: string) => {
    try {
      await declineAdminAccess({ id }).unwrap();
    } catch (e: any) {
      logging(e);
    }
  };

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const renderStatus = (status: UserStatusEnum) => {
    const statusComponent = {
      [UserStatusEnum.ACTIVE]: <Tag color="success">Active</Tag>,
      [UserStatusEnum.UNVERIFIED_DECEASED]: <Tag color="default">Deceased</Tag>,
      [UserStatusEnum.DECEASED]: <Tag color="error">Deceased</Tag>
    };

    return statusComponent[status];
  };

  const renderActions = (adminAccess: AdminAccess) => {
    return (
      <Space size="small">
        {adminAccess.status === AdminAccessStatusEnum.Invited && (
          <Button type="primary" size="small" onClick={() => handleAccept(adminAccess.id)} loading={isLoadingAccept}>
            Accept
          </Button>
        )}
        {adminAccess.status !== AdminAccessStatusEnum.Declined && !props.isAcceptedAdminStatus && (
          <Button size="small" onClick={() => handleDecline(adminAccess.id)} loading={isLoadingDecline}>
            Decline
          </Button>
        )}

        {adminAccess.status === AdminAccessStatusEnum.Accepted && adminAccess.user.status === UserStatusEnum.ACTIVE && (
          <Button
            type="primary"
            size="small"
            onClick={() =>
              dispatch(
                openModal({
                  name: CONFIRM_DECEASED_MODAL_NAME,
                  data: {
                    user: adminAccess.user
                  }
                })
              )
            }
          >
            Mark Deceased
          </Button>
        )}
      </Space>
    );
  };

  const mobileColumns: ColumnsType<AdminAccess> = [
    {
      render: (adminAccess: AdminAccess) => (
        <Space size={4} direction="vertical" className="responsive-table-card">
          <div className="responsive-table-title">
            {adminAccess.role === AdminAccessRoleEnum.Manager && adminAccess.user.status === UserStatusEnum.DECEASED ? (
              <Link to={`/beneficiaries/${adminAccess.id}`}>{getFullNameWithEmail(adminAccess.user)}</Link>
            ) : (
              getFullNameWithEmail(adminAccess.user)
            )}
          </div>

          <Flex justify="space-between">
            <div>{renderStatus(adminAccess.user.status)}</div>
            <div>{renderActions(adminAccess)}</div>
          </Flex>
        </Space>
      )
    }
  ];

  const columns: ColumnsType<AdminAccess> = [
    {
      title: 'Beneficiary',
      key: 'name',
      dataIndex: 'user',
      ellipsis: true,
      render: (user: User, adminAccess: AdminAccess) =>
        adminAccess.role === AdminAccessRoleEnum.Manager && user.status === UserStatusEnum.DECEASED ? (
          <Link to={`/beneficiaries/${adminAccess.id}`}>{getFullNameWithEmail(user)}</Link>
        ) : (
          getFullNameWithEmail(user)
        )
    }
  ];

  if (props.isAcceptedAdminStatus) {
    columns.push({
      title: 'User Status',
      key: 'user',
      dataIndex: 'user',
      render: (user: User) => renderStatus(user.status)
    });
  }

  columns.push({
    title: 'Actions',
    key: 'actions',
    render: (_, adminAccess) => renderActions(adminAccess)
  });

  if (!beneficiariesData?.total) {
    return null;
  }

  return (
    <>
      <ConfirmModal />

      <div className="beneficiary-list-component">
        <h3>
          {props.title} ({beneficiariesData?.total || 0})
        </h3>

        <ResponsiveTable
          mobileUntilBreakpoint="lg"
          mobileColumns={mobileColumns}
          columns={columns}
          bordered
          rowKey="id"
          onChange={onTableChanged}
          dataSource={beneficiariesData?.list || []}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            current: currentPage,
            total: beneficiariesData?.total || 0
          }}
          loading={isFetching}
        />
      </div>
    </>
  );
}

export default BeneficiaryListComponent;
