import { Col, Row, Typography } from 'antd';
import { useGetOneUserSysAdminQuery } from 'api/SysAdminApi';
import SysAdminMessageListComponent from 'components/SysAdmin/SysAdminMessageListComponent/SysAdminMessageListComponent';
import SysAdminPaymentListComponent from 'components/SysAdmin/SysAdminPaymentListComponent/SysAdminPaymentListComponent';
import SysAdminPreviewMessageModalComponent from 'components/SysAdmin/SysAdminPreviewMessageModalComponent/SysAdminPreviewMessageModalComponent';
import { getFullNameOrEmail } from 'utils/utils';
import './SysAdminUserDetailsComponent.css';

type AllProps = {
  userId: string;
};

function SysAdminUserDetailsComponent({ userId }: AllProps) {
  const { data: sysAdminUserData } = useGetOneUserSysAdminQuery({
    id: userId
  });

  return (
    <>
      <div className="sys-admin-user-list-component">
        <Typography>
          <h2>{getFullNameOrEmail(sysAdminUserData)}</h2>
        </Typography>

        <Row gutter={[0, 10]}>
          <Col xs={12}>Email: {sysAdminUserData?.email}</Col>
          <Col xs={12}>Gender: {sysAdminUserData?.gender}</Col>
          <Col xs={12}>Status: {sysAdminUserData?.status}</Col>
          <Col xs={12}>Access Level: {sysAdminUserData?.accessLevelPlan}</Col>
          <Col xs={12}>Total messages: {sysAdminUserData?.messagesCount}</Col>
          <Col xs={12}>Completed messages: {sysAdminUserData?.messagesPublishedCount}</Col>
        </Row>

        <SysAdminPaymentListComponent userId={userId} />
        <SysAdminMessageListComponent userId={userId} />
      </div>

      <SysAdminPreviewMessageModalComponent />
    </>
  );
}

export default SysAdminUserDetailsComponent;
