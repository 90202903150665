import { Modal } from 'antd';
import { useGetOneAdminAccessQuery, useRemoveAdminAccessMutation } from 'api/AdminAccessApi';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { getFullNameWithEmail } from 'utils/utils';
import './RemoveAdminModalComponent.css';

export const DELETE_ADMIN_MODAL_NAME = 'DELETE_ADMIN_MODAL_NAME';

type AllProps = {
  adminAccessId: string;
};

function RemoveAdminModalComponent({ adminAccessId }: AllProps) {
  const dispatch = useAppDispatch();
  const [removeAdminAccess, { isLoading: isLoadingRemove }] = useRemoveAdminAccessMutation();

  const { data: adminAccess, isLoading } = useGetOneAdminAccessQuery({ id: adminAccessId });

  const handleRemove = async () => {
    await removeAdminAccess({ id: adminAccessId }).unwrap();
    handleCloseModal();
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title="Remove Administrator"
      onOk={handleRemove}
      onCancel={handleCloseModal}
      okText="Remove"
      confirmLoading={isLoadingRemove}
      className="size-sm"
    >
      <SpinComponent isLoading={isLoading}>
        <div className="remove-admin-modal-component">
          <p>
            Do you want to remove <strong>{getFullNameWithEmail(adminAccess?.admin)}</strong>?
          </p>
        </div>
      </SpinComponent>
    </Modal>
  );
}

function RemoveAdminModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const adminAccessId = modalData?.id || null;

  return modalName === DELETE_ADMIN_MODAL_NAME ? <RemoveAdminModalComponent adminAccessId={adminAccessId} /> : null;
}

export default RemoveAdminModalComponentWrapper;
