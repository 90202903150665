import { Modal } from 'antd';
import { MessageTemplate } from 'api/MessagesApi';
import { TextToHtmlComponent } from 'components/UiKit/TextToHtmlComponent/TextToHtmlComponent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './PreviewMessageTemplateModalComponent.css';

export const PREVIEW_MESSAGE_TEMPLATE_MODAL_NAME = 'PREVIEW_MESSAGE_TEMPLATE_MODAL_NAME';

type AllProps = {
  messageTemplate: MessageTemplate;
};

function PreviewMessageTemplateModalComponent({ messageTemplate }: AllProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCreateMessage = () => {
    navigate('/message', { state: { messageType: messageTemplate.type, messageTemplate } });
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title={messageTemplate.title}
      onOk={handleCreateMessage}
      onCancel={handleCloseModal}
      okText="Create Message"
      cancelText="Close"
    >
      <div className="preview-message-template-modal-component">
        <div>
          <span className="content">
            <TextToHtmlComponent text={messageTemplate.content} />
          </span>
        </div>
      </div>
    </Modal>
  );
}

function PreviewMessageTemplateModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const messageTemplate = modalData?.messageTemplate || null;

  return modalName === PREVIEW_MESSAGE_TEMPLATE_MODAL_NAME ? (
    <PreviewMessageTemplateModalComponent messageTemplate={messageTemplate} />
  ) : null;
}

export default PreviewMessageTemplateModalComponentWrapper;
