import { WELCOME_MODAL_NAME } from 'components/Account/WelcomeModalComponent/WelcomeModalComponent';
import CreateMessageComponent from 'components/Message/CreateMessageComponent/CreateMessageComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { selectAuthUser } from 'store/authSlice';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './CreateMessage.css';

function CreateMessage() {
  const dispatch = useAppDispatch();
  const user = useSelector(selectAuthUser);
  const { messageId } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (user && !user.isInitialized) {
      dispatch(openModal({ name: WELCOME_MODAL_NAME }));
    }
  }, [user]);

  return (
    <>
      <div className="dashboard layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content-flex">
          <>
            <CreateMessageComponent
              messageId={messageId}
              messageType={location.state?.messageType}
              messageTemplate={location.state?.messageTemplate}
              step={location.state?.step}
            />
          </>
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default CreateMessage;
