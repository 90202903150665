import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Form, Input, Popconfirm, Progress, Row, Space } from 'antd';
import { Message, MessageTypeEnum, useGetFileMessageQuery } from 'api/MessagesApi';
import { AxiosProgressEvent } from 'axios';
import { StepMessageData } from 'components/Message/CreateMessageComponent/StepMessageComponent/StepMessageComponent';
import { useMemo } from 'react';
import { isSupportedAudioFile, isSupportedVideoFile } from 'utils/utils';
import './StepMessagePreviewComponent.css';

type AllProps = {
  message: Message;
  messageType: MessageTypeEnum;
  file?: File | null;
  fileName?: string;
  content?: string;
  onBack: () => void;
  onNext: (data: StepMessageData, file?: File | null, afterAction?: 'next' | 'none' | 'exit') => void;
  onRestart: () => void;
  isLoading?: boolean;
  onAbortUploading: () => void;
  axiosProgressEvent: AxiosProgressEvent | null;
};

function StepMessagePreviewComponent({
  message,
  messageType,
  file,
  fileName,
  content,
  onBack,
  onNext,
  onRestart,
  isLoading,
  onAbortUploading,
  axiosProgressEvent
}: AllProps) {
  const [form] = Form.useForm();

  const { data: messageFile, isLoading: isLoadingFile } = useGetFileMessageQuery({ id: message.id });

  const onSubmit = async (isExit?: boolean) => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      const data: StepMessageData = {
        content: fields.content
      };

      if (fileName) {
        onNext(data, undefined, isExit ? 'exit' : 'next');
      } else {
        onNext(data, file, isExit ? 'exit' : 'next');
      }
    } catch (e) {}
  };

  const selectedFileName = file?.name || fileName || '';

  const renderVideo = useMemo(
    () => (
      <div className="video-overlay">
        {file ? (
          <video width="100%" src={URL.createObjectURL(file)} controls playsInline />
        ) : messageFile?.signedUrl ? (
          <video width="100%" src={messageFile.signedUrl} controls playsInline />
        ) : null}
      </div>
    ),
    [file, messageFile]
  );

  const renderAudio = useMemo(
    () => (
      <div className="audio-overlay">
        {file ? (
          <audio src={URL.createObjectURL(file)} controls />
        ) : messageFile?.signedUrl ? (
          <audio src={messageFile.signedUrl} controls />
        ) : null}
      </div>
    ),
    [file, messageFile]
  );

  return (
    <div className="step-message-preview-component create-message-component-full-height">
      <Form
        layout="vertical"
        onFinish={onSubmit}
        autoComplete="off"
        requiredMark="optional"
        form={form}
        initialValues={{ content }}
        className="content-area"
      >
        <div className="create-message-component-subtitle text-left">Message Content</div>

        {selectedFileName ? (
          <>
            {messageType === MessageTypeEnum.Video && isSupportedVideoFile(selectedFileName) ? (
              renderVideo
            ) : messageType === MessageTypeEnum.Audio && isSupportedAudioFile(selectedFileName) ? (
              renderAudio
            ) : selectedFileName ? (
              <Space align="center" className="selected-file">
                {selectedFileName}{' '}
                <a onClick={onRestart}>
                  <CloseOutlined />
                </a>
              </Space>
            ) : null}
          </>
        ) : messageType === MessageTypeEnum.Text ? (
          <Form.Item name="content" rules={[{ required: true }]}>
            <Input.TextArea
              autoFocus
              rows={14}
              placeholder="Type your message here!"
              maxLength={5000}
              showCount={true}
            />
          </Form.Item>
        ) : null}

        {axiosProgressEvent && (
          <>
            <div>Uploading...</div>
            <Flex align="center" className="uploading-progress" justify="space-between">
              <Progress percent={Math.round((axiosProgressEvent.progress || 0.5) * 100)}></Progress>
              <Button onClick={onAbortUploading} type="text">
                Cancel
              </Button>
            </Flex>
          </>
        )}
        <Popconfirm
          title="Start over?"
          description="You will lose all your progress with this message"
          onConfirm={onRestart}
          okText="Start over"
          cancelText="Cancel"
        >
          <Button>Start Over</Button>
        </Popconfirm>
      </Form>

      <Row className="create-message-component-footer">
        <Col span={12}>
          <Button onClick={onBack} type="link">
            <ArrowLeftOutlined /> Back
          </Button>
        </Col>
        <Col span={12} className="text-right">
          <Flex gap={20} justify="flex-end">
            <Button onClick={() => onSubmit(true)}>Save & Exit</Button>
            <Button onClick={() => onSubmit()} type="primary" loading={isLoading}>
              Next
            </Button>
          </Flex>
        </Col>
      </Row>
    </div>
  );
}

export default StepMessagePreviewComponent;
