import { Message, MessageTemplate, MessageTypeEnum } from 'api/MessagesApi';
import { AxiosProgressEvent } from 'axios';
import AudioRecorderComponent from 'components/Message/CreateMessageComponent/StepMessageComponent/AudioRecorderComponent/AudioRecorderComponent';
import StepMessageOptionsComponent from 'components/Message/CreateMessageComponent/StepMessageComponent/StepMessageOptionsComponent/StepMessageOptionsComponent';
import StepMessagePreviewComponent from 'components/Message/CreateMessageComponent/StepMessageComponent/StepMessagePreviewComponent/StepMessagePreviewComponent';
import VideoRecorderComponent from 'components/Message/CreateMessageComponent/StepMessageComponent/VideoRecorderComponent/VideoRecorderComponent';
import { useState } from 'react';
import './StepMessageComponent.css';

export const enum StepMessageStepsEnum {
  Init,
  Creating,
  Preview
}

export type StepMessageData = Pick<Message, 'content'>;

type AllProps = {
  message: Message;
  messageType: MessageTypeEnum;
  messageTemplate?: MessageTemplate;
  onBack: () => void;
  onNext: (data: StepMessageData, file?: File | null, afterAction?: 'next' | 'none' | 'exit') => void;
  isLoading?: boolean;
  onAbortUploading: () => void;
  axiosProgressEvent: AxiosProgressEvent | null;
};

function StepMessageComponent({
  message,
  messageType,
  messageTemplate,
  onBack,
  onNext,
  isLoading,
  onAbortUploading,
  axiosProgressEvent
}: AllProps) {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState(message.fileName || '');
  const [content, setContent] = useState(message?.content || messageTemplate?.content || '');
  const [currentStep, setCurrentStep] = useState(
    message?.fileName || (messageType === MessageTypeEnum.Text && content)
      ? StepMessageStepsEnum.Preview
      : StepMessageStepsEnum.Init
  );

  const selectFile = (file: File) => {
    setFile(file);
    setCurrentStep(StepMessageStepsEnum.Preview);
  };

  return (
    <>
      {currentStep === StepMessageStepsEnum.Creating ? (
        <>
          {messageType === MessageTypeEnum.Video && (
            <VideoRecorderComponent
              onDone={(file) => {
                setCurrentStep(StepMessageStepsEnum.Preview);
                setFile(file);
              }}
              onCancel={() => {
                setCurrentStep(StepMessageStepsEnum.Init);
              }}
            />
          )}
          {messageType === MessageTypeEnum.Audio && (
            <AudioRecorderComponent
              onDone={(file) => {
                setCurrentStep(StepMessageStepsEnum.Preview);
                setFile(file);
              }}
              onCancel={() => {
                setCurrentStep(StepMessageStepsEnum.Init);
              }}
            />
          )}
        </>
      ) : currentStep === StepMessageStepsEnum.Preview ? (
        <StepMessagePreviewComponent
          axiosProgressEvent={axiosProgressEvent}
          onAbortUploading={onAbortUploading}
          onBack={onBack}
          onNext={onNext}
          messageType={messageType}
          file={file}
          fileName={fileName}
          content={content}
          message={message}
          onRestart={() => {
            onNext({ content: '' }, null, 'none');
            setCurrentStep(StepMessageStepsEnum.Init);
          }}
          isLoading={isLoading}
        />
      ) : (
        <StepMessageOptionsComponent
          messageType={messageType}
          onSelectFile={selectFile}
          onBack={onBack}
          onCreating={() => setCurrentStep(StepMessageStepsEnum.Creating)}
          onPreview={() => {
            setContent('');
            setCurrentStep(StepMessageStepsEnum.Preview);
          }}
        />
      )}
    </>
  );
}

export default StepMessageComponent;
