import { Alert, Modal } from 'antd';
import { useGetOneContactQuery, useRemoveContactMutation } from 'api/ContactsApi';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { getResponseErrors, logging } from 'utils/utils';
import './RemoveContactModalComponent.css';

export const DELETE_CONTACT_MODAL_NAME = 'DELETE_CONTACT_MODAL_NAME';

type AllProps = {
  contactId: string;
};

function RemoveContactModalComponent({ contactId }: AllProps) {
  const dispatch = useAppDispatch();
  const [removeContact, { isLoading: isLoadingRemove, error: errorRemove }] = useRemoveContactMutation();

  const { data: contact, isLoading } = useGetOneContactQuery({ id: contactId });

  const handleRemove = async () => {
    try {
      await removeContact({ id: contactId }).unwrap();
      handleCloseModal();
    } catch (e) {
      logging(e);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title="Remove Contact"
      onOk={handleRemove}
      onCancel={handleCloseModal}
      okText="Remove"
      confirmLoading={isLoadingRemove}
      className="size-sm"
    >
      <SpinComponent isLoading={isLoading}>
        <div className="remove-contact-modal-component">
          {errorRemove && (
            <Alert className="message" message={getResponseErrors(errorRemove).join(', ')} type="error" showIcon />
          )}
          <p>
            Do you want to remove <strong>{contact?.name}</strong>?
          </p>
          <p>This will also delete the selected contact from all messages.</p>
        </div>
      </SpinComponent>
    </Modal>
  );
}

function RemoveContactModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const contactId = modalData?.id || null;

  return modalName === DELETE_CONTACT_MODAL_NAME ? <RemoveContactModalComponent contactId={contactId} /> : null;
}

export default RemoveContactModalComponentWrapper;
