import { Alert, Form, Input, Modal } from 'antd';
import {
  AdminAccessRoleEnum,
  useCreateAdminAccessMutation,
  useEditAdminAccessMutation,
  useGetOneAdminAccessQuery
} from 'api/AdminAccessApi';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { BubbleHintComponent, BubbleHintOption } from 'components/UiKit/BubbleHintComponent/BubbleHintComponent';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName, selectModalOnClose } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { logging, validateForm } from 'utils/utils';
import './UpsertAdminModalComponent.css';

export const UPSERT_ADMIN_MODAL_NAME = 'UPSERT_ADMIN_MODAL_NAME';

type AllProps = {
  adminAccessId: string | null;
  onClose?: () => void;
};

function UpsertAdminModalComponent({ adminAccessId, onClose }: AllProps) {
  const dispatch = useAppDispatch();

  const [createAdminAccess, { isLoading: isLoadingCreate, isError: isErrorCreate }] = useCreateAdminAccessMutation();
  const [editAdminAccess, { isLoading: isLoadingEdit, isError: isErrorEdit }] = useEditAdminAccessMutation();
  const isEditing = !!adminAccessId;
  const [role, setRole] = useState(AdminAccessRoleEnum.Activator);

  const [form] = Form.useForm();

  const { data: adminAccess, isLoading } = useGetOneAdminAccessQuery({ id: adminAccessId! }, { skip: !adminAccessId });

  useEffect(() => {
    if (adminAccess) {
      form.setFieldsValue(adminAccess);
      form.setFieldValue('email', adminAccess.admin.email);

      setRole(adminAccess.role);
    }
  }, [adminAccess]);

  const handleUpsert = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      if (isEditing) {
        await editAdminAccess({
          id: adminAccessId,
          role,
          relationship: fields.relationship,
          guessedAdminName: fields.guessedAdminName
        }).unwrap();
      } else {
        await createAdminAccess({
          email: fields.email,
          role,
          relationship: fields.relationship,
          guessedAdminName: fields.guessedAdminName
        }).unwrap();
      }

      onClose && onClose();
      handleCloseModal();
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const roleOptions: BubbleHintOption[] = [
    {
      value: AdminAccessRoleEnum.Activator,
      title: 'Notify Only',
      description:
        'Your admin will only be able to mark you as deceased. They will not be able to update delivery date or contact information of your recipient(s).'
    },
    {
      value: AdminAccessRoleEnum.Manager,
      title: 'Manage',
      description:
        'Your administrator will be able to mark you as deceased, update contact information and delivery date of your messages. They will NOT be able to see your message content.'
    }
  ];

  return (
    <Modal
      open={true}
      title={isEditing ? 'Edit Administrator' : 'Add Administrator'}
      onOk={handleUpsert}
      onCancel={handleCloseModal}
      okText={isEditing ? 'Update' : 'Create'}
      confirmLoading={isLoadingCreate || isLoadingEdit}
    >
      <SpinComponent isLoading={isLoading}>
        <div className="upsert-admin-modal-component">
          <Form name="basic" layout="vertical" autoComplete="off" requiredMark="optional" form={form}>
            {!isEditing && (
              <p>
                Your Administrator will receive an email with instructions on how to notify us of your passing so that
                we can begin to deliver your messages at the appropriate time.
              </p>
            )}

            {isErrorCreate && (
              <Alert className="message" message="Cannot create an administrator" type="error" showIcon />
            )}
            {isErrorEdit && (
              <Alert className="message" message="Cannot update an administrator" type="error" showIcon />
            )}

            <Form.Item label="Administrator Full Name" name="guessedAdminName" rules={[{ required: true }]}>
              <Input size="large" />
            </Form.Item>

            <Form.Item label="Administrator Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
              <Input size="large" placeholder="email@domain.com" disabled={isEditing} />
            </Form.Item>

            <Form.Item label="Relationship" name="relationship">
              <Input size="large" placeholder="Ex) Spouse, Child, Family, etc." />
            </Form.Item>

            <Form.Item label="Access" required={true}>
              <BubbleHintComponent
                options={roleOptions}
                selectedValue={role}
                onChange={(value) => setRole(value as AdminAccessRoleEnum)}
              />
            </Form.Item>
          </Form>
        </div>
      </SpinComponent>
    </Modal>
  );
}

function UpsertAdminModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const modalOnClose = useSelector(selectModalOnClose);
  const adminAccessId = modalData?.id || null;

  return modalName === UPSERT_ADMIN_MODAL_NAME ? (
    <UpsertAdminModalComponent adminAccessId={adminAccessId} onClose={modalOnClose} />
  ) : null;
}

export default UpsertAdminModalComponentWrapper;
