import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAffiliateCode } from 'store/affiliateSlice';
import { selectAuthUser } from 'store/authSlice';
import { useAppDispatch } from 'store/store';

function AffiliateLink() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectAuthUser);
  const { affiliateCode } = useParams();

  useEffect(() => {
    dispatch(setAffiliateCode(affiliateCode || ''));

    if (user) {
      navigate('/');
    } else {
      navigate('/signup');
    }
  }, []);

  return <></>;
}

export default AffiliateLink;
