import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Form, Input, Row } from 'antd';
import { Message, MessageTemplate } from 'api/MessagesApi';
import { logging } from 'utils/utils';
import './StepTitleComponent.css';

export type StepTitleData = Pick<Message, 'name'>;

type AllProps = {
  message: Message;
  onNext: (data: StepTitleData, file?: File | null, afterAction?: 'next' | 'none' | 'exit') => void;
  onBack: () => void;
  messageTemplate?: MessageTemplate;
  isLoading?: boolean;
};

function StepTitleComponent({ message, onNext, onBack, isLoading, messageTemplate }: AllProps) {
  const [form] = Form.useForm();

  const onSubmit = async (isExit?: boolean) => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      const data: StepTitleData = {
        name: fields.name
      };
      onNext(data, undefined, isExit ? 'exit' : 'next');
    } catch (e) {
      logging(e);
    }
  };

  return (
    <div className="step-title-component create-message-component-full-height">
      <Form
        layout="vertical"
        onFinish={onSubmit}
        autoComplete="off"
        requiredMark="optional"
        form={form}
        initialValues={{ name: message.name || messageTemplate?.title }}
      >
        <div className="create-message-component-title">Give your message a name.</div>

        <Form.Item name="name" label="Message Title" rules={[{ required: true }]}>
          <Input placeholder="Message Name" />
        </Form.Item>
      </Form>

      <Row className="create-message-component-footer">
        <Col span={12}>
          <Button onClick={onBack} type="link">
            <ArrowLeftOutlined /> Back
          </Button>
        </Col>
        <Col span={12} className="text-right">
          <Flex gap={20} justify="flex-end">
            <Button onClick={() => onSubmit(true)}>Save & Exit</Button>
            <Button onClick={() => onSubmit()} type="primary" loading={isLoading}>
              Next
            </Button>
          </Flex>
        </Col>
      </Row>
    </div>
  );
}

export default StepTitleComponent;
