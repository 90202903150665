import { FundViewOutlined, GiftOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import './SysAdminMenu.css';

export enum SysAdminMenuEnum {
  USERS = 'users',
  PAYMENTS = 'payments',
  PAYMENT_COUPONS = 'paymentCoupons',
  REPORT = 'report',
  GIFTS = 'gifts'
}

type AllProps = {
  selectedMenu?: SysAdminMenuEnum;
};

function SysAdminMenu({ selectedMenu }: AllProps) {
  const items: MenuProps['items'] = [
    {
      label: <Link to="/sysadmin/users">Users</Link>,
      key: SysAdminMenuEnum.USERS,
      icon: <UserOutlined />
    },
    {
      label: <Link to="/sysadmin/payments">Payments</Link>,
      key: SysAdminMenuEnum.PAYMENTS,
      icon: <ShoppingCartOutlined />
    },
    {
      label: <Link to="/sysadmin/report">Report</Link>,
      key: SysAdminMenuEnum.REPORT,
      icon: <FundViewOutlined />
    },
    {
      label: <Link to="/sysadmin/gifts">Gifts</Link>,
      key: SysAdminMenuEnum.GIFTS,
      icon: <GiftOutlined />
    }
  ];

  return (
    <>
      <div className="sys-admin-menu">
        <Menu selectedKeys={[selectedMenu!]} mode="horizontal" items={items} />
      </div>
    </>
  );
}

export default SysAdminMenu;
