import { Button, Modal } from 'antd';
import { useInitializeMeUserMutation } from 'api/UsersApi';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'store/authSlice';
import { closeModal, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { logging } from 'utils/utils';
import './WelcomeModalComponent.css';

export const WELCOME_MODAL_NAME = 'WELCOME_MODAL_NAME';

function WelcomeModalComponent() {
  const user = useSelector(selectAuthUser);
  const dispatch = useAppDispatch();

  const [initializeMeUser] = useInitializeMeUserMutation();

  const handleOk = async () => {
    try {
      await initializeMeUser().unwrap();
    } catch (e) {
      logging(e);
    } finally {
      dispatch(closeModal());
    }
  };

  return (
    <Modal open={true} footer={null} onCancel={() => handleOk()} className="size-sm">
      <div className="welcome-modal-component">
        <h2>Welcome!</h2>
        <p className="main-text">We're thrilled to have you join our community.</p>

        <Button type="primary" size="large" onClick={() => handleOk()}>
          Get Started!
        </Button>
      </div>
    </Modal>
  );
}

function WelcomeModalComponentWrapper() {
  const modalName = useSelector(selectModalName);

  return modalName === WELCOME_MODAL_NAME ? <WelcomeModalComponent /> : null;
}

export default WelcomeModalComponentWrapper;
