import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Radio, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import './BubbleHintComponent.css';

export interface BubbleHintOption {
  value: string;
  title: string;
  description: string;
}

type AllProps = {
  options: BubbleHintOption[];
  selectedValue?: string;
  onChange: (value: string) => void;
};

export function BubbleHintComponent({ options, selectedValue, onChange }: AllProps) {
  const [value, setValue] = useState(options[0].value);

  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  const renderInfo = () => {
    const option = options.find((option) => option.value === value);
    return option ? (
      <div className="event-type-info">
        <div className="event-type-title">
          <InfoCircleOutlined className="event-type-icon" /> {option.title}
        </div>
        {option.description}
      </div>
    ) : null;
  };

  return (
    <Row gutter={[10, 10]} className="bubble-hint-component">
      <Col xs={24} sm={12}>
        <Radio.Group
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          value={value}
        >
          <Space direction="vertical">
            {options.map((option, index) => (
              <Radio value={option.value} key={index}>
                {option.title}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Col>
      <Col xs={24} sm={12}>
        <div className="event-type-info-block">{renderInfo()}</div>
      </Col>
    </Row>
  );
}
