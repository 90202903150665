import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import type { AuthUser } from 'api/AuthApi';
import { authApi } from 'api/AuthApi';
import type { RootState } from 'store/store';

type AuthState = {
  user: AuthUser | null;
  isSpecialPrice: boolean;
  accessToken: string | null;
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isSpecialPrice: false,
    accessToken: localStorage.getItem('accessToken') || null
  } as AuthState,
  reducers: {
    setAccessToken(state, action: PayloadAction<string>) {
      localStorage.setItem('accessToken', action.payload);

      state.accessToken = action.payload;
    },
    setIsSpecialPrice(state, action: PayloadAction<boolean>) {
      state.isSpecialPrice = action.payload;
    },
    logout(state) {
      localStorage.removeItem('accessToken');

      state.accessToken = null;
      state.user = null;

      // TODO: Clear all cache after logout: dispatch(api.util.resetApiState())
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          authApi.endpoints.login.matchFulfilled,
          authApi.endpoints.loginGoogle.matchFulfilled,
          authApi.endpoints.loginFacebook.matchFulfilled
        ),
        (state, { payload }) => {
          localStorage.setItem('accessToken', payload);

          state.accessToken = payload;
        }
      )
      .addMatcher(authApi.endpoints.me.matchFulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, { payload }) => {
        localStorage.removeItem('accessToken');

        state.user = null;
        state.accessToken = null;
      });
  }
});

export const { setAccessToken, logout, setIsSpecialPrice } = slice.actions;
export default slice.reducer;

export const selectAuthUser = (state: RootState) => state.auth.user;
export const selectAuthToken = (state: RootState) => state.auth.accessToken;
