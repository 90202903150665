import { Button, Col, Row, Select, Tabs } from 'antd';
import { ContactRelationEnum } from 'api/ContactsApi';
import { MessageEventTypeEnum } from 'api/MessagesApi';
import filterTemplatesImage from 'assets/filter-templates.svg';
import MessageTemplateExamplesComponent from 'components/Message/MessageTemplatesComponent/MessageTemplateExamplesComponent/MessageTemplateExamplesComponent';
import MessageTemplateIdeasComponent from 'components/Message/MessageTemplatesComponent/MessageTemplateIdeasComponent/MessageTemplateIdeasComponent';
import PreviewMessageTemplateModalComponent from 'components/Message/MessageTemplatesComponent/PreviewMessageTemplateModalComponent/PreviewMessageTemplateModalComponent';
import { useState } from 'react';
import './MessageTemplatesComponent.css';

export interface MessageTemplatesFilters {
  recipient: ContactRelationEnum | '';
  eventType: MessageEventTypeEnum | '';
}

const enum MessageTemplateTabsEnum {
  IDEAS = 'ideas',
  EXAMPLES = 'examples'
}

function MessageTemplatesComponent() {
  const [currentTab, setCurrentTab] = useState(MessageTemplateTabsEnum.IDEAS);
  const [filters, setFilters] = useState<MessageTemplatesFilters>({
    recipient: '',
    eventType: ''
  });

  const updateFilter = (newFilters: Partial<MessageTemplatesFilters>) => {
    setFilters((filters) => ({ ...filters, ...newFilters }));
  };

  const resetFilters = () => {
    setFilters({
      recipient: '',
      eventType: ''
    });
  };

  return (
    <>
      <div className="message-templates-component">
        <div className="filter-block">
          <Row justify="space-between" gutter={10}>
            <Col md={16}>
              <h2>Filter results to find inspiration exactly to your need</h2>
              <p className="filter-description">
                Check out the Ideas tab for guidance or browse the Examples section for ready-to-use messages for any
                occasion.
              </p>
            </Col>
            <Col xs={0} sm={0} md={8} className="text-right">
              <img src={filterTemplatesImage} alt="" />
            </Col>
          </Row>
          {currentTab === MessageTemplateTabsEnum.EXAMPLES && (
            <Row gutter={[10, 10]}>
              <Col>
                <div className="info-field">
                  <div className="label">Recipient</div>
                  <Select
                    onChange={(value) => updateFilter({ recipient: value })}
                    value={filters.recipient}
                    size="large"
                    className="filter-select"
                    options={[
                      { value: '', label: 'All' },
                      { value: ContactRelationEnum.Spouse, label: 'Spouse' },
                      { value: ContactRelationEnum.Child, label: 'Child/Grandchild' },
                      { value: ContactRelationEnum.Sibling, label: 'Sibling' },
                      { value: ContactRelationEnum.Friend, label: 'Friend' },
                      { value: ContactRelationEnum.Parent, label: 'Parent/Grandparent' }
                    ]}
                  />
                </div>
              </Col>
              <Col>
                <div className="info-field">
                  <div className="label">Event type</div>
                  <Select
                    onChange={(value) => updateFilter({ eventType: value })}
                    value={filters.eventType}
                    size="large"
                    className="filter-select"
                    options={[
                      { value: '', label: 'All' },
                      { value: MessageEventTypeEnum.Final, label: 'Parting Message' },
                      { value: MessageEventTypeEnum.Birthday, label: 'Birthday' },
                      { value: MessageEventTypeEnum.Holiday, label: 'Holiday' },
                      { value: MessageEventTypeEnum.Anniversary, label: 'Anniversary' }
                    ]}
                  />
                </div>
              </Col>
              <Col>
                <Button size="large" type="text" onClick={resetFilters}>
                  Reset
                </Button>
              </Col>
            </Row>
          )}
        </div>

        <Tabs
          type="card"
          defaultActiveKey={currentTab}
          onChange={(tab) => setCurrentTab(tab as MessageTemplateTabsEnum)}
          items={[
            {
              label: `Ideas`,
              key: MessageTemplateTabsEnum.IDEAS,
              children: <MessageTemplateIdeasComponent />
            },
            {
              label: `Examples`,
              key: MessageTemplateTabsEnum.EXAMPLES,
              children: <MessageTemplateExamplesComponent filters={filters} />
            }
          ]}
        />
      </div>

      <PreviewMessageTemplateModalComponent />
    </>
  );
}

export default MessageTemplatesComponent;
