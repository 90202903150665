import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

type ConfigState = {
  isTourDone: boolean;
};

const slice = createSlice({
  name: 'config',
  initialState: {
    isTourDone: !!localStorage.getItem('tourDone')
  } as ConfigState,
  reducers: {
    tourDone(state) {
      localStorage.setItem('tourDone', '1');

      state.isTourDone = true;
    }
  }
});

export const { tourDone } = slice.actions;
export default slice.reducer;

export const selectConfig = (state: RootState) => state.config;
export const selectConfigIsTourDone = (state: RootState) => selectConfig(state).isTourDone;
