import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { useSignupMutation } from 'api/AuthApi';
import classNames from 'classnames';
import AuthComponent from 'components/AuthComponent/AuthComponent';
import SocialLogin from 'pages/Auth/SocialLogin/SocialLogin';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { removeAffiliateCode, selectAffiliateCode } from 'store/affiliateSlice';
import { useAppDispatch } from 'store/store';
import { logging, validateForm } from 'utils/utils';
import './SignUp.css';

function SignUp() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const affiliateCode = useSelector(selectAffiliateCode);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isConfirmedError, setIsConfirmedError] = useState(false);
  const [signup, { isError, isLoading }] = useSignupMutation();
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      setIsConfirmedError(false);
      if (!isConfirmed) {
        setIsConfirmedError(true);
        return;
      }

      await signup({
        email: fields.email,
        password: fields.password,
        partnerCode: affiliateCode
      }).unwrap();

      dispatch(removeAffiliateCode());

      form.setFieldValue('email', '');
      form.setFieldValue('password', '');

      navigate(`/confirm-email?email=${encodeURIComponent(fields.email)}`);
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  return (
    <AuthComponent
      title="Sign Up"
      subtitle={
        <>
          Already have an account? <Link to="/login">Login Here</Link>
        </>
      }
    >
      <div className="signup">
        <Divider plain>or</Divider>

        <SocialLogin />

        <Divider plain>or</Divider>

        <Form name="basic" layout="vertical" onFinish={onSubmit} autoComplete="off" requiredMark="optional" form={form}>
          {isError && <Alert className="message" message="Cannot create an account" type="error" showIcon />}

          <Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
            <Input size="large" placeholder="email@domain.com" />
          </Form.Item>

          <Form.Item label="Password" name="password" rules={[{ required: true }]}>
            <Input.Password size="large" />
          </Form.Item>

          <div className="agree-block">
            <Checkbox
              className={classNames({
                error: isConfirmedError
              })}
              onChange={(e) => {
                setIsConfirmed(e.target.checked);
                setIsConfirmedError(false);
              }}
            >
              By checking this box, you certify that you are at least 18 years old and agree to our{' '}
              <Link to="https://www.eternalapplications.com/privacy" target="_blank">
                Terms and Privacy Policy
              </Link>
            </Checkbox>
          </div>

          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthComponent>
  );
}

export default SignUp;
