import { Avatar, Button, Col, Dropdown, Row } from 'antd';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { selectAuthUser } from 'store/authSlice';

import imageLogoTextFull from 'assets/logo-text-full.svg';
import noAvatarImage from 'assets/noAvatar.svg';

import { MenuOutlined } from '@ant-design/icons';

import './PublicHeader.css';

function PublicHeader() {
  const user = useSelector(selectAuthUser);
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Log-In',
      key: 'login',
      className: 'primary-button'
    },
    {
      label: 'Sign-Up',
      key: 'signup',
      className: 'sub-button'
    }
  ];

  return (
    <div className="public-header">
      <Row align="middle" wrap={false}>
        <Col flex="25%">
          <Row align="middle" wrap={false}>
            <Link to="https://www.eternalapplications.com" target="_blank" className="menu-text">
              <p>Home</p>
            </Link>
          </Row>
        </Col>

        <Col flex="50%">
          <Row align="middle" wrap={false} className="logo-container">
            <NavLink to="/">
              <img src={imageLogoTextFull} alt="" className="logo" />
            </NavLink>
          </Row>
        </Col>

        <Col flex="25%" sm={6} md={0}>
          <Dropdown
            className="right"
            menu={{
              items: menuItems.map(({ label, key }) => ({
                key,
                label: <NavLink to={`/${key}`}>{label}</NavLink>
              }))
            }}
            trigger={['click']}
          >
            <div className="user-button">
              <Avatar
                size={28}
                src={user?.avatarUrl ? user.avatarUrl : noAvatarImage}
                className="avatar"
                shape="circle"
              />
              <MenuOutlined />
            </div>
          </Dropdown>
        </Col>

        <Col flex="25%" xs={0} sm={0} md={6}>
          <div className="right">
            {menuItems.map(({ label, key, className }) => (
              <Button key={key} className={className} onClick={() => navigate(`/${key}`)}>
                {label}
              </Button>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PublicHeader;
