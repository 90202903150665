import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'api/Api';
import { Message } from 'api/MessagesApi';

export const adminAccessMessagesApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'adminAccessMessagesApi',
  tagTypes: ['AdminAccessMessages'],
  endpoints: (build) => ({
    getAllMessages: build.query<{ list: Message[]; total: number }, { id: string; limit?: number; offset?: number }>({
      query: ({ id, ...params }) => ({ url: `/admin-access/${id}/messages`, params }),
      transformResponse: (response: { data: [Message[], number] }) => ({
        list: response.data[0],
        total: response.data[1]
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.list.map(({ id }) => ({
                type: 'AdminAccessMessages' as const,
                id
              })),
              { type: 'AdminAccessMessages', id: 'LIST' }
            ]
          : [{ type: 'AdminAccessMessages', id: 'LIST' }]
    })
  })
});

export const { useGetAllMessagesQuery } = adminAccessMessagesApi;
