import { useCallback, useEffect, useRef, useState } from 'react';

export const useCountUp = () => {
  const [timerCountUp, setTimerCountUp] = useState(0);
  const timer = useRef({
    startedDate: 0,
    totalMilliseconds: 0,
    interval: {} as NodeJS.Timer
  });

  useEffect(() => {
    return () => clearInterval(timer.current.interval);
  }, []);

  const run = useCallback(() => {
    if (timer.current.startedDate) {
      return;
    }

    timer.current.startedDate = new Date().getTime();
    timer.current.interval = setInterval(() => {
      const time = new Date().getTime() - timer.current.startedDate + timer.current.totalMilliseconds;
      setTimerCountUp(Math.floor(time / 1000));
    }, 100);
  }, []);

  const startCountUp = useCallback(() => {
    setTimerCountUp(0);
    timer.current.totalMilliseconds = 0;
    run();
  }, []);

  const pauseCountUp = useCallback(() => {
    if (!timer.current.startedDate) {
      return;
    }

    clearInterval(timer.current.interval);
    timer.current.totalMilliseconds += new Date().getTime() - timer.current.startedDate;
    timer.current.startedDate = 0;
  }, []);

  const resumeCountUp = useCallback(() => {
    run();
  }, []);

  const resetCountUp = useCallback(() => {
    setTimerCountUp(0);
    timer.current.startedDate = 0;
    timer.current.totalMilliseconds = 0;
    clearInterval(timer.current.interval);
  }, []);

  return { timerCountUp, startCountUp, pauseCountUp, resumeCountUp, resetCountUp };
};
