import { FacebookFilled, InstagramOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Link, NavLink } from 'react-router-dom';

import imageLogoVerticalWhite from 'assets/logo-vertical-white.svg';

import { useEffect, useRef, useState } from 'react';
import './PublicFooter.css';

export const FACEBOOK_URL = 'https://www.facebook.com/EternalApplications/';
export const INSTAGRAM_URL = 'https://www.instagram.com/eternalapplicationsinc/';

function PublicFooter() {
  const modalRef = useRef(null);
  const [modalHeight, setModalHeight] = useState(0);

  useEffect(() => {
    if (modalRef.current) {
      //@ts-ignore
      setModalHeight(modalRef.current.clientHeight);
    }
  }, []);

  // TODO: move URLs to env variables
  return (
    <footer className="public-footer">
      <Row align="middle" wrap={false} className="public-footer-container">
        <Col flex="25%">
          <Row align="middle" wrap={false} className="logo-container">
            <NavLink to="/">
              <img src={imageLogoVerticalWhite} alt="" className="logo" />
            </NavLink>
          </Row>
        </Col>

        <Col flex="50%">
          <Row align="middle" wrap={false} className="menu-container">
            <Link to="https://www.eternalapplications.com/privacy" target="_blank" className="menu-text">
              <p>Privacy</p>
            </Link>
            <p className="separator" />
            <Link to="https://www.eternalapplications.com/terms-of-service" target="_blank" className="menu-text">
              <p>Terms</p>
            </Link>
          </Row>
        </Col>

        <Col flex="25%">
          <div className="right">
            <Link to={INSTAGRAM_URL}>
              <InstagramOutlined size={32} color="#fff" />
            </Link>

            <Link to={FACEBOOK_URL}>
              <FacebookFilled size={32} color="#fff" />
            </Link>
          </div>
        </Col>
      </Row>
    </footer>
  );
}

export default PublicFooter;
