import { Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SysAdminPayment, useGetAllPaymentsSysAdminQuery } from 'api/SysAdminApi';
import { User } from 'api/UsersApi';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getFullNameOrEmail } from 'utils/utils';
import './SysAdminPaymentListComponent.css';

type AllProps = {
  userId?: string;
  affiliateUserId?: string;
  fromDate?: string | null;
  toDate?: string | null;
};

const PAGE_SIZE = 10;

function SysAdminPaymentListComponent({ userId, affiliateUserId, fromDate, toDate }: AllProps) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: sysAdminPaymentListData, isFetching } = useGetAllPaymentsSysAdminQuery({
    limit: PAGE_SIZE,
    offset: (currentPage - 1) * PAGE_SIZE,
    ...(affiliateUserId ? { affiliateUserId } : null),
    ...(userId ? { userId } : null),
    ...(fromDate ? { fromDate } : null),
    ...(toDate ? { toDate } : null)
  });

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const columns: ColumnsType<SysAdminPayment> = [
    {
      title: 'User Name',
      key: 'name',
      dataIndex: 'user',
      ellipsis: true,
      render: (user: User) => <Link to={`/sysadmin/users/${user.id}`}>{getFullNameOrEmail(user)}</Link>
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'user',
      ellipsis: true,
      render: (user: User) => user.email
    },
    {
      title: 'Plan',
      key: 'planName',
      dataIndex: 'planName',
      render: (planName) => planName
    },
    // {
    //   title: 'Coupon',
    //   key: 'couponName',
    //   dataIndex: 'couponName',
    //   render: (couponName) => couponName
    // },
    // {
    //   title: 'Credits',
    //   key: 'creditAmount',
    //   dataIndex: 'creditAmount',
    //   render: (creditAmount) => creditAmount
    // },
    {
      title: 'Amount Total',
      key: 'stripeAmountTotal',
      dataIndex: 'stripeAmountTotal',
      render: (stripeAmountTotal) => `$${stripeAmountTotal / 100}`
    },
    {
      title: 'Payment Intent',
      key: 'paymentIntentId',
      dataIndex: 'stripePaymentIntentId',
      render: (paymentIntentId) => <Typography.Text>{paymentIntentId}</Typography.Text>
    },
    {
      title: 'Affiliate',
      key: 'affiliateUser',
      dataIndex: 'affiliateUser',
      render: (affiliateUser: User) => <Typography.Text>{affiliateUser?.email}</Typography.Text>
    },
    {
      title: 'Created',
      key: 'created',
      dataIndex: 'stripeCreated',
      render: (stripeCreated) => dayjs(stripeCreated).format('YYYY-MM-DD hh:mm')
    }
  ];

  return (
    <>
      <div className="sys-admin-user-list-component">
        <h3>Payments ({sysAdminPaymentListData?.total || 0})</h3>

        <Table
          columns={columns}
          dataSource={sysAdminPaymentListData?.list || []}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            current: currentPage,
            total: sysAdminPaymentListData?.total || 0
          }}
          onChange={onTableChanged}
          rowKey="id"
          bordered
          loading={isFetching}
        />
      </div>
    </>
  );
}

export default SysAdminPaymentListComponent;
