import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logout, setAccessToken } from 'store/authSlice';
import { RootState } from 'store/store';

const mutex = new Mutex();
const baseUrl = process.env.REACT_APP_API_URL;

const isUnauthorizedError = (serverError): boolean => {
  return serverError?.data === 'Unauthorized' && serverError?.originalStatus === 401;
};

const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.accessToken;
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  }
});

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (isUnauthorizedError(result.error)) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // try to get a new access token
        const refreshResult = await baseQuery(
          {
            url: '/auth/refresh-token',
            method: 'POST'
          },
          api,
          extraOptions
        );
        const response: any = refreshResult.data;
        const accessToken: string = response?.data?.accessToken;
        if (accessToken) {
          api.dispatch(setAccessToken(accessToken));
        } else {
          api.dispatch(logout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
