import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Flex, Form, Input, Radio, Row, Select } from 'antd';
import { useCreateContactMutation, useGetAllContactsQuery } from 'api/ContactsApi';
import { Message } from 'api/MessagesApi';
import { CONTACT_RELATIONS_MAP } from 'components/Contact/ContactListComponent/ContactListComponent';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logging, validateForm } from 'utils/utils';
import './StepRecipientComponent.css';

export type StepRecipientData = {
  contactIds: string[];
};

type AllProps = {
  message: Message | null;
  onNext: (data: StepRecipientData, file?: File | null, afterAction?: 'next' | 'none' | 'exit') => void;
  isLoading?: boolean;
};

function StepRecipientComponent({ message, onNext, isLoading }: AllProps) {
  const [selectedRecipientIds, setSelectedRecipientIds] = useState<string[]>([]);
  const [isAddNewContact, setIsAddNewContact] = useState(false);
  const { data: contactsData, isLoading: isLoadingContacts } = useGetAllContactsQuery({});
  const [createContact] = useCreateContactMutation();
  const navigate = useNavigate();

  const [formContact] = Form.useForm();

  useEffect(() => {
    setIsAddNewContact(!selectedRecipientIds.length);
  }, [selectedRecipientIds]);

  useEffect(() => {
    if (message) {
      setSelectedRecipientIds((message?.contacts || []).map((contact) => contact.id));
    }
  }, [message]);

  useEffect(() => {
    if (contactsData?.total === 0) {
      setIsAddNewContact(true);
    }
  }, [contactsData]);

  const handleSelectRecipients = (recipientIds: string[]) => {
    setSelectedRecipientIds((prev) => [...prev, recipientIds[0]]);
    setIsAddNewContact(false);
  };

  const handleRemoveContact = (recipientId: string) => {
    setSelectedRecipientIds((prev) => prev.filter((id) => id !== recipientId));
  };

  const handleCreateContact = async () => {
    if (isAddNewContact) {
      try {
        await formContact.validateFields();
        const fields = formContact.getFieldsValue();

        const contact = await createContact({
          name: fields.contactName,
          email: fields.contactEmail,
          phone: fields.contactPhone,
          relationship: fields.contactRelationship
        }).unwrap();

        setSelectedRecipientIds((prev) => [...prev, contact.id]);
        setIsAddNewContact(false);

        formContact.setFieldsValue({
          contactName: '',
          contactEmail: '',
          contactPhone: '',
          contactRelationship: ''
        });
      } catch (e) {
        logging(e);
        validateForm(formContact, e, 'contact');
        return false;
      }
    }

    return true;
  };

  const onSubmit = async (isExit?: boolean) => {
    try {
      const contact = await handleCreateContact();
      if (!contact) {
        return;
      }

      const data: StepRecipientData = {
        contactIds: selectedRecipientIds
      };
      onNext(data, undefined, isExit ? 'exit' : 'next');
    } catch (e) {
      logging(e);
    }
  };

  return (
    <div className="step-recipient-component create-message-component-full-height">
      <SpinComponent isLoading={isLoadingContacts}>
        <div className="contact-block">
          {isAddNewContact ? (
            <>
              <div className="create-message-component-title">Who will be receiving your message?</div>
              <div className="create-message-component-subtitle">
                Select a recipient from contact list or add recipient contact information.
              </div>

              <Row>
                <Col xs={24} lg={14}>
                  <div className="contact-add">
                    <p>Create new contact.</p>
                    <Form layout="vertical" autoComplete="off" requiredMark="optional" form={formContact}>
                      <Form.Item name="contactName" label="Full Name" rules={[{ required: true }]}>
                        <Input placeholder="Recipient Full Name" />
                      </Form.Item>
                      <Form.Item
                        name="contactEmail"
                        label="Email Address"
                        rules={[{ required: true }, { type: 'email' }]}
                      >
                        <Input placeholder="Recipient Email" />
                      </Form.Item>
                      <Form.Item name="contactPhone" label="Phone Number">
                        <Input placeholder="Recipient Phone" />
                      </Form.Item>
                      <p>Select the option that best applies.</p>
                      <Form.Item
                        name="contactRelationship"
                        messageVariables={{ label: 'Relationship' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <Row>
                            {Object.entries(CONTACT_RELATIONS_MAP).map(([value, label]) => (
                              <Col key={value} span={12}>
                                <Radio value={value}>{label}</Radio>
                              </Col>
                            ))}
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                      <div className="switch-contact-select text-right">
                        <Button type="primary" onClick={handleCreateContact}>
                          ADD RECIPIENT
                        </Button>
                      </div>
                    </Form>

                    <Divider>OR</Divider>
                    <p>Select from existing contact.</p>
                    <Form.Item>
                      <Select
                        className="delivery-select-contact"
                        showSearch
                        mode="multiple"
                        allowClear
                        placeholder="Select Contact"
                        optionFilterProp="children"
                        optionLabelProp="label"
                        loading={isLoadingContacts}
                        onChange={handleSelectRecipients}
                        filterOption={(inputValue, option) =>
                          ((option?.label as string) ?? '').toLowerCase().includes(inputValue.toLowerCase())
                        }
                      >
                        {contactsData?.list.map((contact) => (
                          <Select.Option
                            className="delivery-select-contact-item"
                            key={contact.id}
                            value={contact.id}
                            label={`${contact.name} (${contact.email})`}
                          >
                            <span className="contact-name">{contact.name}</span>
                            <span className="contact-relationship">
                              {contact.relationship && <>({contact.relationship})</>}
                            </span>
                            <span className="contact-email">{contact.email}</span>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className="create-message-component-subtitle text-left">Current Recipients</div>
              <div>
                <strong>Recipients:</strong>
              </div>
              {selectedRecipientIds.map((id) => {
                const contact = (contactsData?.list || []).find((contact) => contact.id === id);
                return contact ? (
                  <Flex gap={20} className="contact" key={contact.id}>
                    {contact.name} ({contact.email}){' '}
                    <DeleteOutlined className="icon" onClick={() => handleRemoveContact(contact.id)} />
                  </Flex>
                ) : null;
              })}

              <div className="add-another-contact">
                <div className="create-message-component-title">
                  Would you like to add another recipient to this message?
                </div>

                <Flex gap={40} justify="center">
                  <div
                    className="big-button box-shadow-animation"
                    onClick={() => {
                      setIsAddNewContact(true);
                    }}
                  >
                    Yes
                  </div>
                  <div className="big-button box-shadow-animation" onClick={() => onSubmit()}>
                    No
                  </div>
                </Flex>
              </div>
            </>
          )}
        </div>
      </SpinComponent>

      <Row className="create-message-component-footer">
        {isAddNewContact ? (
          <>
            <Col span={12}>
              <Button onClick={() => navigate('/')} type="link">
                <ArrowLeftOutlined /> Back to Home
              </Button>
            </Col>
            <Col span={12} className="text-right">
              {!!selectedRecipientIds.length && <Button onClick={() => setIsAddNewContact(false)}>Close</Button>}
            </Col>
          </>
        ) : (
          <>
            <Col span={12}>
              <Button onClick={() => navigate('/')} type="link">
                <ArrowLeftOutlined /> Back to Home
              </Button>
            </Col>
            <Col span={12} className="text-right">
              <Flex gap={20} justify="flex-end">
                <Button onClick={() => onSubmit(true)} disabled={!selectedRecipientIds.length}>
                  Save & Exit
                </Button>
                <Button
                  onClick={() => onSubmit()}
                  type="primary"
                  disabled={!selectedRecipientIds.length}
                  loading={isLoading}
                >
                  Next
                </Button>
              </Flex>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default StepRecipientComponent;
