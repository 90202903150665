import { Alert, Button, Form, Input } from 'antd';
import { useResetPasswordMutation } from 'api/AuthApi';
import AuthComponent from 'components/AuthComponent/AuthComponent';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { logging, validateForm } from 'utils/utils';
import './ResetPassword.css';

function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();

  const [resetPassword, { isError, isLoading }] = useResetPasswordMutation();

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      await resetPassword({
        email: fields.email,
        password: fields.password,
        code: fields.code
      }).unwrap();

      form.setFieldValue('email', '');
      form.setFieldValue('password', '');
      form.setFieldValue('code', '');

      navigate(`/login?email=${encodeURIComponent(fields.email)}&reset=true`);
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  return (
    <AuthComponent
      title="Reset Password"
      subtitle={
        <>
          Already have an account? <Link to="/login">Login Here</Link>
        </>
      }
    >
      <div className="confirm-email">
        <Form
          name="basic"
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{
            email: searchParams.get('email'),
            code: searchParams.get('code')
          }}
          autoComplete="off"
          requiredMark="optional"
          form={form}
        >
          {searchParams.get('email') && (
            <Alert
              className="message"
              message={
                <>
                  <p>
                    Reset password code was sent to <strong>{searchParams.get('email')}</strong>.
                  </p>
                  <p>
                    Please be sure to check your spam or junk folder if you don't receive a reset password email in your
                    inbox within a few minutes.
                  </p>
                </>
              }
              type="success"
              showIcon
            />
          )}

          {isError && <Alert className="message" message="Cannot reset password" type="error" showIcon />}

          <Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
            <Input size="large" placeholder="email@domain.com" />
          </Form.Item>

          <Form.Item label="Code" name="code" rules={[{ required: true }]}>
            <Input size="large" />
          </Form.Item>

          <Form.Item label="Password" name="password" rules={[{ required: true }]}>
            <Input.Password size="large" />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Update Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthComponent>
  );
}

export default ResetPassword;
