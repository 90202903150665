import { Flex } from 'antd';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <>
      <div className="footer">
        <Flex vertical={true} className="text-center" gap={14}>
          <Flex className="footer-links" justify="center" gap={20}>
            <Link to={'https://www.eternalapplications.com/privacy'} target="_blank">
              Privacy
            </Link>
            |
            <Link to={'https://www.eternalapplications.com/privacy'} target="_blank">
              Terms
            </Link>
            | <Link to={'/faq'}>FAQ</Link>|<Link to={'/contact-us'}>Contact</Link>
          </Flex>

          <div className="copyright">&copy; Eternal Applications, Inc. All rights reserved.</div>
        </Flex>
      </div>
    </>
  );
}

export default Footer;
