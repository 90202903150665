import { AudioOutlined, FormOutlined, LeftCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Alert, Flex, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useGetOneAdminAccessQuery } from 'api/AdminAccessApi';
import { useGetAllMessagesQuery } from 'api/AdminAccessMessagesApi';
import { Contact } from 'api/ContactsApi';
import { Message, MessageTypeEnum } from 'api/MessagesApi';
import { ResponsiveTable } from 'components/UiKit/ResponsiveTable/ResponsiveTable';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { displayDeliveryDate, getFullNameWithEmail } from 'utils/utils';
import './BeneficiaryComponent.css';

const PAGE_SIZE = 10;

function BeneficiaryComponent() {
  const { adminAccessId } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const { data: adminAccess } = useGetOneAdminAccessQuery({ id: adminAccessId! }, { skip: !adminAccessId });
  const {
    data: messagesData,
    isFetching,
    isError,
    error
  } = useGetAllMessagesQuery(
    {
      id: adminAccessId!,
      limit: PAGE_SIZE,
      offset: (currentPage - 1) * PAGE_SIZE
    },
    { skip: !adminAccessId }
  );

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const renderTypeIcon = (type: MessageTypeEnum) => {
    if (type === MessageTypeEnum.Video) {
      return <VideoCameraOutlined />;
    } else if (type === MessageTypeEnum.Audio) {
      return <AudioOutlined />;
    } else {
      return <FormOutlined />;
    }
  };

  const renderContacts = (contacts: Contact[]) => {
    return (
      contacts &&
      contacts.map((contact) => (
        <div key={contact.id}>
          <Typography.Text>
            {contact.name} ({contact.email})
          </Typography.Text>
        </div>
      ))
    );
  };

  const renderActions = (message: Message) => {
    return (
      <Space size="middle">
        {/*{message.status !== MessageStatusEnum.Delivered && (*/}
        {/*  <a onClick={() => editMessage(message.id)}>*/}
        {/*    <Tooltip title="Edit Message">*/}
        {/*      <EditOutlined />*/}
        {/*    </Tooltip>*/}
        {/*  </a>*/}
        {/*)}*/}
        {/*<a onClick={() => deleteMessage(message.id)}>*/}
        {/*  <Tooltip title="Remove Message">*/}
        {/*    <DeleteOutlined />*/}
        {/*  </Tooltip>*/}
        {/*</a>*/}
        {/*{message.status === MessageStatusEnum.Draft && <a onClick={() => handlePublishMessage(message.id)}>Complete</a>}*/}
      </Space>
    );
  };

  const mobileColumns: ColumnsType<Message> = [
    {
      render: (message: Message) => (
        <Space size={4} direction="vertical" className="responsive-table-card">
          <Flex justify="space-between">
            <Flex align="center" gap="small">
              {renderTypeIcon(message.type)}
              <span className="responsive-table-title">
                <Typography.Text>{message.name}</Typography.Text>
              </span>
            </Flex>
          </Flex>
          <div>
            <strong>Send To:</strong> {renderContacts(message.contacts)}
          </div>
          <Flex justify="space-between">
            <div>
              <strong>Send On:</strong> {displayDeliveryDate(message)}
            </div>
            <div>{renderActions(message)}</div>
          </Flex>
        </Space>
      )
    }
  ];

  const columns: ColumnsType<Message> = [
    {
      title: 'Message Name',
      key: 'name',
      render: (_, message) => (
        <div className="message-title">
          <Typography.Text>{message.name}</Typography.Text>
        </div>
      )
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (type) => renderTypeIcon(type)
    },
    {
      title: 'Recipient(s)',
      key: 'recipient',
      dataIndex: 'contacts',
      render: (contacts: Contact[]) => renderContacts(contacts)
    },
    {
      title: 'Delivery Date',
      key: 'delivery',
      render: (_, message) => displayDeliveryDate(message)
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, message) => renderActions(message)
    }
  ];

  return (
    <>
      <div className="beneficiary-component">
        <h3>
          <Space size="middle" align="center">
            <Link to="/beneficiaries">
              <LeftCircleOutlined />
            </Link>
            <div>
              {getFullNameWithEmail(adminAccess?.user)}'s Messages ({messagesData?.total || 0}){' '}
            </div>
          </Space>
        </h3>

        {isError && <Alert className="message" message={(error as any)?.data.message} type="error" showIcon />}

        <ResponsiveTable
          mobileUntilBreakpoint="lg"
          mobileColumns={mobileColumns}
          columns={columns}
          dataSource={messagesData?.list || []}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            current: currentPage,
            total: messagesData?.total || 0
          }}
          onChange={onTableChanged}
          rowKey="id"
          bordered
          loading={isFetching}
        />
      </div>
    </>
  );
}

export default BeneficiaryComponent;
