import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { useContactUsMutation } from 'api/CommonApi';
import imageContactUs from 'assets/contact-us.svg';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'store/authSlice';
import { logging, validateForm } from 'utils/utils';
import './ContactUsComponent.css';

function ContactUsComponent() {
  const user = useSelector(selectAuthUser);
  const [isSuccess, setIsSuccess] = useState(false);
  const [form] = Form.useForm();

  const [contactUs, { isLoading }] = useContactUsMutation();

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
  }, [user]);

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      await contactUs({
        message: fields.message
      }).unwrap();

      form.setFieldValue('message', '');
      setIsSuccess(true);
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  return (
    <div className="contact-us-component">
      <h3>We'd love to hear from you!</h3>

      <Row gutter={[40, 10]} align="middle">
        <Col md={14}>
          {isSuccess && (
            <p>
              <Alert message="Message was sent to our team." type="success" showIcon />
            </p>
          )}
          <Form
            name="basic"
            layout="vertical"
            onFinish={onSubmit}
            autoComplete="off"
            requiredMark="optional"
            form={form}
          >
            <Row gutter={[20, 10]}>
              <Col xs={24}>
                <Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
                  <Input size="large" disabled={true} />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Message" name="message" rules={[{ required: true }]}>
                  <Input.TextArea rows={4} placeholder="Type your message here!" maxLength={5000} showCount={true} />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Submit Message
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={10} className="image">
          <img src={imageContactUs} alt="Contact Us" />
        </Col>
      </Row>
    </div>
  );
}

export default ContactUsComponent;
