import BeneficiaryListComponent from 'components/Admin/BeneficiaryListComponent/BeneficiaryListComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import './Beneficiaries.css';

function Beneficiaries() {
  return (
    <>
      <div className="beneficiaries layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <>
            <BeneficiaryListComponent isAcceptedAdminStatus={false} title="Pending Acceptance" />
            <BeneficiaryListComponent isAcceptedAdminStatus title="Beneficiaries" />
          </>
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default Beneficiaries;
