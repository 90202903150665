import { Button, Modal } from 'antd';
import { useChangeStatusUserMutation, UserStatusEnum } from 'api/UsersApi';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'store/authSlice';
import { closeModal, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './WarningDeceasedStatusModal.css';

export const WARNING_DECEASED_STATUS_MODAL_NAME = 'WARNING_DECEASED_STATUS_MODAL_NAME';

function WarningDeceasedStatusModal() {
  const dispatch = useAppDispatch();
  const user = useSelector(selectAuthUser);

  const [changeStatusUser, { isLoading }] = useChangeStatusUserMutation();

  const handleIgnore = async () => {
    dispatch(closeModal());
  };

  const handleAliveAction = async () => {
    if (!user) {
      return;
    }

    try {
      await changeStatusUser({ userId: user.id, status: UserStatusEnum.ACTIVE }).unwrap();

      dispatch(closeModal());
    } catch (e: any) {
      console.log('move to active user error', e);
    }
  };

  return (
    <Modal open={true} footer={null} onCancel={handleIgnore}>
      <>
        <p>We've noticed that your account is currently marked as "Deceased".</p>
        <p>
          It's essential to ensure the accuracy of our records. If you believe this is a mistake or need assistance,
          please reach out to our data integrity team.
        </p>

        <p>Please review and confirm the accuracy of this status.</p>

        <div className="status-action-buttons">
          <Button type="primary" loading={isLoading} onClick={handleAliveAction}>
            This is a Mistake, I am Alive
          </Button>
        </div>
      </>
    </Modal>
  );
}

function WarningDeceasedStatusModalWrapper() {
  const modalName = useSelector(selectModalName);

  return modalName === WARNING_DECEASED_STATUS_MODAL_NAME ? <WarningDeceasedStatusModal /> : null;
}

export default WarningDeceasedStatusModalWrapper;
