import { Modal } from 'antd';
import { useGetOneMessageQuery, useRemoveMessageMutation } from 'api/MessagesApi';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './RemoveMessageModalComponent.css';

export const DELETE_MESSAGE_MODAL_NAME = 'DELETE_MESSAGE_MODAL_NAME';

type AllProps = {
  messageId: string;
};

function RemoveMessageModalComponent({ messageId }: AllProps) {
  const dispatch = useAppDispatch();
  const [removeMessage] = useRemoveMessageMutation();

  const { data: message, isLoading } = useGetOneMessageQuery({ id: messageId });

  const handleRemove = async () => {
    await removeMessage({ id: messageId }).unwrap();
    handleCloseModal();
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title="Remove Message"
      onOk={handleRemove}
      onCancel={handleCloseModal}
      okText="Remove"
      className="size-sm"
    >
      <SpinComponent isLoading={isLoading}>
        <div className="remove-message-modal-component">
          Are you sure you want to permanently delete <strong>{message?.name}</strong>?
        </div>
      </SpinComponent>
    </Modal>
  );
}

function RemoveMessageModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const messageId = modalData?.id || null;

  return modalName === DELETE_MESSAGE_MODAL_NAME ? <RemoveMessageModalComponent messageId={messageId} /> : null;
}

export default RemoveMessageModalComponentWrapper;
