import { Alert } from 'antd';
import { AdminAccessListEnum, useGetAllAdminAccessQuery } from 'api/AdminAccessApi';
import { MessageStatusEnum, useGetAllMessagesQuery } from 'api/MessagesApi';
import MessageListComponent from 'components/Message/MessageListComponent/MessageListComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import { Link } from 'react-router-dom';
import './Dashboard.css';

function Dashboard() {
  const { data: adminAccessData, isSuccess: isSuccessAdminAccess } = useGetAllAdminAccessQuery({
    list: AdminAccessListEnum.Admins,
    limit: 1
  });

  const { data: messagesData, isSuccess: isSuccessMessages } = useGetAllMessagesQuery({
    status: MessageStatusEnum.Published,
    limit: 1
  });

  return (
    <>
      <div className="dashboard layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <>
            {isSuccessAdminAccess &&
              isSuccessMessages &&
              !(adminAccessData?.list || []).length &&
              !!(messagesData?.list || []).length && (
                <Alert
                  message="Missing"
                  description={
                    <>
                      <div>
                        Make sure to assign an Administrator to your account by visiting the{' '}
                        <Link to={'/account'}>Account page</Link>.
                      </div>
                    </>
                  }
                  type="warning"
                  showIcon
                  className="warning-administrator"
                />
              )}

            <MessageListComponent />
          </>
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
