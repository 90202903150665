import imageRecording from 'assets/recording.svg';
import { StatusMessages } from 'components/Tool/ReactMediaRecorder/ReactMediaRecorder';
import { useEffect } from 'react';
import { useCountUp } from 'utils/useCountUp';
import { formatTime } from 'utils/utils';
import './RecordingRecorderComponent.css';

function RecordingRecorderComponent({
  status,
  maxTime,
  onTimeEnds
}: {
  status: StatusMessages;
  maxTime: number;
  onTimeEnds: () => void;
}) {
  const { timerCountUp, pauseCountUp, resumeCountUp } = useCountUp();

  useEffect(() => {
    if (status === 'recording') {
      resumeCountUp();
    } else if (status === 'paused') {
      pauseCountUp();
    }
  }, [status]);

  useEffect(() => {
    if (timerCountUp >= maxTime) {
      onTimeEnds();
    }
  }, [timerCountUp, maxTime]);

  const label = status === 'recording' ? 'Recording' : status === 'paused' ? 'Paused' : 'Ready';

  return (
    <div className="recording-recorder-component">
      <div>
        <img src={imageRecording} alt="" className="icon" /> {label}
      </div>
      <div className="recording-time">Time: {formatTime(timerCountUp)}</div>
      <div className="recording-time">Remaining: {formatTime(maxTime - timerCountUp)}</div>
    </div>
  );
}

export default RecordingRecorderComponent;
