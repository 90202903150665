import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'api/Api';
import { User } from 'api/UsersApi';

export interface AuthUser extends User {
  avatarUrl: string;
  isAdmin: boolean;
  isSysAdmin: boolean;
}

export enum UserGenderEnum {
  Male = 'male',
  Female = 'female',
  NotShared = 'not-shared'
}

export const invalidateAuthTags = () => authApi.util.invalidateTags(['Auth']);

export const authApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'authApi',
  tagTypes: ['Auth'],
  endpoints: (build) => ({
    login: build.mutation<string, { email: string; password: string }>({
      query: (body) => ({
        url: '/auth/login',
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: { accessToken: string } }) => response.data.accessToken,
      invalidatesTags: ['Auth']
    }),
    loginGoogle: build.mutation<string, { accessToken: string; partnerCode?: string }>({
      query: (body) => ({
        url: '/auth/login/google',
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: { accessToken: string } }) => response.data.accessToken,
      invalidatesTags: ['Auth']
    }),
    loginFacebook: build.mutation<string, { accessToken: string; partnerCode?: string }>({
      query: (body) => ({
        url: '/auth/login/facebook',
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: { accessToken: string } }) => response.data.accessToken,
      invalidatesTags: ['Auth']
    }),
    signup: build.mutation<
      boolean,
      {
        email: string;
        password: string;
        partnerCode?: string;
      }
    >({
      query: (body) => ({
        url: '/auth/signup',
        method: 'POST',
        body
      }),
      transformResponse: (response: { success: boolean }) => response.success
    }),
    confirmEmail: build.mutation<boolean, { email: string; code: string }>({
      query: (body) => ({
        url: '/auth/confirm-email',
        method: 'POST',
        body
      }),
      transformResponse: (response: { success: boolean }) => response.success
    }),
    forgotPassword: build.mutation<boolean, { email: string }>({
      query: (body) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body
      }),
      transformResponse: (response: { success: boolean }) => response.success
    }),
    resetPassword: build.mutation<boolean, { email: string; password: string; code: string }>({
      query: (body) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body
      }),
      transformResponse: (response: { success: boolean }) => response.success
    }),
    me: build.query<AuthUser, void>({
      query: () => ({ url: '/auth/me' }),
      transformResponse: (response: { data: AuthUser }) => response.data,
      providesTags: () => ['Auth']
    }),
    logout: build.mutation<boolean, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST'
      }),
      transformResponse: (response: { success: boolean }) => response.success
    })
  })
});

export const {
  useLoginMutation,
  useLoginGoogleMutation,
  useLoginFacebookMutation,
  useSignupMutation,
  useConfirmEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useMeQuery,
  useLogoutMutation
} = authApi;
