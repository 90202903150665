import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthUser } from 'api/AuthApi';
import { PaymentPlan } from 'api/PaymentApi';
import dayjs from 'dayjs';

declare global {
  interface Window {
    dataLayer: any[];

    adbeaconShopifyPixelData?: { init: { context: { document: { referrer: string } } } };
    adBeaconCheckoutData?: {
      order_id: string;
      order_date_time: string;
      payment_currency: string;

      sub_total_price_in_payment_currency: number;
      total_price_in_payment_currency: number;
      discount_code: string;

      sub_total_price_in_store_currency: number;
      total_price_in_store_currency: number;

      customer_id: string;
      email: string;

      line_items: {
        product_id: string;
        product_name: string;
        price_in_payment_currency: number;
        price_in_store_currency: number;
        quantity: number;
      }[];
    };
  }
  var abPing: () => void | undefined;
}

interface EcommercePayload {
  id: string;
  created: number;
  currency: string;
  total: number;
  subtotal: number;
  plan: PaymentPlan;
  couponName: string | null;
  user: AuthUser | null;
}

const slice = createSlice({
  name: 'ecommerce',
  initialState: {},
  reducers: {
    setAdBeaconReferrer(state, action: PayloadAction<string>) {
      const referrer = action.payload;
      if (typeof abPing !== 'undefined') {
        if (referrer) {
          window.adbeaconShopifyPixelData = {
            init: { context: { document: { referrer } } }
          };
        }
      }
    },
    purchaseEvent(state, action: PayloadAction<EcommercePayload>) {
      const order = action.payload;

      if (typeof abPing !== 'undefined') {
        if (order && order.user) {
          window.adBeaconCheckoutData = {
            order_id: order.id,
            order_date_time: dayjs(order.created * 1000).format('YYYY-MM-DD hh:mm:ss'),
            payment_currency: order.currency,

            // Payment currency is the currency in which the customer made the payment
            sub_total_price_in_payment_currency: order.subtotal / 100,
            total_price_in_payment_currency: order.total / 100,
            discount_code: order.couponName || '',

            // Store currency is the base currency of your store.
            sub_total_price_in_store_currency: order.subtotal / 100,
            total_price_in_store_currency: order.total / 100,

            customer_id: order.user.id,
            email: order.user.email,

            line_items: [
              {
                product_id: order.plan.name,
                product_name: order.plan.name,
                price_in_payment_currency: order.plan.prices[0].amount / 100,
                price_in_store_currency: order.plan.prices[0].amount / 100,
                quantity: 1
              }
            ]
          };
        } else {
          delete window.adBeaconCheckoutData;
        }

        abPing();
      }

      window.dataLayer.push({ ecommerce: null });
      if (order) {
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: order.id,
            // Sum of (price * quantity) for all items.
            value: order.total / 100,
            currency: 'USD',
            coupon: order.couponName || '',
            items: [
              {
                item_id: order.plan.name,
                item_name: order.plan.name,
                coupon: order.couponName || '',
                discount: (order.subtotal - order.total) / 100,
                index: 0,
                price: order.plan.prices[0].amount / 100,
                quantity: 1
              }
            ]
          }
        });
      }
    }
  }
});

export const { setAdBeaconReferrer, purchaseEvent } = slice.actions;
export default slice.reducer;
