import ContactUsComponent from 'components/Common/ContactUsComponent/ContactUsComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import './ContactUs.css';

function ContactUs() {
  return (
    <div className="contact-us-page layout-full-height">
      <Header />

      <ContainerComponent className="layout-main-content">
        <>
          <ContactUsComponent />
        </>
      </ContainerComponent>

      <Footer />
    </div>
  );
}

export default ContactUs;
