import { Alert, Modal } from 'antd';
import { useResumeSubscriptionMutation } from 'api/PaymentApi';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { getResponseErrors } from 'utils/utils';
import './ResumeSubscriptionModalComponent.css';

export const RESUME_SUBSCRIPTION_MODAL_NAME = 'RESUME_SUBSCRIPTION_MODAL_NAME';

type AllProps = {
  onOk?: () => void;
};

function ResumeSubscriptionModalComponent({ onOk }: AllProps) {
  const dispatch = useAppDispatch();
  const [resumeSubscription, { isLoading, error }] = useResumeSubscriptionMutation();

  const handleCancel = async () => {
    try {
      const result = await resumeSubscription().unwrap();
      if (result) {
        onOk && onOk();
      }
      handleCloseModal();
    } catch (e) {}
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title="Resume Subscription"
      onOk={handleCancel}
      onCancel={handleCloseModal}
      okText="Resume Subscription"
      confirmLoading={isLoading}
      className="size-sm"
    >
      <SpinComponent isLoading={isLoading}>
        <div className="resume-subscription-modal-component">
          {error && <Alert className="message" message={getResponseErrors(error).join(', ')} type="error" showIcon />}
          <p>Welcome back! We're excited to have you with us again. Click below to resume your subscription.</p>
        </div>
      </SpinComponent>
    </Modal>
  );
}

function ResumeSubscriptionModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const onOk = modalData?.onOk || null;

  return modalName === RESUME_SUBSCRIPTION_MODAL_NAME ? <ResumeSubscriptionModalComponent onOk={onOk} /> : null;
}

export default ResumeSubscriptionModalComponentWrapper;
