import { Divider } from 'antd';
import AccountSettingsComponent from 'components/Account/AccountSettingsComponent/AccountSettingsComponent';
import AdministratorListComponent from 'components/Account/AdministratorListComponent/AdministratorListComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import './Account.css';

function Account() {
  return (
    <>
      <div className="account-page layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <>
            <AccountSettingsComponent />
            <Divider />
            <AdministratorListComponent />
          </>
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default Account;
