import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row, Space, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Contact, ContactRelationEnum, useGetAllContactsQuery } from 'api/ContactsApi';
import RemoveContactModalComponent, {
  DELETE_CONTACT_MODAL_NAME
} from 'components/Contact/RemoveContactModalComponent/RemoveContactModalComponent';
import EditContactModalComponent, {
  UPSERT_CONTACT_MODAL_NAME
} from 'components/Contact/UpsertContactModalComponent/UpsertContactModalComponent';
import { ResponsiveTable } from 'components/UiKit/ResponsiveTable/ResponsiveTable';
import { useState } from 'react';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './ContactListComponent.css';

const PAGE_SIZE = 10;

export const CONTACT_RELATIONS_MAP: { [key: string]: string } = {
  [ContactRelationEnum.Spouse]: 'Spouse',
  [ContactRelationEnum.Child]: 'Child',
  [ContactRelationEnum.Sibling]: 'Sibling',
  [ContactRelationEnum.Grandchild]: 'Grandchild',
  [ContactRelationEnum.Family]: 'Family',
  [ContactRelationEnum.SignificantOther]: 'Significant Other',
  [ContactRelationEnum.Friend]: 'Friend',
  [ContactRelationEnum.Parent]: 'Parent',
  [ContactRelationEnum.Grandparent]: 'Grandparent',
  [ContactRelationEnum.AuntUncle]: 'Aunt/Uncle',
  [ContactRelationEnum.Cousin]: 'Cousin',
  [ContactRelationEnum.Other]: 'Other'
};

function ContactListComponent() {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: contactsData,
    isLoading,
    isFetching
  } = useGetAllContactsQuery({
    limit: PAGE_SIZE,
    offset: (currentPage - 1) * PAGE_SIZE
  });

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const handleAdd = () => {
    dispatch(
      openModal({
        name: UPSERT_CONTACT_MODAL_NAME,
        onClose: () => setCurrentPage(1)
      })
    );
  };

  const handleEdit = (id: string) => {
    dispatch(openModal({ name: UPSERT_CONTACT_MODAL_NAME, data: { id } }));
  };

  const handleRemove = (id: string) => {
    dispatch(openModal({ name: DELETE_CONTACT_MODAL_NAME, data: { id } }));
  };

  const renderRelationship = (relation: ContactRelationEnum) => {
    return CONTACT_RELATIONS_MAP[relation] || relation;
  };

  const renderActions = (contact: Contact) => {
    return (
      <Space size="middle">
        <a onClick={() => handleEdit(contact.id)}>
          <Tooltip title="Edit Contact">
            <EditOutlined />
          </Tooltip>
        </a>
        <a onClick={() => handleRemove(contact.id)}>
          <Tooltip title="Remove Contact">
            <DeleteOutlined />
          </Tooltip>
        </a>
      </Space>
    );
  };

  const mobileColumns: ColumnsType<Contact> = [
    {
      render: (contact: Contact) => (
        <Space size={4} direction="vertical" className="responsive-table-card">
          <Flex justify="space-between">
            <div className="responsive-table-title">
              <Typography.Text>{contact.name}</Typography.Text>
            </div>
            <div>{renderRelationship(contact.relationship)}</div>
          </Flex>
          <div>{contact.phone}</div>
          <Flex justify="space-between" gap={20}>
            <div>
              <Typography.Text>{contact.email}</Typography.Text>
            </div>
            <div>{renderActions(contact)}</div>
          </Flex>
        </Space>
      )
    }
  ];

  const columns: ColumnsType<Contact> = [
    {
      title: 'Contact Name',
      key: 'name',
      dataIndex: 'name',
      ellipsis: true,
      render: (name: string) => name
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      ellipsis: true,
      render: (email: string) => email
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone',
      ellipsis: true
    },
    {
      title: 'Relationship',
      key: 'relationship',
      dataIndex: 'relationship',
      ellipsis: true,
      render: (relation: ContactRelationEnum) => renderRelationship(relation)
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, contact) => renderActions(contact)
    }
  ];

  return (
    <>
      <div className="contact-list-component">
        <Row align="middle">
          <Col span={12}>
            <h3>Contacts ({contactsData?.total || 0})</h3>
          </Col>
          <Col span={12} className="text-right">
            <Tooltip title="Create New Contact">
              <Button size="small" type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleAdd} />
            </Tooltip>
          </Col>
        </Row>

        <ResponsiveTable
          mobileUntilBreakpoint="lg"
          mobileColumns={mobileColumns}
          columns={columns}
          dataSource={contactsData?.list || []}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            current: currentPage,
            total: contactsData?.total || 0
          }}
          onChange={onTableChanged}
          rowKey="id"
          bordered
          loading={isFetching}
        />
      </div>

      <EditContactModalComponent />
      <RemoveContactModalComponent />
    </>
  );
}

export default ContactListComponent;
