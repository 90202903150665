import { DeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Space, Tooltip, Typography, Upload } from 'antd';
import { UserGenderEnum } from 'api/AuthApi';
import { useUpdateAvatarMeUserMutation } from 'api/UsersApi';
import noAvatarImage from 'assets/noAvatar.svg';
import EditAccountModalComponent, {
  EDIT_PROFILE_MODAL_NAME
} from 'components/Account/EditAccountModalComponent/EditAccountModalComponent';
import UpdatePartnerModalComponent, {
  UPDATE_PARTNER_MODAL_NAME
} from 'components/Account/UpdatePartnerModalComponent/UpdatePartnerModalComponent';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'store/authSlice';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { getFullName, logging } from 'utils/utils';
import './AccountSettingsComponent.css';

function AccountSettingsComponent() {
  const dispatch = useAppDispatch();
  const user = useSelector(selectAuthUser);

  const [updateAvatarMeUser, { isLoading: isLoadingAddFile, error: errorAddFile }] = useUpdateAvatarMeUserMutation();

  const getUserGender = (gender: UserGenderEnum) => {
    if (gender === UserGenderEnum.Male) {
      return 'Male';
    } else if (gender === UserGenderEnum.Female) {
      return 'Female';
    } else if (gender === UserGenderEnum.NotShared) {
      return 'Prefer not to share';
    } else {
      return 'Not set';
    }
  };

  const updateAvatar = async (file?: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file || '');

      await updateAvatarMeUser({ formData }).unwrap();
    } catch (e) {
      logging(e);
    }
  };

  const handleEditProfile = () => {
    dispatch(openModal({ name: EDIT_PROFILE_MODAL_NAME }));
  };

  const handleUpdatePartner = () => {
    dispatch(openModal({ name: UPDATE_PARTNER_MODAL_NAME }));
  };

  const renderInfoField = (label: string, value: string) => {
    return (
      <div className="info-field">
        <div className="label">{label}</div>
        <Typography.Text>{value}</Typography.Text>
      </div>
    );
  };

  const affiliateLink = `${window.location.protocol}//${window.location.host}/partner/${user?.affiliateCode}`;

  return (
    <div className="account-settings-component">
      <div className="information-block">
        <h3>My Information</h3>
        {user && (
          <>
            <Row align="middle" justify="center" gutter={[20, 20]}>
              <Col xs={24} md={4}>
                <div className="avatar-block">
                  <Upload
                    accept=".jpeg,.jpg,.png,.gif,.svg"
                    showUploadList={false}
                    beforeUpload={async (file) => {
                      await updateAvatar(file);
                      return false;
                    }}
                  >
                    <Avatar src={user.avatarUrl ? user.avatarUrl : noAvatarImage} className="avatar" shape="circle" />
                  </Upload>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={
                      <Tooltip title="Remove Profile Photo">
                        <DeleteOutlined />
                      </Tooltip>
                    }
                    className="remove-avatar"
                    onClick={() => updateAvatar()}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={10}>
                <Row gutter={[0, 20]}>
                  <Col xs={24}>{renderInfoField('Name', getFullName(user))}</Col>
                  <Col xs={24}>{renderInfoField('Gender', getUserGender(user.gender))}</Col>
                </Row>
              </Col>
              <Col xs={24} sm={12} md={10}>
                <Row gutter={[0, 20]}>
                  <Col xs={24}>{renderInfoField('Email', user.email)}</Col>
                  <Col xs={24}>{renderInfoField('Password', '••••••••••••')}</Col>
                </Row>
              </Col>
            </Row>
            <Row align="top" justify="space-between" className="bottom-block">
              <Col sm={24} md={18}>
                <div>
                  {user.affiliateCode ? (
                    <>
                      <Space direction="vertical" size={6}>
                        <strong>Affiliate program</strong>
                        <Typography>Your code: {user.affiliateCode}</Typography>
                        <Typography>
                          Your link: <a href={affiliateLink}>{affiliateLink}</a>
                        </Typography>
                      </Space>
                    </>
                  ) : (
                    <Typography>
                      Partner:{' '}
                      <a onClick={handleUpdatePartner}>
                        {user.affiliateUser ? user.affiliateUser.affiliateCode : 'Not Set'}
                      </a>
                    </Typography>
                  )}
                </div>
              </Col>
              <Col sm={24} md={6} className="text-right">
                <Button onClick={handleEditProfile} className="edit-button">
                  Edit profile
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>

      <UpdatePartnerModalComponent />
      <EditAccountModalComponent />
    </div>
  );
}

export default AccountSettingsComponent;
