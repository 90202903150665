import { MenuOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Dropdown, MenuProps, Row } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { useLogoutMutation } from 'api/AuthApi';
import { UserAccessLevelPlanEnum } from 'api/UsersApi';
import imageLogoVerticalImage from 'assets/logo-vertical.svg';
import imageLogo from 'assets/logo.svg';
import noAvatarImage from 'assets/noAvatar.svg';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { selectAuthUser } from 'store/authSlice';
import { getAccessLevelPlanName, getCurrentUrl } from 'utils/utils';
import './Header.css';

function Header() {
  const navigate = useNavigate();

  const user = useSelector(selectAuthUser);
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    await logout().unwrap();
    window.location.href = '/login';
  };

  const handleGetCredits = () => {
    navigate('/pricing');
  };

  const mainMenu: MenuProps['items'] = [
    {
      label: <NavLink to="/">Home</NavLink>,
      key: 'home'
    },
    {
      label: <NavLink to="/dashboard">Dashboard</NavLink>,
      key: 'dashboard'
    },
    {
      label: <NavLink to="/message-templates">Example Messages</NavLink>,
      key: 'templates'
    },
    {
      label: <NavLink to="/gift">Gift</NavLink>,
      key: 'gift'
    }
  ];

  const subMenu: MenuProps['items'] = [
    ...(user?.isSysAdmin
      ? [
          {
            label: (
              <Link to="/sysadmin">
                <strong>SysAdmin</strong>
              </Link>
            ),
            key: 'sysadmin'
          }
        ]
      : []),
    {
      label: <Link to="/account">Account</Link>,
      key: 'account'
    },
    {
      label: <Link to="/billing">Billing</Link>,
      key: 'billing'
    },
    ...(user?.isAdmin
      ? [
          {
            label: <Link to="/beneficiaries">Administration</Link>,
            key: 'administration'
          }
        ]
      : []),
    {
      label: <Link to="/contacts">Contacts</Link>,
      key: 'contacts'
    },
    {
      type: 'divider'
    },
    {
      label: <Link to="/faq">FAQ</Link>,
      key: 'faq'
    },
    {
      label: (
        <Link to="https://www.eternalapplications.com/privacy" target="_blank">
          Privacy / Terms
        </Link>
      ),
      key: 'privacy'
    },
    {
      label: <Link to="/contact-us">Contact Us</Link>,
      key: 'contact-us'
    },
    {
      type: 'divider'
    },
    {
      label: <div onClick={handleLogout}>Logout</div>,
      key: 'logout',
      danger: true
    }
  ];

  return (
    <>
      <div className="header">
        {user ? (
          <ContainerComponent>
            <>
              <div className="header-mobile">
                <Row>
                  <Col span={8}>
                    <div className="credits" data-tour="get-credits-mobile">
                      {user.accessLevelPlan === UserAccessLevelPlanEnum.Free ? (
                        <Link to="/pricing">
                          <strong>Upgrade</strong>
                        </Link>
                      ) : (
                        <div className="credits-count" onClick={handleGetCredits}>
                          {getAccessLevelPlanName(user.accessLevelPlan)}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col span={8} className="text-center">
                    <NavLink to="/">
                      <img src={imageLogo} alt="" className="logo" />
                    </NavLink>
                  </Col>
                  <Col span={8} className="text-right">
                    <Dropdown
                      menu={{
                        items: [
                          ...mainMenu,
                          {
                            type: 'divider'
                          },
                          ...subMenu
                        ]
                      }}
                      trigger={['click']}
                    >
                      <div className="user-button" data-tour="menu-mobile">
                        <Avatar
                          size={28}
                          src={user?.avatarUrl ? user.avatarUrl : noAvatarImage}
                          className="avatar"
                          shape="circle"
                        />
                        <MenuOutlined />
                      </div>
                    </Dropdown>
                  </Col>
                </Row>
              </div>

              <div className="header-desktop">
                <Row align="middle" wrap={false}>
                  <Col span={16}>
                    <Row align="middle" wrap={false}>
                      <Col>
                        <NavLink to="/">
                          <img src={imageLogo} alt="" className="logo" />
                        </NavLink>
                      </Col>
                      <Col>
                        <ul className="menu" data-tour="menu">
                          {mainMenu.map((item) => (
                            <li key={(item as MenuItemType).key}>{(item as MenuItemType).label}</li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <div className="right">
                      <div className="credits" data-tour="get-credits">
                        {user.accessLevelPlan === UserAccessLevelPlanEnum.Free ? (
                          <Link to="/pricing">
                            <strong>Upgrade Plan</strong>
                          </Link>
                        ) : (
                          <div className="credits-count" onClick={handleGetCredits}>
                            {getAccessLevelPlanName(user.accessLevelPlan)}
                          </div>
                        )}
                      </div>
                      <Dropdown menu={{ items: subMenu }} trigger={['click']}>
                        <div className="user-button">
                          <Avatar
                            size={28}
                            src={user?.avatarUrl ? user.avatarUrl : noAvatarImage}
                            className="avatar"
                            shape="circle"
                          />
                          <MenuOutlined />
                        </div>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          </ContainerComponent>
        ) : (
          <ContainerComponent>
            <div className="header-public">
              <Row align="middle" wrap={false}>
                <Col span={16}>
                  <Row align="middle" wrap={false}>
                    <Col>
                      <NavLink to="/">
                        <img src={imageLogoVerticalImage} alt="" className="logo" />
                      </NavLink>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <div className="right">
                    <Button
                      type="primary"
                      onClick={() =>
                        navigate('/login', {
                          state: { redirectUrl: getCurrentUrl() }
                        })
                      }
                    >
                      Sign In
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </ContainerComponent>
        )}
      </div>
    </>
  );
}

export default Header;
