import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Button, Col, Divider, Form, Input, Row, Space } from 'antd';
import {
  PaymentPlanPrice,
  SetupIntent,
  useCreateGiftSetupIntentMutation,
  useGetPaymentMethodsQuery,
  useGiftPaymentMutation,
  usePreviewGiftPaymentQuery
} from 'api/PaymentApi';
import { GiftData } from 'components/CreateGiftComponent/CreateGiftComponent';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatPrice, logging } from 'utils/utils';
import { selectAuthUser } from '../../../store/authSlice';
import './GiftCheckoutComponent.css';

type AllProps = {
  price: PaymentPlanPrice;
  data: GiftData;
  onPurchaseDone?: () => void;
};

function GiftCheckoutComponent({ price, data, onPurchaseDone }: AllProps) {
  const user = useSelector(selectAuthUser);
  const stripe = useStripe();
  const elements = useElements();
  const [couponName, setCouponName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { data: preview, isLoading: isLoadingPreview } = usePreviewGiftPaymentQuery({
    priceId: price.stripePriceId,
    couponName
  });
  const { data: paymentMethods } = useGetPaymentMethodsQuery();
  const [createGiftSetupIntent] = useCreateGiftSetupIntentMutation();
  const [giftPayment] = useGiftPaymentMutation();

  const [form] = Form.useForm();

  const processPayment = async () => {
    setErrorMessage('');
    await form.validateFields();
    const senderEmail = form.getFieldValue('senderEmail');

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setCouponName(form.getFieldValue('couponName'));

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message || 'Unknown error');
      setIsLoading(false);
      return;
    }

    let paymentMethod = paymentMethods?.length ? paymentMethods[0].id : '';
    if (!paymentMethod) {
      let setupIntent: SetupIntent;
      try {
        setupIntent = await createGiftSetupIntent({ email: senderEmail }).unwrap();
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        return;
      }

      const { setupIntent: setupIntentResult, error: setupIntentError } = await stripe.confirmSetup({
        elements,
        clientSecret: String(setupIntent.clientSecret),
        confirmParams: {
          return_url: window.location.href
        },
        redirect: 'if_required'
      });

      if (setupIntentError) {
        logging(setupIntentError);
        setErrorMessage(setupIntentError.message || 'Unknown error');
        return;
      }
      paymentMethod = String(setupIntentResult?.payment_method);
    }

    let paymentDone = false;
    try {
      paymentDone = await giftPayment({
        priceId: price.stripePriceId,
        couponName,
        paymentMethod,
        recipientName: data.recipientName,
        recipientEmail: data.recipientEmail,
        sendDate: data.sendDate,
        senderName: data.senderName,
        senderText: data.senderText,
        senderEmail
      }).unwrap();
    } catch (e) {
      console.error('Payment Not Done');
      return;
    }

    if (!paymentDone) {
      // error
    }

    setIsLoading(false);

    // if (result) {
    //   dispatch(
    //     purchaseEvent({
    //       id: result.id,
    //       created: result.created,
    //       currency: result.currency,
    //       total: paymentDone.total,
    //       subtotal: paymentDone.subtotal,
    //       plan: paymentDone.plan,
    //       couponName: paymentDone.couponName,
    //       user
    //     })
    //   );
    // }

    onPurchaseDone && onPurchaseDone();
  };

  useEffect(() => {
    if (user) {
      form.setFieldValue('senderEmail', user.email);
    }
  }, [user]);

  return (
    <div className="gift-checkout">
      {errorMessage && <Alert className="error-message" message={errorMessage} type="error" showIcon />}

      <SpinComponent isLoading={isLoadingPreview}>
        {preview && (
          <Form onFinish={() => processPayment()} form={form} layout="vertical" requiredMark="optional">
            <div className="product-info">
              {preview.lines.map((line) => (
                <Row key={line.description}>
                  <Col xs={12}>{line.description}</Col>
                  <Col xs={12} className="text-right">
                    {formatPrice(line.amount / 100)}
                  </Col>
                </Row>
              ))}
              <Row>
                <Col xs={12}>
                  Coupon {/*<Button type="link">+ add coupon</Button>*/}
                  {preview.discount.name ? (
                    <>
                      <strong>{preview.discount.name}</strong>{' '}
                      <Button
                        size="small"
                        onClick={() => {
                          setCouponName('');
                          form.setFieldValue('couponName', '');
                        }}
                      >
                        Remove
                      </Button>
                    </>
                  ) : (
                    <Space.Compact>
                      <Form.Item name="couponName">
                        <Input />
                      </Form.Item>
                      <Button type="primary" onClick={() => setCouponName(form.getFieldValue('couponName'))}>
                        Apply
                      </Button>
                    </Space.Compact>
                  )}
                </Col>
                <Col xs={12} className="text-right">
                  {preview.discount.amount ? (
                    <strong>-{formatPrice(preview.discount.amount / 100)}</strong>
                  ) : (
                    <>{formatPrice(0)}</>
                  )}
                </Col>
              </Row>
              <Divider />
              <Row className="total">
                <Col xs={12}>Total</Col>
                <Col xs={12} className="text-right">
                  {formatPrice(preview.total / 100)}
                </Col>
              </Row>

              {!paymentMethods?.length && (
                <>
                  <Form.Item
                    name="senderEmail"
                    label="Your Email (for billing)"
                    rules={[{ required: true }, { type: 'email' }]}
                  >
                    <Input />
                  </Form.Item>

                  <PaymentElement />
                </>
              )}

              <Button className="pay-button" type="primary" htmlType="submit" size="large" block loading={isLoading}>
                Pay ${(preview.total / 100).toFixed(2)}
              </Button>
            </div>
          </Form>
        )}
      </SpinComponent>
    </div>
  );
}

export default GiftCheckoutComponent;
