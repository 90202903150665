import './TextToHtmlComponent.css';

type AllProps = {
  text: string;
};

export function TextToHtmlComponent({ text }: AllProps) {
  return (
    <div className="text-to-html-component">
      {text.split('\n').map((text, index) => (
        <div key={index}>{text}</div>
      ))}
    </div>
  );
}
