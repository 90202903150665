import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row, Space, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  AdminAccess,
  AdminAccessListEnum,
  AdminAccessRoleEnum,
  AdminAccessStatusEnum,
  useGetAllAdminAccessQuery,
  useReInviteAdminAccessMutation
} from 'api/AdminAccessApi';
import { User } from 'api/UsersApi';
import RemoveAdminModalComponent, {
  DELETE_ADMIN_MODAL_NAME
} from 'components/Account/RemoveAdminModalComponent/RemoveAdminModalComponent';
import UpsertAdminModalComponent, {
  UPSERT_ADMIN_MODAL_NAME
} from 'components/Account/UpsertAdminModalComponent/UpsertAdminModalComponent';
import { ResponsiveTable } from 'components/UiKit/ResponsiveTable/ResponsiveTable';
import { useState } from 'react';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { logging } from 'utils/utils';
import './AdministratorListComponent.css';

const PAGE_SIZE = 10;

function AdministratorListComponent() {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [inviteSentIds, setInviteSentIds] = useState<string[]>([]);

  const [resendInviteAdminAccess] = useReInviteAdminAccessMutation();
  const { data: adminAccessData, isFetching } = useGetAllAdminAccessQuery({
    list: AdminAccessListEnum.Admins,
    limit: PAGE_SIZE,
    offset: (currentPage - 1) * PAGE_SIZE
  });

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const handleAdd = async () => {
    dispatch(
      openModal({
        name: UPSERT_ADMIN_MODAL_NAME,
        onClose: () => setCurrentPage(1)
      })
    );
  };

  const handleEdit = async (id: string) => {
    dispatch(openModal({ name: UPSERT_ADMIN_MODAL_NAME, data: { id } }));
  };

  const handleRemove = async (id: string) => {
    dispatch(openModal({ name: DELETE_ADMIN_MODAL_NAME, data: { id } }));
  };

  const handleResendInvite = async (id: string) => {
    setInviteSentIds((ids) => [...ids, id]);

    try {
      await resendInviteAdminAccess({ id }).unwrap();
    } catch (e) {
      logging(e);
    }
  };

  const renderRole = (role: AdminAccessRoleEnum) => {
    return role === AdminAccessRoleEnum.Manager ? <>Manage</> : <>Notify Only</>;
  };

  const renderStatus = (status: AdminAccessStatusEnum) => {
    return status === AdminAccessStatusEnum.Accepted ? (
      <Tag color="success">Accepted</Tag>
    ) : status === AdminAccessStatusEnum.Declined ? (
      <Tag color="error">Declined</Tag>
    ) : (
      <Tag color="default">Pending</Tag>
    );
  };

  const renderActions = (adminAccess: AdminAccess) => {
    return (
      <Space size="small">
        <a onClick={() => handleEdit(adminAccess.id)}>
          <Tooltip title="Edit Administrator">
            <EditOutlined />
          </Tooltip>
        </a>
        <a onClick={() => handleRemove(adminAccess.id)}>
          <Tooltip title="Remove Administrator">
            <DeleteOutlined />
          </Tooltip>
        </a>
        {adminAccess.status !== AdminAccessStatusEnum.Accepted &&
          (inviteSentIds.includes(adminAccess.id) ? (
            'Invite Sent'
          ) : (
            <a onClick={() => handleResendInvite(adminAccess.id)}>
              <Tooltip title="Resend Invite">Resend Invite</Tooltip>
            </a>
          ))}
      </Space>
    );
  };

  const mobileColumns: ColumnsType<AdminAccess> = [
    {
      render: (adminAccess: AdminAccess) => (
        <Space size={4} direction="vertical" className="responsive-table-card">
          <Flex justify="space-between">
            <div className="responsive-table-title">
              <Typography.Text>{adminAccess.guessedAdminName}</Typography.Text>
            </div>
            <div>{renderStatus(adminAccess.status)}</div>
          </Flex>
          <div>
            <Typography.Text>{adminAccess.admin.email}</Typography.Text>
            <Typography.Text>{adminAccess.relationship}</Typography.Text>
          </div>
          <Flex justify="space-between">
            <div>{renderRole(adminAccess.role)}</div>
            <div>{renderActions(adminAccess)}</div>
          </Flex>
        </Space>
      )
    }
  ];

  const columns: ColumnsType<AdminAccess> = [
    {
      title: 'Administrator Name',
      key: 'name',
      dataIndex: 'guessedAdminName',
      ellipsis: true,
      render: (guessedAdminName: string) => guessedAdminName
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'admin',
      ellipsis: true,
      render: (admin: User) => admin.email
    },
    {
      title: 'Relationship',
      key: 'relationship',
      dataIndex: 'relationship',
      ellipsis: true,
      render: (relationship: string) => relationship
    },
    {
      title: 'Access',
      key: 'role',
      dataIndex: 'role',
      render: (role: AdminAccessRoleEnum) => renderRole(role)
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: AdminAccessStatusEnum) => renderStatus(status)
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, adminAccess) => renderActions(adminAccess)
    }
  ];

  return (
    <div className="administrator-list-component">
      <Row align="middle">
        <Col span={14}>
          <h3>Administrators ({adminAccessData?.total || 0})</h3>
        </Col>
        <Col span={10} className="text-right">
          <Tooltip title="Create New Administator">
            <Button size="small" type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleAdd} />
          </Tooltip>
        </Col>
      </Row>

      <ResponsiveTable
        mobileUntilBreakpoint="lg"
        mobileColumns={mobileColumns}
        columns={columns}
        dataSource={adminAccessData?.list || []}
        pagination={{
          defaultPageSize: PAGE_SIZE,
          current: currentPage,
          total: adminAccessData?.total || 0
        }}
        onChange={onTableChanged}
        rowKey="id"
        bordered
        loading={isFetching}
      />

      <UpsertAdminModalComponent />
      <RemoveAdminModalComponent />
    </div>
  );
}

export default AdministratorListComponent;
