import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'api/Api';
import { invalidateAuthTags } from 'api/AuthApi';
import { User } from 'api/UsersApi';

export enum AdminAccessStatusEnum {
  Invited = 'invited',
  Accepted = 'accepted',
  Declined = 'declined'
}

export enum AdminAccessRoleEnum {
  Activator = 'activator',
  Manager = 'manager'
}

export enum AdminAccessListEnum {
  Admins = 'admins',
  Users = 'users'
}

export interface AdminAccess {
  id: string;
  admin: User;
  user: User;
  status: AdminAccessStatusEnum;
  role: AdminAccessRoleEnum;
  isActive: string;
  relationship: string;
  guessedAdminName: string;
}

export const adminAccessApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'adminAccessApi',
  tagTypes: ['AdminAccess'],
  endpoints: (build) => ({
    getAllAdminAccess: build.query<
      { list: AdminAccess[]; total: number },
      {
        list: AdminAccessListEnum;
        limit?: number;
        offset?: number;
        filter?: { userId?: string; status?: AdminAccessStatusEnum };
      }
    >({
      query: (params) => {
        const filter = {};

        if (params.filter) {
          if (params.filter.userId) {
            filter['userId'] = params.filter.userId;
          }

          if (params.filter.status) {
            filter['status'] = params.filter.status;
          }
        }

        return {
          url: '/admin-access',
          params: { ...params, filter: JSON.stringify(filter) }
        };
      },
      transformResponse: (response: { data: [AdminAccess[], number] }) => ({
        list: response.data[0],
        total: response.data[1]
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.list.map(({ id }) => ({
                type: 'AdminAccess' as const,
                id
              })),
              { type: 'AdminAccess', id: 'LIST' }
            ]
          : [{ type: 'AdminAccess', id: 'LIST' }]
    }),
    getOneAdminAccess: build.query<AdminAccess, Pick<AdminAccess, 'id'>>({
      query: ({ id }) => ({ url: `/admin-access/${id}` }),
      transformResponse: (response: { data: AdminAccess }) => response.data,
      providesTags: (result, error, { id }) => [{ type: 'AdminAccess', id }]
    }),
    createAdminAccess: build.mutation<
      AdminAccess,
      Pick<User, 'email'> & Pick<AdminAccess, 'role' | 'relationship' | 'guessedAdminName'>
    >({
      query: (body) => ({
        url: '/admin-access',
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: AdminAccess }) => response.data,
      invalidatesTags: () => [{ type: 'AdminAccess', id: 'LIST' }]
    }),
    editAdminAccess: build.mutation<
      AdminAccess,
      Pick<AdminAccess, 'id' | 'role' | 'relationship' | 'guessedAdminName'>
    >({
      query: ({ id, ...body }) => ({
        url: `/admin-access/${id}`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: { adminAccess: AdminAccess } }) => response.data.adminAccess,
      invalidatesTags: (result, error, { id }) => [{ type: 'AdminAccess', id }]
    }),
    reInviteAdminAccess: build.mutation<boolean, Pick<AdminAccess, 'id'>>({
      query: ({ id }) => ({
        url: `/admin-access/${id}/reinvite`,
        method: 'POST'
      }),
      transformResponse: (response: { success: boolean }) => response.success
    }),
    acceptAdminAccess: build.mutation<AdminAccess, Pick<AdminAccess, 'id'>>({
      query: ({ id }) => ({
        url: `/admin-access/${id}/accept`,
        method: 'POST'
      }),
      transformResponse: (response: { data: AdminAccess }) => response.data,
      invalidatesTags: () => [{ type: 'AdminAccess', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    declineAdminAccess: build.mutation<AdminAccess, Pick<AdminAccess, 'id'>>({
      query: ({ id }) => ({
        url: `/admin-access/${id}/decline`,
        method: 'POST'
      }),
      transformResponse: (response: { data: AdminAccess }) => response.data,
      invalidatesTags: () => [{ type: 'AdminAccess', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    }),
    removeAdminAccess: build.mutation<boolean, Pick<AdminAccess, 'id'>>({
      query: ({ id }) => ({
        url: `/admin-access/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { data: { deleted: boolean } }) => response.data.deleted,
      invalidatesTags: () => [{ type: 'AdminAccess', id: 'LIST' }]
    })
  })
});

export const invalidateAdminTags = () => adminAccessApi.util.invalidateTags([{ type: 'AdminAccess', id: 'LIST' }]);

export const {
  useGetAllAdminAccessQuery,
  useGetOneAdminAccessQuery,
  useCreateAdminAccessMutation,
  useEditAdminAccessMutation,
  useReInviteAdminAccessMutation,
  useAcceptAdminAccessMutation,
  useDeclineAdminAccessMutation,
  useRemoveAdminAccessMutation
} = adminAccessApi;
