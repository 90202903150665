import ContactListComponent from 'components/Contact/ContactListComponent/ContactListComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import './Contacts.css';

function Contacts() {
  return (
    <>
      <div className="contacts layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <ContactListComponent />
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default Contacts;
