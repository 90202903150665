import SysAdminGiftListComponent from 'components/SysAdmin/SysAdminGiftListComponent/SysAdminGiftListComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import SysAdminMenu, { SysAdminMenuEnum } from 'pages/SysAdmin/SysAdminMenu/SysAdminMenu';
import './Gifts.css';

function Gifts() {
  return (
    <>
      <div className="sys-admin-users layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <>
            <SysAdminMenu selectedMenu={SysAdminMenuEnum.GIFTS} />

            <SysAdminGiftListComponent />
          </>
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default Gifts;
