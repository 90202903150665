import SysAdminReportUserComponent from 'components/SysAdmin/SysAdminReportUserComponent/SysAdminReportUserComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import SysAdminMenu, { SysAdminMenuEnum } from 'pages/SysAdmin/SysAdminMenu/SysAdminMenu';
import { useParams } from 'react-router-dom';
import './ReportUser.css';

function ReportUser() {
  const { userId } = useParams();

  return (
    <>
      <div className="sys-admin-report layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <>
            <SysAdminMenu selectedMenu={SysAdminMenuEnum.REPORT} />

            <SysAdminReportUserComponent userId={userId!} />
          </>
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default ReportUser;
