import { Alert, Button, Form, Input } from 'antd';
import { useConfirmEmailMutation } from 'api/AuthApi';
import AuthComponent from 'components/AuthComponent/AuthComponent';
import { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { logging, validateForm } from 'utils/utils';
import './ConfirmEmail.css';

function ConfirmEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();

  const [confirmEmail, { isError, isLoading }] = useConfirmEmailMutation();

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      await confirmEmail({
        email: fields.email,
        code: fields.code
      }).unwrap();

      form.setFieldValue('email', '');
      form.setFieldValue('code', '');

      navigate(`/login?email=${encodeURIComponent(fields.email)}&confirm=true`);
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  useEffect(() => {
    if (searchParams.get('code')) {
      onSubmit();
    }
  }, []);

  return (
    <AuthComponent
      title="Confirm Email"
      subtitle={
        <>
          Already have an account? <Link to="/login">Login Here</Link>
        </>
      }
    >
      <div className="confirm-email">
        <Form
          name="basic"
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{
            email: searchParams.get('email'),
            code: searchParams.get('code')
          }}
          autoComplete="off"
          requiredMark="optional"
          form={form}
        >
          {isError && <Alert className="message" message="Cannot confirm email" type="error" showIcon />}

          <Alert
            message={
              <>
                <p>
                  Confirmation Code was sent to your email <strong>{searchParams.get('email') || ''}</strong>
                </p>
                <p>
                  Please be sure to check your spam or junk folder if you don't receive a confirmation email in your
                  inbox within a few minutes.
                </p>
              </>
            }
            type="info"
            showIcon
          />

          <Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
            <Input size="large" placeholder="email@domain.com" />
          </Form.Item>

          <Form.Item label="Code" name="code" rules={[{ required: true }]}>
            <Input size="large" />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Confirm Email
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthComponent>
  );
}

export default ConfirmEmail;
