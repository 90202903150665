import { useCallback, useRef, useState } from 'react';

export const useCountDown = () => {
  const [timerCountDown, setTimerCountDown] = useState(0);
  const [isCountDownStarted, setIsCountDownStarted] = useState(false);
  const timer = useRef({
    started: false,
    counted: 0
  });

  const startCountDown = useCallback((times: number, callback: any) => {
    if (timer.current.started) {
      return;
    }

    setIsCountDownStarted(true);
    setTimerCountDown(times);
    timer.current.started = true;
    timer.current.counted = 0;

    const interval = setInterval(() => {
      timer.current.counted++;
      if (timer.current.counted === times) {
        setIsCountDownStarted(false);
        callback();
        clearInterval(interval);
      } else {
        setTimerCountDown(times - timer.current.counted);
      }
    }, 1000);
  }, []);

  return { timerCountDown, isCountDownStarted, startCountDown };
};
