import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useUpdatePartnerMeUserMutation } from 'api/UsersApi';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { removeAffiliateCode, selectAffiliateCode } from 'store/affiliateSlice';
import { selectAuthUser } from 'store/authSlice';
import { closeModal, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { logging, validateForm } from 'utils/utils';
import './UpdatePartnerModalComponent.css';

export const UPDATE_PARTNER_MODAL_NAME = 'UPDATE_PARTNER_MODAL_NAME';

function UpdatePartnerModalComponent() {
  const dispatch = useAppDispatch();
  const affiliateCode = useSelector(selectAffiliateCode);
  const user = useSelector(selectAuthUser);

  const [isChangingPartner, setIsChangingPartner] = useState(false);
  const [updatePartnerMeUser, { isLoading: isLoadingUpdate }] = useUpdatePartnerMeUserMutation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
    if (!user?.affiliateUser) {
      setIsChangingPartner(true);
    }
  }, [user]);

  const handleUpdate = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      await updatePartnerMeUser({
        partnerCode: fields.partnerCode
      }).unwrap();

      dispatch(removeAffiliateCode());

      handleCloseModal();
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  const handleRemovePartner = async () => {
    try {
      await updatePartnerMeUser({
        partnerCode: ''
      }).unwrap();

      handleCloseModal();
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal open={true} title="Update Partner" footer={null} onCancel={handleCloseModal}>
      <div className="update-partner-modal-component">
        {user?.affiliateUser && (
          <p>
            You current partner is <strong>{user.affiliateUser.affiliateCode}</strong>
          </p>
        )}

        <Form
          name="basic"
          layout="inline"
          onFinish={handleUpdate}
          autoComplete="off"
          requiredMark="optional"
          initialValues={{ partnerCode: affiliateCode }}
          form={form}
        >
          {isChangingPartner ? (
            <>
              <Form.Item label="Partner Code" name="partnerCode" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button block type="primary" htmlType="submit" loading={isLoadingUpdate}>
                  Set
                </Button>
              </Form.Item>
            </>
          ) : (
            <a onClick={() => setIsChangingPartner(true)}>Set new Partner</a>
          )}
        </Form>

        <Row justify="space-between" className="buttons">
          <Col xs={12}>
            <Button onClick={handleRemovePartner} type="default" danger>
              Remove Partner
            </Button>
          </Col>
          <Col xs={12} className="text-right">
            <Button onClick={handleCloseModal}>Close</Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

function UpdatePartnerModalComponentWrapper() {
  const modalName = useSelector(selectModalName);

  return modalName === UPDATE_PARTNER_MODAL_NAME ? <UpdatePartnerModalComponent /> : null;
}

export default UpdatePartnerModalComponentWrapper;
