import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'api/Api';
import { invalidateAuthTags } from 'api/AuthApi';

export interface Gift {
  id: string;
  planName: string;
  couponName: string;
  recipientName: string;
  recipientEmail: string;
  sendDate: string;
  senderName: string;
  senderText: string;
}

export const giftsApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'giftsApi',
  tagTypes: ['Gifts'],
  endpoints: (build) => ({
    getGifts: build.query<Gift[], void>({
      query: () => ({ url: `/gifts` }),
      transformResponse: (response: { data: Gift[] }) => response.data,
      providesTags: () => ['Gifts']
    }),
    applyGift: build.mutation<boolean, Pick<Gift, 'id'>>({
      query: ({ id }) => ({
        url: `/gifts/${id}/use`,
        method: 'POST'
      }),
      transformResponse: (response: { success: boolean }) => response.success,
      invalidatesTags: (result, error, { id }) => [{ type: 'Gifts', id }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAuthTags());
        } catch (e) {}
      }
    })
  })
});

export const { useGetGiftsQuery, useApplyGiftMutation } = giftsApi;
