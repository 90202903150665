import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Select } from 'antd';
import { UserGenderEnum } from 'api/AuthApi';
import { useUpdateMeUserMutation } from 'api/UsersApi';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'store/authSlice';
import { closeModal, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { logging, validateForm } from 'utils/utils';
import './EditAccountModalComponent.css';

export const EDIT_PROFILE_MODAL_NAME = 'EDIT_PROFILE_MODAL_NAME';

function EditAccountModalComponent() {
  const dispatch = useAppDispatch();
  const user = useSelector(selectAuthUser);

  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isCodeRequired, setIsCodeRequired] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const [updateMeUser, { isLoading: isLoadingEdit, error: errorUpdate }] = useUpdateMeUserMutation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
      if (newEmail) {
        form.setFieldValue('email', newEmail);
      }
    }
  }, [user]);

  const handleUpdate = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      if (isChangingEmail) {
        setNewEmail(fields.email);
      }

      await updateMeUser({
        firstName: fields.firstName,
        lastName: fields.lastName,
        gender: fields.gender,
        ...(isChangingEmail ? { email: fields.email, code: fields.code } : null),
        ...(isChangingPassword ? { password: fields.password } : null)
      }).unwrap();

      if (isChangingEmail && user?.email !== fields.email && !isCodeRequired) {
        setIsCodeRequired(true);
        return;
      }

      handleCloseModal();
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const error: any = errorUpdate;

  return (
    <Modal
      open={true}
      title="Edit Profile"
      onOk={handleUpdate}
      onCancel={handleCloseModal}
      okText="Update"
      confirmLoading={isLoadingEdit}
    >
      <div className="edit-account-modal-component">
        <Form name="basic" layout="vertical" autoComplete="off" requiredMark="optional" form={form}>
          {error?.data.message && <Alert className="message" message={error.data.message} type="error" showIcon />}

          <Row gutter={[20, 10]}>
            <Col xs={12}>
              <Form.Item label="First Name" name="firstName" rules={[{ required: true }]} className="form-item">
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]} className="form-item">
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item label="Gender" name="gender" rules={[{ required: true }]} className="form-item">
                <Select
                  size="large"
                  placeholder="Select"
                  options={[
                    { value: UserGenderEnum.Male, label: 'Male' },
                    { value: UserGenderEnum.Female, label: 'Female' },
                    { value: UserGenderEnum.NotShared, label: 'Prefer not to share' }
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          {isChangingEmail ? (
            <>
              <Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
                <Input size="large" onChange={() => setIsEmailChanged(user?.email !== form.getFieldValue('email'))} />
              </Form.Item>
              {isCodeRequired ? (
                <Form.Item label="Code" name="code" rules={[{ required: true }]}>
                  <Input size="large" />
                </Form.Item>
              ) : (
                isEmailChanged && (
                  <Alert message="Conformation Code will be sent to your new email." type="info" showIcon />
                )
              )}
            </>
          ) : (
            <Row align="middle" justify="space-between">
              <Col>Email: {user?.email}</Col>
              <Col>
                <Button onClick={() => setIsChangingEmail(true)}>Change email</Button>
              </Col>
            </Row>
          )}

          <Divider />

          {isChangingPassword ? (
            <Form.Item label="New Password" name="password" rules={[{ required: true }]}>
              <Input size="large" />
            </Form.Item>
          ) : (
            <Row align="middle" justify="space-between">
              <Col>Password: ••••••••••••</Col>
              <Col>
                <Button onClick={() => setIsChangingPassword(true)}>Change password</Button>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </Modal>
  );
}

function EditAccountModalComponentWrapper() {
  const modalName = useSelector(selectModalName);

  return modalName === EDIT_PROFILE_MODAL_NAME ? <EditAccountModalComponent /> : null;
}

export default EditAccountModalComponentWrapper;
