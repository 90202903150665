import { PaymentPlanPrice } from 'api/PaymentApi';
import OneTimeCheckoutComponent from 'components/Account/PricingComponent/CheckoutFormComponent/OneTimeCheckoutComponent/OneTimeCheckoutComponent';
import SubscriptionCheckoutComponent from 'components/Account/PricingComponent/CheckoutFormComponent/SubscriptionCheckoutComponent/SubscriptionCheckoutComponent';
import './CheckoutFormComponent.css';

type AllProps = {
  price: PaymentPlanPrice;
  onPurchaseDone?: () => void;
};

function CheckoutFormComponent({ price, onPurchaseDone }: AllProps) {
  return (
    <div className="checkout-form-component">
      {price.period === 'one-time' ? (
        <OneTimeCheckoutComponent price={price} onPurchaseDone={onPurchaseDone} />
      ) : (
        <SubscriptionCheckoutComponent price={price} onPurchaseDone={onPurchaseDone} />
      )}
    </div>
  );
}

export default CheckoutFormComponent;
