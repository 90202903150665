import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Form, Radio, Row, Select, Space, Tooltip } from 'antd';
import { Message, MessageEventTypeEnum, MessageTemplate } from 'api/MessagesApi';
import { BubbleHintComponent, BubbleHintOption } from 'components/UiKit/BubbleHintComponent/BubbleHintComponent';
import { useEffect, useState } from 'react';
import { checkIfDateValid, generateRange, getMonthNames, logging } from 'utils/utils';
import './StepEventComponent.css';

export type StepEventData = Pick<Message, 'eventType' | 'deliveryYear' | 'deliveryMonth' | 'deliveryDay'>;

type AllProps = {
  message: Message;
  onNext: (data: StepEventData, file?: File | null, afterAction?: 'next' | 'none' | 'exit') => void;
  onBack: () => void;
  messageTemplate?: MessageTemplate;
  isLoading?: boolean;
};

function StepEventComponent({ message, onNext, onBack, isLoading, messageTemplate }: AllProps) {
  const [eventType, setEventType] = useState(messageTemplate?.eventType || MessageEventTypeEnum.Final);
  const [isDisplayYear, setIsDisplayYear] = useState(true);
  const [dateError, setDateError] = useState('');

  const [form] = Form.useForm();

  useEffect(() => {
    if (message) {
      form.setFieldsValue({
        eventType: message.eventType || MessageEventTypeEnum.Final,
        deliveryYear: message.deliveryYear,
        deliveryMonth: message.deliveryMonth,
        deliveryDay: message.deliveryDay
      });
      setEventType((message.eventType || MessageEventTypeEnum.Final) as MessageEventTypeEnum);
      setIsDisplayYear(!((message.deliveryMonth || message.deliveryDay) && message?.deliveryYear === null));
    }
  }, [message]);

  const eventTypeOptions: BubbleHintOption[] = [
    {
      value: MessageEventTypeEnum.Final,
      title: 'Final Thoughts / Parting Message',
      description:
        'The Final Thoughts / Parting Message is designed to be delivered shortly after your passing. Since this date is unknown all you’ll need to provide is the recipient(s) information and we’ll take care of the rest.'
    },
    {
      value: MessageEventTypeEnum.Birthday,
      title: 'Birthday',
      description:
        'Commemorate a special birthday! By default Birthday messages are delivered at the first occurrence following your passing unless we are provided a specific year the message is to be delivered.'
    },
    {
      value: MessageEventTypeEnum.Holiday,
      title: 'Holiday',
      description:
        'Be there with your loved ones on the days that matter! By default Holiday messages are delivered at the first occurrence following your passing, unless we are provided a specific year the message is to be delivered.'
    },
    {
      value: MessageEventTypeEnum.Anniversary,
      title: 'Anniversary',
      description:
        'Celebrate your love one more time! By default Anniversary messages are delivered at the first occurrence following your passing, unless we are provided a specific year the message is to be delivered.'
    },
    {
      value: MessageEventTypeEnum.Other,
      title: 'Other/Custom',
      description: "Leave a custom message for any occasion. Just insert the date and we'll take care of the rest."
    }
  ];

  const isEventWithDate = () => {
    return eventType !== MessageEventTypeEnum.Final;
  };

  const onSubmit = async (isExit?: boolean) => {
    try {
      setDateError('');

      await form.validateFields();
      const fields = form.getFieldsValue();

      if (isEventWithDate()) {
        if (!checkIfDateValid(fields.deliveryDay, fields.deliveryMonth, isDisplayYear ? fields.deliveryYear : null)) {
          setDateError('This date is invalid');
          return;
        }

        // TODO: check if date is in future
      }

      const data: StepEventData = {
        eventType,
        deliveryYear: fields.deliveryYear,
        deliveryMonth: fields.deliveryMonth,
        deliveryDay: fields.deliveryDay
      };
      onNext(data, undefined, isExit ? 'exit' : 'next');
    } catch (e) {
      logging(e);
    }
  };

  return (
    <div className="step-event-component create-message-component-full-height">
      <Form
        layout="vertical"
        onFinish={onSubmit}
        autoComplete="off"
        requiredMark="optional"
        form={form}
        initialValues={{ eventType: messageTemplate?.eventType }}
      >
        <div className="create-message-component-title">
          What event will trigger the process to deliver your messages?
        </div>
        <p>
          <strong>Select the option that best applies.</strong>
        </p>
        <BubbleHintComponent
          options={eventTypeOptions}
          selectedValue={eventType}
          onChange={(value) => setEventType(value as MessageEventTypeEnum)}
        />

        {isEventWithDate() && (
          <div className="select-delivery-date">
            <p>
              <strong>Do you know the year you would like this message delivered?</strong>{' '}
              <Tooltip title="Messages will only be delivered if your administrator has marked you as deceased">
                <InfoCircleOutlined className="cursor-pointer" />
              </Tooltip>
            </p>

            <Radio.Group
              onChange={(e) => setIsDisplayYear(e.target.value)}
              value={isDisplayYear}
              className="is-display-year"
            >
              <Space direction="horizontal">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>

            <Row gutter={10} className="delivery-date-inputs">
              {isDisplayYear && (
                <Col span={8}>
                  <Form.Item name="deliveryYear" messageVariables={{ label: 'Year' }} rules={[{ required: true }]}>
                    <Select
                      placeholder="Year"
                      options={generateRange(new Date().getFullYear(), new Date().getFullYear() + 50).map((year) => ({
                        label: year,
                        value: year
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={8}>
                <Form.Item name="deliveryMonth" messageVariables={{ label: 'Month' }} rules={[{ required: true }]}>
                  <Select
                    placeholder="Month"
                    options={getMonthNames().map((month, index) => ({
                      label: month,
                      value: index + 1
                    }))}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="deliveryDay" messageVariables={{ label: 'Day' }} rules={[{ required: true }]}>
                  <Select
                    placeholder="Day"
                    options={generateRange(1, 31).map((day) => ({
                      label: day,
                      value: day
                    }))}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            {dateError && <div className="error">{dateError}</div>}
          </div>
        )}
      </Form>

      <Row className="create-message-component-footer">
        <Col span={12}>
          <Button onClick={onBack} type="link">
            <ArrowLeftOutlined /> Back
          </Button>
        </Col>
        <Col span={12} className="text-right">
          <Flex gap={20} justify="flex-end">
            <Button onClick={() => onSubmit(true)}>Save & Exit</Button>
            <Button onClick={() => onSubmit()} type="primary" loading={isLoading}>
              Next
            </Button>
          </Flex>
        </Col>
      </Row>
    </div>
  );
}

export default StepEventComponent;
