import { DatePicker, Space, Typography } from 'antd';
import { useGetOneUserSysAdminQuery, useGetReportSysAdminQuery } from 'api/SysAdminApi';
import SysAdminPaymentListComponent from 'components/SysAdmin/SysAdminPaymentListComponent/SysAdminPaymentListComponent';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectReportFromDate, selectReportToDate, setReportFromDate, setReportToDate } from 'store/reportSlice';
import { useAppDispatch } from 'store/store';
import { getFullNameOrEmail } from 'utils/utils';
import './SysAdminReportUserComponent.css';

type AllProps = {
  userId: string;
};

function SysAdminReportUserComponent({ userId }: AllProps) {
  const dispatch = useAppDispatch();
  const reportFromDate = useSelector(selectReportFromDate);
  const reportToDate = useSelector(selectReportToDate);

  const [fromDate, setFromDate] = useState<string | null>(reportFromDate);
  const [toDate, setToDate] = useState<string | null>(reportToDate);

  const { data: sysAdminReportData, isFetching } = useGetReportSysAdminQuery(
    {
      fromDate: fromDate!,
      toDate: toDate!,
      userId
    },
    { skip: !fromDate || !toDate }
  );

  const { data: sysAdminUserData } = useGetOneUserSysAdminQuery({
    id: userId
  });

  const userReport = sysAdminReportData?.list[0] || null;

  return (
    <>
      <div className="sys-admin-report-user-component">
        <DatePicker.RangePicker
          size="large"
          defaultValue={[
            reportFromDate ? dayjs(reportFromDate, 'YYYY-MM-DD') : null,
            reportToDate ? dayjs(reportToDate, 'YYYY-MM-DD') : null
          ]}
          onChange={(dates, dateStrings) => {
            const [from, to] = dateStrings;

            setFromDate(from);
            setToDate(to);

            dispatch(setReportFromDate(from));
            dispatch(setReportToDate(to));
          }}
        />
        <Typography>
          <h2>Report for: {getFullNameOrEmail(sysAdminUserData)}</h2>
        </Typography>

        {fromDate && toDate && userReport && (
          <Space direction="vertical">
            <div>
              <strong>Total:</strong> {`$${(parseInt(userReport.amountTotal) / 100).toLocaleString()}`}
            </div>
            <div>
              <strong>Commission:</strong>{' '}
              {`$${(Math.round(parseInt(userReport.amountTotal) * 0.03) / 100).toLocaleString()}`}
            </div>
          </Space>
        )}

        <SysAdminPaymentListComponent affiliateUserId={userId} fromDate={fromDate} toDate={toDate} />
      </div>
    </>
  );
}

export default SysAdminReportUserComponent;
