import { AudioOutlined, FormOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { MessageTypeEnum } from 'api/MessagesApi';
import imageMessageAudioImage from 'assets/message-audio-image.svg';
import imageMessageTextImage from 'assets/message-text-image.svg';
import imageMessageVideoImage from 'assets/message-video.image.svg';
import { useNavigate } from 'react-router-dom';
import './SelectCreateMessageComponent.css';

function SelectCreateMessageComponent() {
  const navigate = useNavigate();

  const createMessage = (type: MessageTypeEnum) => {
    navigate('/message', { state: { messageType: type } });
  };

  const renderCard = ({ icon, title, image, onClick }) => {
    return (
      <Col xs={24} lg={8}>
        <div className="card box-shadow-animation" onClick={onClick}>
          <div className="image">
            <img src={image} alt={title} />
          </div>
          <div className="title">
            <div>
              {icon} {title}
            </div>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <div className="select-create-message-component" data-tour="create-message">
      <p className="title">Which message type would you like to leave?</p>
      <p className="subtitle text-center">Select from Written, Audio or Video message.</p>
      <Row gutter={[20, 10]}>
        {renderCard({
          icon: <FormOutlined />,
          title: 'Written Message',
          image: imageMessageTextImage,
          onClick: () => createMessage(MessageTypeEnum.Text)
        })}
        {renderCard({
          icon: <AudioOutlined />,
          title: 'Audio Message',
          image: imageMessageAudioImage,
          onClick: () => createMessage(MessageTypeEnum.Audio)
        })}
        {renderCard({
          icon: <VideoCameraOutlined />,
          title: 'Video Message',
          image: imageMessageVideoImage,
          onClick: () => createMessage(MessageTypeEnum.Video)
        })}
      </Row>
    </div>
  );
}

export default SelectCreateMessageComponent;
