import { AudioOutlined, FormOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Table, Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Contact } from 'api/ContactsApi';
import { Message, MessageStatusEnum, MessageTypeEnum } from 'api/MessagesApi';
import { useGetAllMessagesSysAdminQuery } from 'api/SysAdminApi';
import imagePreview from 'assets/preview.svg';
import { SYSADMIN_PREVIEW_MESSAGE_MODAL_NAME } from 'components/SysAdmin/SysAdminPreviewMessageModalComponent/SysAdminPreviewMessageModalComponent';
import { useState } from 'react';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { displayDeliveryDate } from 'utils/utils';
import './SysAdminMessageListComponent.css';

type AllProps = {
  userId: string;
};

const PAGE_SIZE = 10;

function SysAdminMessageListComponent({ userId }: AllProps) {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: sysAdminPaymentListData, isFetching } = useGetAllMessagesSysAdminQuery({
    userId,
    limit: PAGE_SIZE,
    offset: (currentPage - 1) * PAGE_SIZE
  });

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const previewMessage = (id: string) => {
    dispatch(openModal({ name: SYSADMIN_PREVIEW_MESSAGE_MODAL_NAME, data: { id } }));
  };

  const renderPreviewIcon = (message: Message) => {
    const hasPreview = message.type === MessageTypeEnum.Text || message.fileName !== '';

    return (
      hasPreview && (
        <Tooltip title="Preview">
          <img src={imagePreview} alt="preview" onClick={() => previewMessage(message.id)} className="cursor-pointer" />
        </Tooltip>
      )
    );
  };

  const renderTypeIcon = (type: MessageTypeEnum) => {
    if (type === MessageTypeEnum.Video) {
      return <VideoCameraOutlined />;
    } else if (type === MessageTypeEnum.Audio) {
      return <AudioOutlined />;
    } else {
      return <FormOutlined />;
    }
  };

  const renderContacts = (contacts: Contact[]) => {
    return (
      contacts &&
      contacts.map((contact) => (
        <div key={contact.id}>
          <Typography.Text>
            {contact.name} ({contact.email})
          </Typography.Text>
        </div>
      ))
    );
  };

  const renderStatus = (status: MessageStatusEnum) => {
    const statuses = {
      [MessageStatusEnum.Published]: <Tag color="success">Completed</Tag>,
      [MessageStatusEnum.Delivered]: <Tag color="geekblue">Delivered</Tag>
    };

    return statuses[status] ? statuses[status] : <Tag color="default">Draft</Tag>;
  };

  const columns: ColumnsType<Message> = [
    {
      title: 'Message Name',
      key: 'name',
      render: (_, message) => (
        <div className="message-title">
          <Typography.Text>
            {message.name} {renderPreviewIcon(message)}
          </Typography.Text>
        </div>
      )
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (type) => renderTypeIcon(type)
    },
    {
      title: 'Recipient(s)',
      key: 'recipient',
      dataIndex: 'contacts',
      render: (contacts: Contact[]) => renderContacts(contacts)
    },
    {
      title: 'Delivery Date',
      key: 'delivery',
      render: (_, message) => displayDeliveryDate(message)
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: MessageStatusEnum) => renderStatus(status)
    }
  ];

  return (
    <>
      <div className="sys-admin-user-list-component">
        <h3>Messages ({sysAdminPaymentListData?.total || 0})</h3>

        <Table
          columns={columns}
          dataSource={sysAdminPaymentListData?.list || []}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            current: currentPage,
            total: sysAdminPaymentListData?.total || 0
          }}
          onChange={onTableChanged}
          rowKey="id"
          bordered
          loading={isFetching}
        />
      </div>
    </>
  );
}

export default SysAdminMessageListComponent;
