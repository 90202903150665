import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import affiliateReducer from 'store/affiliateSlice';
import authReducer from 'store/authSlice';
import configReducer from 'store/configSlice';
import ecommerceSlice from 'store/ecommerceSlice';
import modalReducer from 'store/modalSlice';
import reportReducer from 'store/reportSlice';

import { adminAccessApi } from 'api/AdminAccessApi';
import { adminAccessMessagesApi } from 'api/AdminAccessMessagesApi';
import { authApi } from 'api/AuthApi';
import { commonApi } from 'api/CommonApi';
import { contactsApi } from 'api/ContactsApi';
import { giftsApi } from 'api/GiftsApi';
import { messagesApi } from 'api/MessagesApi';
import { paymentApi } from 'api/PaymentApi';
import { sysAdminApi } from 'api/SysAdminApi';
import { usersApi } from 'api/UsersApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    affiliate: affiliateReducer,
    report: reportReducer,
    modal: modalReducer,
    config: configReducer,
    ecommerce: ecommerceSlice,
    [authApi.reducerPath]: authApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [giftsApi.reducerPath]: giftsApi.reducer,
    [messagesApi.reducerPath]: messagesApi.reducer,
    [adminAccessApi.reducerPath]: adminAccessApi.reducer,
    [adminAccessMessagesApi.reducerPath]: adminAccessMessagesApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [sysAdminApi.reducerPath]: sysAdminApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware,
      commonApi.middleware,
      usersApi.middleware,
      contactsApi.middleware,
      giftsApi.middleware,
      messagesApi.middleware,
      adminAccessApi.middleware,
      adminAccessMessagesApi.middleware,
      paymentApi.middleware,
      sysAdminApi.middleware
    )
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
