import { Col, Row } from 'antd';
import imageLogoTextFull from 'assets/logo-text-full.svg';
import { ReactElement } from 'react';
import './AuthComponent.css';

type AllProps = {
  title: string;
  subtitle: string | ReactElement;
  children: ReactElement;
};
const mainWebsiteUrl = process.env.REACT_APP_MAIN_WEBSITE_URL;

function AuthComponent({ title, subtitle, children }: AllProps) {
  return (
    <Row align="middle" justify="center" className="auth-component">
      <Col xs={22} sm={20} md={14} lg={12} xl={10}>
        <div className="logo">
          <a href={mainWebsiteUrl ?? '/'}>
            <img src={imageLogoTextFull} alt="" />
          </a>
        </div>
        <div className="block">
          <h1>{title}</h1>
          {subtitle && <div className="subtitle">{subtitle}</div>}

          {children}
        </div>
      </Col>
    </Row>
  );
}

export default AuthComponent;
