import { ArrowRightOutlined } from '@ant-design/icons';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Button, Col, Divider, Flex, Form, Row } from 'antd';
import {
  PaymentPlanPrice,
  PreviewSubscriptionPayment,
  SetupIntent,
  useCreateSetupIntentMutation,
  useGetPaymentMethodsQuery,
  usePreviewSubscriptionPaymentQuery,
  useSubscriptionPaymentMutation
} from 'api/PaymentApi';
import { UserAccessLevelPeriodEnum, UserAccessLevelPlanEnum } from 'api/UsersApi';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import dayjs from 'dayjs';
import { useState } from 'react';
import { formatPrice, logging } from 'utils/utils';
import './SubscriptionCheckoutComponent.css';

type AllProps = {
  price: PaymentPlanPrice;
  onPurchaseDone?: () => void;
};

function SubscriptionCheckoutComponent({ price, onPurchaseDone }: AllProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { data: preview, isLoading: isLoadingPreview } = usePreviewSubscriptionPaymentQuery({
    priceId: price.stripePriceId
  });
  const { data: paymentMethods } = useGetPaymentMethodsQuery();
  const [createSetupIntent] = useCreateSetupIntentMutation();
  const [subscriptionPayment] = useSubscriptionPaymentMutation();

  const [form] = Form.useForm();

  const processPayment = async () => {
    setErrorMessage('');

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message || 'Unknown error');
      setIsLoading(false);
      return;
    }

    let paymentMethod = paymentMethods?.length ? paymentMethods[0].id : '';
    if (!paymentMethod) {
      let setupIntent: SetupIntent;
      try {
        setupIntent = await createSetupIntent({}).unwrap();
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        return;
      }

      const { setupIntent: setupIntentResult, error: setupIntentError } = await stripe.confirmSetup({
        elements,
        clientSecret: String(setupIntent.clientSecret),
        confirmParams: {
          return_url: window.location.href
        },
        redirect: 'if_required'
      });

      if (setupIntentError) {
        logging(setupIntentError);
        setErrorMessage(setupIntentError.message || 'Unknown error');
        return;
      }
      paymentMethod = String(setupIntentResult?.payment_method);
    }

    let paymentDone = false;
    try {
      paymentDone = await subscriptionPayment({
        priceId: price.stripePriceId,
        paymentMethod
      }).unwrap();
    } catch (e) {
      console.error('Payment Not Done');
      return;
    }

    if (!paymentDone) {
      // error
    }

    setIsLoading(false);

    // if (result) {
    //   dispatch(
    //     purchaseEvent({
    //       id: result.id,
    //       created: result.created,
    //       currency: result.currency,
    //       total: paymentDone.total,
    //       subtotal: paymentDone.subtotal,
    //       plan: paymentDone.plan,
    //       couponName: paymentDone.couponName,
    //       user
    //     })
    //   );
    // }

    onPurchaseDone && onPurchaseDone();
  };

  return (
    <div className="subscription-checkout">
      {errorMessage && <Alert className="error-message" message={errorMessage} type="error" showIcon />}

      <SpinComponent isLoading={isLoadingPreview}>
        {preview && (
          <>
            <Form onFinish={() => processPayment()} form={form}>
              {preview.isRenewing || preview.isInTrialPeriod || preview.action === 'upgrade' ? (
                <div className="product-info">
                  {!preview.isRenewing &&
                  (preview.fromPlan.accessLevel === UserAccessLevelPlanEnum.Free || preview.isInTrialPeriod) ? (
                    <Row>
                      <Col xs={12}>Free Trial</Col>
                      <Col xs={12} className="text-right">
                        {dayjs(preview.nextPaymentDate).add(1, 'days').diff(dayjs(), 'days')} Days
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {(preview.upgradeLines || []).map((line) => (
                        <Row key={line.description}>
                          <Col xs={12}>{line.description}</Col>
                          <Col xs={12} className="text-right">
                            {formatPrice(line.amount / 100)}
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}

                  <Divider />
                  <SubscriptionCheckoutPayableOnComponent preview={preview} />

                  {paymentMethods?.length === 0 && <PaymentElement />}

                  <Button
                    className="pay-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    loading={isLoading}
                  >
                    {!preview.isRenewing && preview.fromPlan.accessLevel === UserAccessLevelPlanEnum.Free
                      ? 'Start Free Trial'
                      : !preview.isRenewing && preview.isInTrialPeriod
                      ? 'Update Free Trial'
                      : preview.upgradeTotal
                      ? `Pay $${(preview.upgradeTotal / 100).toFixed(2)}`
                      : 'Pay'}
                  </Button>
                </div>
              ) : preview.action === 'downgrade' ? (
                <>
                  <h3>Downgrade Subscription</h3>
                  <SubscriptionCheckoutComparePlansComponent preview={preview} />
                  <SubscriptionCheckoutPayableOnComponent preview={preview} />

                  <Button
                    className="pay-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    loading={isLoading}
                  >
                    Downgrade
                  </Button>
                </>
              ) : preview.action === 'switch' ? (
                <>
                  <h3>Switch Subscription</h3>
                  <SubscriptionCheckoutComparePlansComponent preview={preview} />
                  <SubscriptionCheckoutPayableOnComponent preview={preview} />

                  <Button
                    className="pay-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    loading={isLoading}
                  >
                    {preview.toPlan.prices[0].period === UserAccessLevelPeriodEnum.Yearly
                      ? 'Switch to Annual'
                      : 'Switch to Monthly'}
                  </Button>
                </>
              ) : null}
            </Form>
          </>
        )}
      </SpinComponent>
    </div>
  );
}

function SubscriptionCheckoutComparePlansComponent({ preview }: { preview: PreviewSubscriptionPayment }) {
  return (
    <Flex justify="center" gap={40}>
      <div className="prev-sub">
        <div>
          <strong>{preview.fromPlan.name}</strong>
          <div>{preview.fromPlan.prices[0].period}</div>
        </div>
        <div>
          Until{' '}
          {preview.nextPaymentDate ? dayjs(preview.nextPaymentDate).subtract(1, 'day').format('MMM D, YYYY') : null}
        </div>
      </div>
      <ArrowRightOutlined />
      <div className="next-sub">
        <div>
          <strong>{preview.toPlan.name}</strong>
          <div>{preview.toPlan.prices[0].period}</div>
        </div>
        <div>Starting from {preview.nextPaymentDate ? dayjs(preview.nextPaymentDate).format('MMM D, YYYY') : null}</div>
      </div>
    </Flex>
  );
}

function SubscriptionCheckoutPayableOnComponent({ preview }: { preview: PreviewSubscriptionPayment }) {
  return (
    <>
      <Flex justify="space-between" className="main-block">
        <div>
          <div>Payable on {preview.nextPaymentDate ? dayjs(preview.nextPaymentDate).format('MMM D, YYYY') : null}</div>
          <div className="subtitle">
            {preview.toPlan.name} plan ({preview.toPlan.prices[0].period})
          </div>
        </div>
        <div className="price">{formatPrice(preview.toPlan.prices[0].amount / 100)}</div>
      </Flex>
      <div className="sub-block">
        Your subscription will be charged on the next billing cycle
        <span>We'll switch you onto your new plan after your current plan finishes</span>
      </div>
    </>
  );
}

export default SubscriptionCheckoutComponent;
