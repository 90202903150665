import { Button, Col, Row, Typography } from 'antd';
import { MessageTemplate, useGetMessageTemplatesQuery } from 'api/MessagesApi';
import { MessageTemplatesFilters } from 'components/Message/MessageTemplatesComponent/MessageTemplatesComponent';
import { PREVIEW_MESSAGE_TEMPLATE_MODAL_NAME } from 'components/Message/MessageTemplatesComponent/PreviewMessageTemplateModalComponent/PreviewMessageTemplateModalComponent';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './MessageTemplateExamplesComponent.css';

type AllProps = {
  filters: MessageTemplatesFilters;
};

function MessageTemplateExamplesComponent({ filters }: AllProps) {
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: messageTemplatesData, isLoading } = useGetMessageTemplatesQuery();

  useEffect(() => {
    const list = (messageTemplatesData?.messageTemplates || []).filter((messageTemplates) => {
      if (filters.eventType !== '' && filters.eventType !== messageTemplates.eventType) return false;
      if (
        messageTemplates.recipients.length > 0 &&
        filters.recipient !== '' &&
        !messageTemplates.recipients.includes(filters.recipient)
      )
        return false;
      return true;
    });
    setMessageTemplates(list);
  }, [filters, messageTemplatesData]);

  const createNewMessage = (messageTemplate: MessageTemplate) => {
    navigate('/message', { state: { messageType: messageTemplate.type, messageTemplate } });
  };

  const previewMessageTemplate = (messageTemplate: MessageTemplate) => {
    dispatch(
      openModal({
        name: PREVIEW_MESSAGE_TEMPLATE_MODAL_NAME,
        data: { messageTemplate }
      })
    );
  };

  return (
    <div className="message-templates-examples-component">
      <h3>Examples</h3>
      <SpinComponent isLoading={isLoading}>
        <Row gutter={[10, 10]}>
          {messageTemplates.map((messageTemplate, index) => (
            <Col xs={24} md={12} lg={8} key={index}>
              <div className="message-card">
                <div className="title">{messageTemplate.title}</div>
                <Typography.Paragraph
                  onClick={() => previewMessageTemplate(messageTemplate)}
                  className="content"
                  ellipsis={{
                    rows: 4,
                    expandable: false
                  }}
                >
                  {messageTemplate.content}
                </Typography.Paragraph>
                <Row justify="space-between">
                  <Col>
                    <Button
                      type="link"
                      className="preview-message"
                      onClick={() => previewMessageTemplate(messageTemplate)}
                    >
                      Preview
                    </Button>
                  </Col>
                  <Col>
                    <Button type="primary" className="create-message" onClick={() => createNewMessage(messageTemplate)}>
                      Create message
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
      </SpinComponent>
    </div>
  );
}

export default MessageTemplateExamplesComponent;
