import CreateGiftComponent from 'components/CreateGiftComponent/CreateGiftComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import './Gift.css';

export function GiftPage() {
  return (
    <div className="gift-page layout-full-height">
      <Header />

      <ContainerComponent className="layout-main-content">
        <CreateGiftComponent />
      </ContainerComponent>

      <Footer />
    </div>
  );
}
