import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

type AffiliateState = {
  affiliateCode: string;
};

const slice = createSlice({
  name: 'affiliate',
  initialState: {
    affiliateCode: localStorage.getItem('affiliateCode')
  } as AffiliateState,
  reducers: {
    setAffiliateCode(state, action: PayloadAction<string>) {
      localStorage.setItem('affiliateCode', action.payload);

      state.affiliateCode = action.payload;
    },
    removeAffiliateCode(state) {
      localStorage.removeItem('affiliateCode');

      state.affiliateCode = '';
    }
  }
});

export const { setAffiliateCode, removeAffiliateCode } = slice.actions;
export default slice.reducer;

export const selectAffiliate = (state: RootState) => state.affiliate;
export const selectAffiliateCode = (state: RootState) => selectAffiliate(state).affiliateCode;
