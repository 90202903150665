import { Alert, Modal } from 'antd';
import { SysAdminUser, useRemoveSysAdminUserMutation } from 'api/SysAdminApi';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './RemoveSysAdminUserModalComponent.css';

export const DELETE_SYS_ADMIN_USER_MODAL_NAME = 'DELETE_SYS_ADMIN_USER_MODAL_NAME';

type AllProps = {
  user: SysAdminUser;
};

function RemoveSysAdminUserModalComponent({ user }: AllProps) {
  const dispatch = useAppDispatch();
  const [removeSysAdminUser, { isLoading: isLoadingRemove }] = useRemoveSysAdminUserMutation();

  const handleRemove = async () => {
    await removeSysAdminUser({ id: user.id }).unwrap();
    handleCloseModal();
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title="Remove User"
      onOk={handleRemove}
      onCancel={handleCloseModal}
      okText="Remove"
      confirmLoading={isLoadingRemove}
      className="size-sm"
    >
      <div className="remove-sysadmin-user-modal-component">
        <Alert className="message" message="This action cannot be reverted!" type="error" showIcon />
        Do you want to remove <strong>{user.email}</strong>?
      </div>
    </Modal>
  );
}

function RemoveSysAdminUserModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const user = modalData?.user || null;

  return modalName === DELETE_SYS_ADMIN_USER_MODAL_NAME ? <RemoveSysAdminUserModalComponent user={user} /> : null;
}

export default RemoveSysAdminUserModalComponentWrapper;
