import { Form, Modal, Radio } from 'antd';
import { SysAdminUser, useUpdateUserSysAdminMutation } from 'api/SysAdminApi';
import { UserAccessLevelPlanEnum } from 'api/UsersApi';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { logging, validateForm } from 'utils/utils';
import './UpdateUserSysAdminModalComponent.css';

export const UPDATE_USER_SYS_ADMIN_MODAL_NAME = 'UPDATE_USER_SYS_ADMIN_MODAL_NAME';

type AllProps = {
  user: SysAdminUser;
};

function UpdateUserSysAdminModalComponent({ user }: AllProps) {
  const dispatch = useAppDispatch();
  const [updateUserSysAdmin, { isLoading: isLoadingUpdate }] = useUpdateUserSysAdminMutation();

  const [form] = Form.useForm();

  const handleUpdate = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      await updateUserSysAdmin({ userId: user.id, accessLevelPlan: fields.accessLevel }).unwrap();

      handleCloseModal();
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title="Update User"
      onOk={handleUpdate}
      onCancel={handleCloseModal}
      okText="Update"
      confirmLoading={isLoadingUpdate}
      className="size-sm"
    >
      <div className="create-sysadmin-payment-modal-component">
        <p>
          <strong>{user.email}</strong>
        </p>
        <Form
          name="basic"
          layout="vertical"
          autoComplete="off"
          requiredMark="optional"
          form={form}
          initialValues={{ accessLevel: user.accessLevelPlan }}
        >
          <Form.Item label="Access Level" name="accessLevel" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={UserAccessLevelPlanEnum.Free}>Free</Radio.Button>
              <Radio.Button value={UserAccessLevelPlanEnum.Premium}>Premium</Radio.Button>
              <Radio.Button value={UserAccessLevelPlanEnum.Unlimited}>Unlimited</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

function UpdateUserSysAdminModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const user = modalData?.user || null;

  return modalName === UPDATE_USER_SYS_ADMIN_MODAL_NAME ? <UpdateUserSysAdminModalComponent user={user} /> : null;
}

export default UpdateUserSysAdminModalComponentWrapper;
