import { createApi } from '@reduxjs/toolkit/query/react';
import { invalidateAdminTags } from 'api/AdminAccessApi';
import { baseQueryWithReAuth } from 'api/Api';
import { GetFileMessage, Message } from 'api/MessagesApi';
import { User, UserAccessLevelPlanEnum } from 'api/UsersApi';

export interface SysAdminReport {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  amountTotal: string;
}

export interface SysAdminPayment {
  id: string;
  planName: string;
  couponName: string;
  stripePaymentIntentId: string;
  stripeCreated: Date;
  stripeAmountTotal: string;
  user: User;
  affiliateUser: User;
}

export interface SysAdminGift {
  id: string;
  priceId: string;
  recipientName: string;
  recipientEmail: string;
  sendDate: string;
  senderEmail: string;
  senderName: string;
  senderText: string;
  createdAt: Date;
}

export interface SysAdminUser extends User {
  messagesCount: number;
  messagesPublishedCount: number;
  adminUsers: User[];
  userAdmins: User[];
}

export const sysAdminApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'sysAdminApi',
  tagTypes: [
    'SysAdminUsers',
    'SysAdminPayments',
    'SysAdminMessages',
    'SysAdminMessageFiles',
    'SysAdminReport',
    'SysAdminGifts'
  ],
  endpoints: (build) => ({
    getAllUsersSysAdmin: build.query<{ list: SysAdminUser[]; total: number }, { limit?: number; offset?: number }>({
      query: (params) => ({ url: '/sysadmin/users', params }),
      transformResponse: (response: { data: [SysAdminUser[], number] }) => ({
        list: response.data[0],
        total: response.data[1]
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.list.map(({ id }) => ({
                type: 'SysAdminUsers' as const,
                id
              })),
              { type: 'SysAdminUsers', id: 'LIST' }
            ]
          : [{ type: 'SysAdminUsers', id: 'LIST' }]
    }),
    getOneUserSysAdmin: build.query<SysAdminUser, Pick<SysAdminUser, 'id'>>({
      query: ({ id }) => ({ url: `/sysadmin/users/${id}` }),
      transformResponse: (response: { data: SysAdminUser }) => response.data,
      providesTags: (result, error, { id }) => [{ type: 'SysAdminUsers', id }]
    }),
    getAllPaymentsSysAdmin: build.query<
      { list: SysAdminPayment[]; total: number },
      { limit?: number; offset?: number; userId?: string; affiliateUserId?: string; fromDate?: string; toDate?: string }
    >({
      query: (params) => ({ url: '/sysadmin/payments', params }),
      transformResponse: (response: { data: [SysAdminPayment[], number] }) => ({
        list: response.data[0],
        total: response.data[1]
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.list.map(({ id }) => ({
                type: 'SysAdminPayments' as const,
                id
              })),
              { type: 'SysAdminPayments', id: 'LIST' }
            ]
          : [{ type: 'SysAdminPayments', id: 'LIST' }]
    }),
    getAllMessagesSysAdmin: build.query<
      { list: Message[]; total: number },
      { limit?: number; offset?: number; userId: string }
    >({
      query: (params) => ({ url: `/sysadmin/messages`, params }),
      transformResponse: (response: { data: [Message[], number] }) => ({
        list: response.data[0],
        total: response.data[1]
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.list.map(({ id }) => ({
                type: 'SysAdminMessages' as const,
                id
              })),
              { type: 'SysAdminMessages', id: 'LIST' }
            ]
          : [{ type: 'SysAdminMessages', id: 'LIST' }]
    }),
    getOneMessageSysAdmin: build.query<Message, Pick<Message, 'id'>>({
      query: ({ id }) => ({ url: `/sysadmin/messages/${id}` }),
      transformResponse: (response: { data: Message }) => response.data,
      providesTags: (result, error, { id }) => [{ type: 'SysAdminMessages', id }]
    }),
    getFileMessageSysAdmin: build.query<GetFileMessage, Pick<Message, 'id'>>({
      query: ({ id }) => ({ url: `/sysadmin/messages/${id}/file` }),
      transformResponse: (response: { data: GetFileMessage }) => response.data,
      providesTags: (result, error, { id }) => [{ type: 'SysAdminMessageFiles', id }]
    }),
    updateUserSysAdmin: build.mutation<boolean, { userId: string; accessLevelPlan: UserAccessLevelPlanEnum }>({
      query: ({ userId, ...body }) => ({
        url: `/sysadmin/users/${userId}`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { success: boolean }) => response.success,
      invalidatesTags: (result, error, { userId }) => [
        userId ? { type: 'SysAdminUsers', id: userId } : { type: 'SysAdminUsers', id: 'LIST' }
      ]
    }),
    updateUserAffiliateSysAdmin: build.mutation<boolean, { userId: string; affiliateCode: string }>({
      query: ({ userId, ...body }) => ({
        url: `/sysadmin/users/${userId}/affiliate`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { success: boolean }) => response.success,
      invalidatesTags: (result, error, { userId }) => [
        userId ? { type: 'SysAdminUsers', id: userId } : { type: 'SysAdminUsers', id: 'LIST' }
      ]
    }),
    // createPaymentSysAdmin: build.mutation<SysAdminPayment, { userId: string }>({
    //   query: (body) => ({
    //     url: '/sysadmin/payments',
    //     method: 'POST',
    //     body
    //   }),
    //   transformResponse: (response: { data: SysAdminPayment }) => response.data,
    //   invalidatesTags: () => [
    //     { type: 'SysAdminPayments', id: 'LIST' },
    //     { type: 'SysAdminUsers', id: 'LIST' }
    //   ],
    //   async onQueryStarted(_, { dispatch, queryFulfilled }) {
    //     try {
    //       await queryFulfilled;
    //       dispatch(invalidateAuthTags());
    //     } catch (e) {}
    //   }
    // }),
    removeSysAdminUser: build.mutation<boolean, Pick<SysAdminUser, 'id'>>({
      query: ({ id }) => ({
        url: `/sysadmin/users/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { data: { deleted: boolean } }) => response.data.deleted,
      invalidatesTags: () => [{ type: 'SysAdminUsers', id: 'LIST' }]
    }),
    getReportSysAdmin: build.query<
      { list: SysAdminReport[]; total: number },
      { limit?: number; offset?: number; fromDate: string; toDate: string; userId?: string }
    >({
      query: (params) => ({ url: '/sysadmin/report', params }),
      transformResponse: (response: { data: [SysAdminReport[], number] }) => ({
        list: response.data[0],
        total: response.data[1]
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.list.map(({ id }) => ({
                type: 'SysAdminReport' as const,
                id
              })),
              { type: 'SysAdminReport', id: 'LIST' }
            ]
          : [{ type: 'SysAdminReport', id: 'LIST' }]
    }),
    promoteToDeceasedSysAdmin: build.mutation<boolean, {}>({
      query: () => ({
        method: 'POST',
        url: `/sysadmin/promote/deceased`
      }),
      transformResponse: (response: { success: boolean }) => response.success,
      invalidatesTags: () => [{ type: 'SysAdminUsers', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(invalidateAdminTags());
        } catch (e) {}
      }
    }),
    deliveryMessagesSysAdmin: build.mutation<boolean, {}>({
      query: () => ({
        method: 'POST',
        url: `/sysadmin/delivery/deceased`
      }),
      transformResponse: (response: { success: boolean }) => response.success
    }),
    getAllGiftSysAdmin: build.query<{ list: SysAdminGift[]; total: number }, { limit?: number; offset?: number }>({
      query: (params) => ({ url: '/sysadmin/gifts', params }),
      transformResponse: (response: { data: [SysAdminGift[], number] }) => ({
        list: response.data[0],
        total: response.data[1]
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.list.map(({ id }) => ({
                type: 'SysAdminGifts' as const,
                id
              })),
              { type: 'SysAdminGifts', id: 'LIST' }
            ]
          : [{ type: 'SysAdminGifts', id: 'LIST' }]
    })
  })
});

export const {
  useGetAllMessagesSysAdminQuery,
  useGetOneMessageSysAdminQuery,
  useGetFileMessageSysAdminQuery,
  useGetAllUsersSysAdminQuery,
  useGetOneUserSysAdminQuery,
  useUpdateUserAffiliateSysAdminMutation,
  useGetAllPaymentsSysAdminQuery,
  useUpdateUserSysAdminMutation,
  useRemoveSysAdminUserMutation,
  useGetReportSysAdminQuery,
  useDeliveryMessagesSysAdminMutation,
  usePromoteToDeceasedSysAdminMutation,
  useGetAllGiftSysAdminQuery
} = sysAdminApi;
