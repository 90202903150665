import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

type ReportState = {
  fromDate: string | null;
  toDate: string | null;
};

const slice = createSlice({
  name: 'affiliate',
  initialState: {
    fromDate: null,
    toDate: null
  } as ReportState,
  reducers: {
    setReportFromDate(state, action: PayloadAction<string | null>) {
      state.fromDate = action.payload;
    },
    setReportToDate(state, action: PayloadAction<string | null>) {
      state.toDate = action.payload;
    }
  }
});

export const { setReportFromDate, setReportToDate } = slice.actions;
export default slice.reducer;

export const selectReport = (state: RootState) => state.report;
export const selectReportFromDate = (state: RootState) => selectReport(state).fromDate;
export const selectReportToDate = (state: RootState) => selectReport(state).toDate;
