import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectAuthToken } from 'store/authSlice';
import { getCurrentUrl } from 'utils/utils';

const PrivateRoutes = () => {
  const token = useSelector(selectAuthToken);

  return token ? <Outlet /> : <Navigate to="/login" state={{ redirectUrl: getCurrentUrl() }} />;
};

export default PrivateRoutes;
