import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'api/Api';

export const commonApi = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'commonApi',
  endpoints: (build) => ({
    contactUs: build.mutation<boolean, { message: string }>({
      query: (body) => ({
        url: '/common/contact-us',
        method: 'POST',
        body
      }),
      transformResponse: (response: { success: boolean }) => response.success
    })
  })
});

export const { useContactUsMutation } = commonApi;
