import { Alert, Form, Input, Modal, Select } from 'antd';
import { useCreateContactMutation, useEditContactMutation, useGetOneContactQuery } from 'api/ContactsApi';
import { CONTACT_RELATIONS_MAP } from 'components/Contact/ContactListComponent/ContactListComponent';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName, selectModalOnClose } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { logging, validateForm } from 'utils/utils';
import './UpsertContactModalComponent.css';

export const UPSERT_CONTACT_MODAL_NAME = 'UPSERT_CONTACT_MODAL_NAME';

type AllProps = {
  contactId: string | null;
  onClose?: () => void;
};

function UpsertContactModalComponent({ contactId, onClose }: AllProps) {
  const dispatch = useAppDispatch();
  const [createContact, { isLoading: isLoadingCreate, isError: isErrorCreate }] = useCreateContactMutation();
  const [editContact, { isLoading: isLoadingEdit, isError: isErrorEdit }] = useEditContactMutation();
  const isEditing = !!contactId;

  const [form] = Form.useForm();

  const { data: contact, isLoading } = useGetOneContactQuery({ id: contactId! }, { skip: !contactId });

  useEffect(() => {
    if (contact) {
      form.setFieldsValue(contact);
    }
  }, [contact]);

  const handleUpsert = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      if (isEditing) {
        await editContact({
          id: contactId,
          name: fields.name,
          email: fields.email,
          phone: fields.phone,
          relationship: fields.relationship
        }).unwrap();
      } else {
        await createContact({
          name: fields.name,
          email: fields.email,
          phone: fields.phone,
          relationship: fields.relationship
        }).unwrap();
      }

      onClose && onClose();
      handleCloseModal();
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={true}
      title={isEditing ? 'Edit Contact' : 'Add Contact'}
      onOk={handleUpsert}
      onCancel={handleCloseModal}
      okText={isEditing ? 'Update' : 'Create'}
      confirmLoading={isLoadingCreate || isLoadingEdit}
      className="size-sm"
    >
      <SpinComponent isLoading={isLoading}>
        <div className="upsert-contact-modal-component">
          <Form name="basic" layout="vertical" autoComplete="off" requiredMark="optional" form={form}>
            {isErrorCreate && <Alert className="message" message="Cannot create a contact" type="error" showIcon />}
            {isErrorEdit && <Alert className="message" message="Cannot update a contact" type="error" showIcon />}

            <Form.Item label="Full Name" name="name" rules={[{ required: true }]}>
              <Input size="large" placeholder="John Doe" />
            </Form.Item>

            <Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
              <Input size="large" placeholder="email@domain.com" />
            </Form.Item>

            <Form.Item label="Phone" name="phone">
              <Input size="large" placeholder="Recipient Phone" />
            </Form.Item>

            <Form.Item label="Relationship" name="relationship" rules={[{ required: true }]}>
              <Select
                options={Object.entries(CONTACT_RELATIONS_MAP).map(([value, label]) => ({
                  value,
                  label
                }))}
              ></Select>
            </Form.Item>
          </Form>
        </div>
      </SpinComponent>
    </Modal>
  );
}

function UpsertContactModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const modalOnClose = useSelector(selectModalOnClose);
  const contactId = modalData?.id || null;

  return modalName === UPSERT_CONTACT_MODAL_NAME ? (
    <UpsertContactModalComponent contactId={contactId} onClose={modalOnClose} />
  ) : null;
}

export default UpsertContactModalComponentWrapper;
