import { Col, Row, Typography } from 'antd';
import { useGetMessageTemplatesQuery } from 'api/MessagesApi';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import './MessageTemplateIdeasComponent.css';

function MessageTemplateIdeasComponent() {
  const { data: messageTemplatesData, isLoading } = useGetMessageTemplatesQuery();

  return (
    <div className="message-templates-ideas-component">
      <h3>Ideas</h3>
      <SpinComponent isLoading={isLoading}>
        <Row gutter={[10, 10]}>
          {messageTemplatesData?.messageIdeas.map((messageIdea, index) => (
            <Col xs={24} md={12} lg={8} key={index}>
              <div className="message-card">
                <div className="title">{messageIdea.title}</div>
                <Typography.Paragraph className="content">{messageIdea.content}</Typography.Paragraph>
              </div>
            </Col>
          ))}
        </Row>
      </SpinComponent>
    </div>
  );
}

export default MessageTemplateIdeasComponent;
