import { Spin } from 'antd';
import { ReactNode } from 'react';
import './SpinComponent.css';

type AllProps = {
  isLoading: boolean;
  children: ReactNode;
};

function SpinComponent({ isLoading, children }: AllProps) {
  return (
    <div className="spin-component">
      {isLoading ? (
        <div className="spin-block">
          <Spin size="large" />
        </div>
      ) : (
        children
      )}
    </div>
  );
}

export default SpinComponent;
