import { Alert, Button, Divider, Form, Input } from 'antd';
import { useLoginMutation } from 'api/AuthApi';
import AuthComponent from 'components/AuthComponent/AuthComponent';
import SocialLogin from 'pages/Auth/SocialLogin/SocialLogin';
import { Link, useSearchParams } from 'react-router-dom';
import { logging, validateForm } from 'utils/utils';
import './Login.css';

function Login() {
  const [searchParams] = useSearchParams();
  const [login, { isError, error: errorLogin, isLoading }] = useLoginMutation();
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      await login({
        email: fields.email,
        password: fields.password
      }).unwrap();
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  return (
    <AuthComponent
      title="Sign In"
      subtitle={
        <>
          Don't have an account? <Link to="/signup">Register Here</Link>
        </>
      }
    >
      <div className="login">
        <Form
          name="basic"
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{
            email: searchParams.get('email')
          }}
          autoComplete="off"
          requiredMark="optional"
          form={form}
        >
          {isError && <Alert className="message" message={(errorLogin as any)?.data.message} type="error" showIcon />}
          {searchParams.get('confirm') && (
            <Alert message="Your email was confirmed. Please login now." type="success" showIcon />
          )}
          {searchParams.get('reset') && (
            <Alert message="Your password was reset. Please login now." type="success" showIcon />
          )}

          <Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
            <Input size="large" placeholder="email@domain.com" />
          </Form.Item>

          <Form.Item label="Password" name="password" rules={[{ required: true }]}>
            <Input.Password size="large" />
          </Form.Item>

          <div className="forgot-password">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>

          <Form.Item>
            <Button block type="primary" htmlType="submit" className="submit-button" loading={isLoading}>
              Sign In
            </Button>
          </Form.Item>

          <Divider plain>or</Divider>

          <SocialLogin />
        </Form>
      </div>
    </AuthComponent>
  );
}

export default Login;
