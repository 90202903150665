import { Button, Space } from 'antd';
import { useDeliveryMessagesSysAdminMutation, usePromoteToDeceasedSysAdminMutation } from 'api/SysAdminApi';

export function QCActions() {
  const [handlePromoteToDeceased, { isLoading: isLoadingDeceased }] = usePromoteToDeceasedSysAdminMutation();
  const [handleDeliveryMessages, { isLoading: isLoadingMessages }] = useDeliveryMessagesSysAdminMutation();

  return (
    <>
      <h3>QC actions</h3>
      <Space>
        <Button type="primary" loading={isLoadingDeceased} onClick={() => handlePromoteToDeceased({})}>
          Move To Deceased
        </Button>

        <Button type="primary" loading={isLoadingMessages} onClick={() => handleDeliveryMessages({})}>
          Messages Delivery
        </Button>
      </Space>
    </>
  );
}
