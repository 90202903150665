import { Divider, Modal, Typography } from 'antd';
import { MessageTypeEnum } from 'api/MessagesApi';
import { useGetFileMessageSysAdminQuery, useGetOneMessageSysAdminQuery } from 'api/SysAdminApi';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { TextToHtmlComponent } from 'components/UiKit/TextToHtmlComponent/TextToHtmlComponent';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { isSupportedAudioFile, isSupportedVideoFile } from 'utils/utils';
import './SysAdminPreviewMessageModalComponent.css';

export const SYSADMIN_PREVIEW_MESSAGE_MODAL_NAME = 'SYSADMIN_PREVIEW_MESSAGE_MODAL_NAME';

type AllProps = {
  messageId: string;
};

function SysAdminPreviewMessageModalComponent({ messageId }: AllProps) {
  const dispatch = useAppDispatch();

  const { data: message, isLoading } = useGetOneMessageSysAdminQuery({ id: messageId });
  const { data: messageFile, isLoading: isLoadingFile } = useGetFileMessageSysAdminQuery({ id: messageId });

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal open={true} title="Preview File" onOk={handleCloseModal} onCancel={handleCloseModal}>
      <SpinComponent isLoading={isLoading || isLoadingFile}>
        <div className="sysadmin-preview-message-modal-component">
          {message && (
            <>
              <p>
                Message title: <strong>{message.name}</strong>
              </p>

              <div className="message-content">
                {message.fileName ? (
                  <>
                    {message.type === MessageTypeEnum.Video && isSupportedVideoFile(message.fileName) ? (
                      <video src={messageFile?.signedUrl} controls playsInline />
                    ) : message.type === MessageTypeEnum.Audio && isSupportedAudioFile(message.fileName) ? (
                      <>
                        <audio src={messageFile?.signedUrl} controls />
                      </>
                    ) : message.fileName ? (
                      <>
                        File: <strong>{message.fileName}</strong>
                      </>
                    ) : null}
                  </>
                ) : message.type === MessageTypeEnum.Text ? (
                  <TextToHtmlComponent text={message.content} />
                ) : null}
              </div>

              {messageFile?.signedUrl && (
                <p>
                  Click{' '}
                  <a href={messageFile.signedUrl} target="_blank" rel="noreferrer">
                    here
                  </a>{' '}
                  to download
                </p>
              )}

              <Divider />

              <div>
                <h4>Contacts</h4>
                {message.contacts &&
                  message.contacts.map((contact) => (
                    <div key={contact.id} className="contact-content">
                      <Typography.Text>
                        <div>Name: {contact.name}</div>
                        <div>Email: {contact.email}</div>
                        <div>Phone: {contact.phone}</div>
                        <div>Relationship: {contact.relationship}</div>
                      </Typography.Text>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </SpinComponent>
    </Modal>
  );
}

function SysAdminPreviewMessageModalComponentWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const messageId = modalData?.id || null;

  return modalName === SYSADMIN_PREVIEW_MESSAGE_MODAL_NAME ? (
    <SysAdminPreviewMessageModalComponent messageId={messageId} />
  ) : null;
}

export default SysAdminPreviewMessageModalComponentWrapper;
