import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SysAdminGift, useGetAllGiftSysAdminQuery } from 'api/SysAdminApi';
import dayjs from 'dayjs';
import { useState } from 'react';
import './SysAdminGiftListComponent.css';

const PAGE_SIZE = 10;

function SysAdminGiftListComponent() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: sysAdminGiftListData, isFetching } = useGetAllGiftSysAdminQuery({
    limit: PAGE_SIZE,
    offset: (currentPage - 1) * PAGE_SIZE
  });

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const columns: ColumnsType<SysAdminGift> = [
    {
      title: 'Recipient Name',
      key: 'recipientName',
      dataIndex: 'recipientName'
    },
    {
      title: 'Recipient Email',
      key: 'recipientEmail',
      dataIndex: 'recipientEmail'
    },
    {
      title: 'Send Date',
      key: 'sendDate',
      dataIndex: 'sendDate'
    },
    {
      title: 'Sender Email',
      key: 'senderEmail',
      dataIndex: 'senderEmail'
    },
    {
      title: 'Sender Name',
      key: 'senderName',
      dataIndex: 'senderName'
    },
    {
      title: 'Text',
      key: 'senderText',
      dataIndex: 'senderText',
      ellipsis: true
    },
    {
      title: 'Price',
      key: 'priceId',
      dataIndex: 'priceId'
    },
    {
      title: 'Created',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (createdAt) => dayjs(createdAt).format('YYYY-MM-DD hh:mm')
    }
  ];

  return (
    <>
      <div className="sys-admin-gift-list-component">
        <h3>Gifts ({sysAdminGiftListData?.total || 0})</h3>

        <Table
          columns={columns}
          dataSource={sysAdminGiftListData?.list || []}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            current: currentPage,
            total: sysAdminGiftListData?.total || 0
          }}
          onChange={onTableChanged}
          rowKey="id"
          bordered
          loading={isFetching}
        />
      </div>
    </>
  );
}

export default SysAdminGiftListComponent;
