import { CaretDownOutlined } from '@ant-design/icons';
import { Collapse, CollapseProps } from 'antd';
import './FaqComponent.css';

function FaqComponent() {
  const productItems: CollapseProps['items'] = [
    {
      label: 'How does Eternal Applications know when a user has passed?',
      children: (
        <div>
          Eternal Applications uses an Administrator system to notify us when you have passed in order to "queue" up
          your messages. Your administrator can be anyone. Users typically rely on spouses, children or close family
          members.
        </div>
      )
    },
    {
      label: 'How does Eternal Applications know when to send my messages?',
      children: (
        <div>
          During the message creation phase, users will be prompted to fill in the date they would like the message
          delivered. We understand however that the delivery date is often unknown, which is why we offer a variety of
          life events to help narrow what date information is required. For example, a "Final Message" requires no date
          information since the message will be delivered after your passing. On the otherhand, if you wish to leave a
          "Birthday Message" to be delivered on your grandchild's 18th birthday, you would have the option to fill in
          the month, day and year that will occur.
        </div>
      )
    },
    {
      label: 'What if I want to cancel a message or make edits?',
      children: (
        <div>
          The Eternal platform was specifically designed to be as user-friendly and as flexible as possible. We
          understand that your reflections change and relationships develop. At any point, and as often as you desire,
          you can edit your message, change the date of receipt, the recipient, the administrator, or even delete the
          whole thing and start from scratch.
        </div>
      )
    },
    {
      label: 'How does delivery of my message work?',
      children: (
        <div>
          In creating your message you will designate the recipient of the message and their contact information. When
          the date of delivery arrives, Eternal Applications will send the recipient a link to a secure page where the
          user will be able to read, view or listen to your message. They will also have the opportunity to download the
          message and save it to their phone, computer or tablet, keeping your message close to heart forever.
        </div>
      )
    },
    {
      label: 'Are there any hidden fees or storage costs?',
      children: (
        <div>
          Absolutely not. There are no hidden fees or additional charges of any sort. Eternal Applications strives to
          make the purchasing process as quick and painless as possible.
        </div>
      )
    }
  ];

  const securityItems: CollapseProps['items'] = [
    {
      label: 'How is my data protected?',
      children: (
        <div>
          Eternal Applications takes data protection and privacy very seriously. We employ a comprehensive approach to
          safeguard your data, ensuring it remains confidential and secure. Here are some key measures we have in place
          to protect your data: (1) Data Encryption: We use advanced encryption techniques to protect data both in
          transit and at rest. This means that any information transferred between your device and our servers, as well
          as the data stored on our servers, is encrypted to prevent unauthorized access. (2) Data Minimization: We
          collect and retain only the data that is necessary for the intended purpose. Unnecessary data is promptly
          deleted, reducing the risk of data exposure. (3) Privacy Policies: We have clear and transparent privacy
          policies in place that explain how we collect, use, and protect your data. These policies are easily
          accessible for your reference.
        </div>
      )
    },
    {
      label: 'Does Eternal sell any of my data?',
      children: (
        <div>
          No, we do not engage in selling any of your personal data. Your information is kept confidential and used only
          for the purposes outlined in our privacy policy, which prioritizes safeguarding your data integrity. We are
          committed to maintaining trust and transparency with our users, ensuring that your information remains
          protected at all times.
        </div>
      )
    },
    {
      label: 'Who will have access to view my message?',
      children: (
        <div>
          No one will have access to your messages other than yourself and the recipient of your message. Terms and
          Conditions apply.
        </div>
      )
    },
    {
      label: 'How can I delete my account and all associated date from the platform?',
      children: (
        <div>
          To request the deletion of your account and all associated data, please contact our support team by sending an
          email to <a href="mailto:info@eternalapplications.com">info@eternalapplications.com</a> with the subject line
          "Account Deletion Request." Make sure to provide the email address associated with your account for
          verification purposes.
        </div>
      )
    },
    {
      label: 'Is my credit card information secure?',
      children: (
        <div>
          Eternal Applications partners with Stripe to offer a comprehensive and secure payment solution. Stripe is a
          trusted partnered to many major companies and institutions such as Google, Amazon, Lyft and More!
        </div>
      )
    }
  ];

  const legalItems: CollapseProps['items'] = [
    {
      label: 'Where can I find your terms and conditions?',
      children: (
        <div>
          You can find our terms and conditions in the footer of our website. Additionally, you can access them directly
          via the following links:{' '}
          <a href="https://www.eternalapplications.com/privacy" target="_blank">
            Privacy Policy
          </a>{' '}
          OR{' '}
          <a href="https://www.eternalapplications.com/terms-of-service" target="_blank">
            Terms of Service
          </a>
          . These documents outline the terms of use and our commitment to protecting your privacy and data security. If
          you have any questions or concerns regarding our terms and conditions, please don't hesitate to contact us.
        </div>
      )
    },
    {
      label: 'Is my message legally binding?',
      children: (
        <div>
          Messages are not legally binding and do not supersede any legal document of trust or inheritance of any kind
          unless specifically detailed by your Estate Planning attorney. Users may use the platform to convey last
          wishes, understanding they hold no legal grounds. All messages must also comply with all applicable laws and
          regulations of your given country/state/region, etc.
        </div>
      )
    },
    {
      label: 'What type of content is not allowed on the site?',
      children: (
        <div>
          At Eternal Applications, we strive to maintain a safe and respectful online environment for all users.
          Therefore, we prohibit certain types of content that may be harmful, offensive, or violate our community
          guidelines. Content that is not allowed on our site includes, but is not limited to:
          <ol>
            <li>Illegal or unlawful activities.</li>
            <li>
              Content that promotes hate speech, discrimination, or violence against individuals or groups based on
              race, ethnicity, religion, gender, sexual orientation, disability, or any other characteristic.
            </li>
            <li>Any form of harassment, bullying, or intimidation.</li>
            <li>Inappropriate or explicit content, including nudity, pornography, or sexually explicit material.</li>
            <li>
              Content that infringes upon the intellectual property rights of others, including copyright or trademark
              violations.
            </li>
            <li>
              Spam, scams, or deceptive content.Any material that may pose a security risk or harm to our users or the
              integrity of our platform.
            </li>
          </ol>
          We reserve the right to remove any content that violates these guidelines and take appropriate action,
          including suspension or termination of user accounts. Our priority is to foster a positive and respectful
          online community where users can engage safely and responsibly. If you come across any content that you
          believe violates our guidelines, please report it to{' '}
          <a href="mailto:info@eternalapplications.com">info@eternalapplications.com</a> for review.
        </div>
      )
    }
  ];

  return (
    <div className="faq-component">
      <h3>Product</h3>
      <Collapse
        expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} />}
        expandIconPosition="end"
        items={productItems}
      />

      <h3>Security and Privacy</h3>
      <Collapse
        expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} />}
        expandIconPosition="end"
        items={securityItems}
      />

      <h3>Legal</h3>
      <Collapse
        expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} />}
        expandIconPosition="end"
        items={legalItems}
      />
    </div>
  );
}

export default FaqComponent;
