import { ArrowLeftOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Col, Flex, Radio, Row, Space } from 'antd';
import { authApi } from 'api/AuthApi';
import { PaymentPlan, PaymentPlanPrice, useGetPaymentPlansQuery } from 'api/PaymentApi';
import { UserAccessLevelPeriodEnum, UserAccessLevelPlanEnum } from 'api/UsersApi';
import confetti from 'canvas-confetti';
import classNames from 'classnames';
import CheckoutFormComponent from 'components/Account/PricingComponent/CheckoutFormComponent/CheckoutFormComponent';
import { CreateMessageStepsEnum } from 'components/Message/CreateMessageComponent/CreateMessageComponent';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { selectAuthUser } from 'store/authSlice';
import { useAppDispatch } from 'store/store';
import { repeatedCall } from 'utils/utils';
import './PricingComponent.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

function PricingComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const messageId = location.state?.messageId;

  const user = useSelector(selectAuthUser);
  const [period, setPeriod] = useState<string>('monthly');
  const [price, setPrice] = useState<PaymentPlanPrice>();
  const [isPurchaseDone, setIsPurchaseDone] = useState(false);

  const { data: planList, isLoading } = useGetPaymentPlansQuery();

  useEffect(() => {
    if (user && user.accessLevelPeriod !== UserAccessLevelPeriodEnum.Init) {
      setPeriod(user.accessLevelPeriod);
    }
  }, [user]);

  useEffect(() => {
    if (isPurchaseDone) {
      confetti({
        particleCount: 150,
        spread: 60,
        zIndex: 99999
      });
      repeatedCall(() => {
        dispatch(authApi.endpoints?.me.initiate(undefined, { forceRefetch: true }));
      });
    }
  }, [isPurchaseDone]);

  return (
    <div className="pricing-component">
      {price ? (
        <>
          {isPurchaseDone ? (
            <div className="text-center">
              <h2>Congratulations!</h2>

              <div className="got-credits">
                <p>Thank you for your purchase!</p>
                <p>Your legacy will now be preserved and cherished for generations to come.</p>
              </div>

              <Space size="large">
                <Button
                  size="large"
                  onClick={() => {
                    setIsPurchaseDone(false);
                    setPrice(undefined);
                  }}
                >
                  Back
                </Button>
                {messageId ? (
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      navigate(`/message/${messageId}`, { state: { step: CreateMessageStepsEnum.Review } });
                    }}
                  >
                    Return to Message
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      navigate(`/`);
                    }}
                  >
                    Create Messages
                  </Button>
                )}
              </Space>
            </div>
          ) : (
            <>
              <h2>
                <ArrowLeftOutlined onClick={() => setPrice(undefined)} /> Payment
              </h2>

              <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd', loader: 'auto' }}>
                <CheckoutFormComponent price={price} onPurchaseDone={() => setIsPurchaseDone(true)} />
              </Elements>
            </>
          )}
        </>
      ) : (
        <>
          <h2>Pricing</h2>

          <SpinComponent isLoading={isLoading}>
            {user?.accessLevelPeriod !== UserAccessLevelPeriodEnum.OneTime && (
              <div className="text-center">
                <Radio.Group value={period} buttonStyle="solid" onChange={(e) => setPeriod(e.target.value)}>
                  <Radio.Button value="monthly">Monthly</Radio.Button>
                  <Radio.Button value="yearly">Yearly</Radio.Button>
                  <Radio.Button value="one-time">One Time</Radio.Button>
                </Radio.Group>
              </div>
            )}

            <Row className="payment-plans" gutter={[10, 30]} justify="center">
              {planList?.map((plan) => (
                <Col xs={20} md={8} lg={8} xl={8} key={plan.name}>
                  <PaymentPlanComponent plan={plan} period={period} onSelect={(price) => setPrice(price)} />
                </Col>
              ))}
            </Row>
            <div className="contact-us">
              Have any questions? <Link to="/contact-us">Contact Us!</Link>
            </div>
          </SpinComponent>
        </>
      )}
    </div>
  );
}

function PaymentPlanComponent({
  plan,
  period,
  onSelect
}: {
  plan: PaymentPlan;
  period: string;
  onSelect: (price: PaymentPlanPrice) => void;
}) {
  const user = useSelector(selectAuthUser);

  const price = plan.prices.find((price) => price.period === period);
  const periodText: string = period === 'monthly' ? '/ month' : period === 'yearly' ? '/ year' : '/ one-time';

  return price ? (
    <div className={classNames('payment-plan-item', { popular: !!plan.isPopular })}>
      <Flex vertical={true} className="plan-block" gap={5}>
        <div>
          <div className="title">{plan.name}</div>
          <div className="price-block">
            <div className="price">
              {price.amount ? (
                <>
                  ${price.amount / 100} <span>{periodText}</span>
                </>
              ) : (
                <>Free</>
              )}
            </div>
          </div>
        </div>
        {plan.highlights && (
          <div className="highlights">
            <ul>
              {plan.highlights.map((highlight, index) => (
                <li key={index} className={classNames({ disabled: highlight.disabled })}>
                  <Flex gap={10}>
                    <div>{highlight.disabled ? <CloseOutlined /> : <CheckOutlined className="tick-icon" />}</div>
                    <div dangerouslySetInnerHTML={{ __html: highlight.text }} />
                  </Flex>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="button-block">
          {plan.accessLevel === UserAccessLevelPlanEnum.Free ? (
            <>{user?.accessLevelPlan === plan.accessLevel && <div className="selected">Current Plan</div>}</>
          ) : period === UserAccessLevelPeriodEnum.OneTime ? (
            <>
              {user?.accessLevelPlan === plan.accessLevel &&
              user?.accessLevelPeriod === UserAccessLevelPeriodEnum.OneTime ? (
                <div className="selected">Current Plan</div>
              ) : user?.accessLevelPlan === UserAccessLevelPlanEnum.Unlimited &&
                user?.accessLevelPeriod === UserAccessLevelPeriodEnum.OneTime &&
                plan.accessLevel === UserAccessLevelPlanEnum.Premium ? null : (
                <Button type="primary" onClick={() => onSelect(price)} block shape="round">
                  {user?.accessLevelPeriod === UserAccessLevelPeriodEnum.OneTime ? 'Upgrade' : 'Buy Now'}
                </Button>
              )}
            </>
          ) : (
            <>
              {user?.accessLevelPlan === plan.accessLevel ? (
                <>
                  {user?.accessLevelPeriod === period ? (
                    <div className="selected">Current Plan</div>
                  ) : (
                    <Button onClick={() => onSelect(price)} block shape="round">
                      {period === UserAccessLevelPeriodEnum.Monthly ? 'Switch to Monthly' : 'Switch to Annual'}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button
                    type={user?.accessLevelPlan === UserAccessLevelPlanEnum.Unlimited ? 'default' : 'primary'}
                    onClick={() => onSelect(price)}
                    block
                    shape="round"
                  >
                    {user?.accessLevelPlan === UserAccessLevelPlanEnum.Unlimited
                      ? 'Downgrade Plan'
                      : user?.accessLevelPlan === UserAccessLevelPlanEnum.Free
                      ? 'Start Free Trial'
                      : 'Upgrade Plan'}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </Flex>
      {plan.isPopular && <div className="label-text">Recommended</div>}
    </div>
  ) : null;
}

export default PricingComponent;
