import { ArrowLeftOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Flex, Row, Tooltip } from 'antd';
import imageVideoPause from 'assets/video-pause-button.svg';
import imageVideoResume from 'assets/video-resume-button.svg';
import imageVideoRetake from 'assets/video-retake-button.svg';
import RecordingRecorderComponent from 'components/Message/CreateMessageComponent/StepMessageComponent/RecordingRecorderComponent/RecordingRecorderComponent';
import { useReactMediaRecorder } from 'components/Tool/ReactMediaRecorder/ReactMediaRecorder';
import SpinComponent from 'components/Tool/SpinComponent/SpinComponent';
import { forwardRef, ReactNode, useImperativeHandle, useRef, useState } from 'react';
import { useCountDown } from 'utils/useCountDown';
import './VideoRecorderComponent.css';

const MAX_RECORD_TIME = 120;

type AllProps = {
  onDone: (file: File) => void;
  onCancel: () => void;
};

function VideoRecorderComponent({ onDone, retake, onCancel }: AllProps & { retake: () => void }) {
  const refStart = useRef<VideoRecorderCountDownHandle>(null);
  const refResume = useRef<VideoRecorderCountDownHandle>(null);
  const refStop = useRef({ isStopped: false });

  const { status, error, startRecording, stopRecording, pauseRecording, resumeRecording, previewStream } =
    useReactMediaRecorder({
      video: true,
      audio: true,
      askPermissionOnMount: true,
      onStop: (blobUrl: string, blob: Blob) => {
        if (refStop.current.isStopped) {
          onDone(new File([blob], `${new Date().getTime()}.mp4`, { type: 'video/mp4' }));
        }
      }
    });

  const stopVideoRecording = () => {
    refStop.current.isStopped = true;
    stopRecording();
  };

  return (
    <div className="video-recorder-component create-message-component-full-height">
      <SpinComponent isLoading={status === 'acquiring_media'}>
        {error ? (
          <>
            <Alert className="error-message" message={error} type="error" showIcon />
            <Button onClick={retake}>Try Again</Button>
          </>
        ) : (
          <>
            <>
              <div className="video-overlay">
                <video
                  width="100%"
                  ref={(video) => {
                    if (video) {
                      video.srcObject = previewStream;
                    }
                  }}
                  onClick={pauseRecording}
                  autoPlay
                  loop
                  playsInline
                />
                {status === 'idle' && (
                  <VideoRecorderCountDownComponent onComplete={startRecording} ref={refStart}>
                    <PlayCircleOutlined className="icon" />
                    <p>Click to start recording</p>
                  </VideoRecorderCountDownComponent>
                )}
                {status === 'paused' && (
                  <VideoRecorderCountDownComponent onComplete={resumeRecording} ref={refResume}>
                    <PauseCircleOutlined className="icon" />
                    <p>Click to continue recording</p>
                  </VideoRecorderCountDownComponent>
                )}
                <RecordingRecorderComponent status={status} maxTime={MAX_RECORD_TIME} onTimeEnds={stopVideoRecording} />
              </div>

              <Flex gap={30} className="video-buttons" justify="center">
                <Tooltip title="Retake">
                  <img src={imageVideoRetake} alt="Retake" onClick={retake} />
                </Tooltip>

                {status === 'recording' ? (
                  <Tooltip title="Pause / Stop">
                    <img src={imageVideoPause} alt="Pause" onClick={pauseRecording} />
                  </Tooltip>
                ) : (
                  <Tooltip title={status === 'idle' ? 'Start Recording' : 'Continue Recording'}>
                    <img
                      src={imageVideoResume}
                      alt="Play"
                      onClick={() =>
                        status === 'idle' ? refStart.current?.delayedCall() : refResume.current?.delayedCall()
                      }
                    />
                  </Tooltip>
                )}
              </Flex>
            </>
          </>
        )}
      </SpinComponent>

      <Row className="create-message-component-footer">
        <Col span={12}>
          <Button onClick={onCancel} type="link">
            <ArrowLeftOutlined /> Back
          </Button>
        </Col>

        <Col span={12} className="text-right">
          <Button
            type="primary"
            htmlType="submit"
            disabled={status !== 'paused' && status !== 'recording'}
            onClick={stopVideoRecording}
          >
            Preview
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function VideoRecorderComponentWrapper({ onDone, onCancel }: AllProps) {
  const [retakeCount, setRetakeCount] = useState(1);

  return (
    <VideoRecorderComponent
      key={retakeCount}
      onDone={onDone}
      onCancel={onCancel}
      retake={() => setRetakeCount((count) => count + 1)}
    />
  );
}

export default VideoRecorderComponentWrapper;

type VideoRecorderCountDownHandle = {
  delayedCall: () => void;
};

const VideoRecorderCountDownComponent = forwardRef<
  VideoRecorderCountDownHandle,
  { children: ReactNode; onComplete: () => void }
>(({ children, onComplete }, ref) => {
  const { timerCountDown, isCountDownStarted, startCountDown } = useCountDown();

  useImperativeHandle(
    ref,
    () => ({
      delayedCall() {
        startCountDown(3, () => onComplete());
      }
    }),
    []
  );

  return (
    <div className="layer" onClick={() => startCountDown(3, () => onComplete())}>
      {isCountDownStarted ? <div className="countdown-number">{timerCountDown || ' '}</div> : children}
    </div>
  );
});
