import { Alert, Button, Form, Input } from 'antd';
import { useForgotPasswordMutation } from 'api/AuthApi';
import AuthComponent from 'components/AuthComponent/AuthComponent';
import { Link, useNavigate } from 'react-router-dom';
import { logging, validateForm } from 'utils/utils';
import './ForgotPassword.css';

function ForgotPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [forgotPassword, { isError, isLoading }] = useForgotPasswordMutation();

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      await forgotPassword({ email: fields.email }).unwrap();

      form.setFieldValue('email', '');

      navigate(`/reset-password?email=${encodeURIComponent(fields.email)}`);
    } catch (e) {
      logging(e);
      validateForm(form, e);
    }
  };

  return (
    <AuthComponent
      title="Forgot Password"
      subtitle={
        <>
          Already have an account? <Link to="/login">Login Here</Link>
        </>
      }
    >
      <div className="forgot-password">
        <Form name="basic" layout="vertical" onFinish={onSubmit} autoComplete="off" requiredMark="optional" form={form}>
          {isError && <Alert className="message" message="Email not found" type="error" showIcon />}

          <Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email' }]}>
            <Input size="large" placeholder="email@domain.com" />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthComponent>
  );
}

export default ForgotPassword;
