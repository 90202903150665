import { Breakpoint, Table, TableProps } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { ColumnsType } from 'antd/es/table';
import { AnyObject } from 'antd/es/_util/type';
import classNames from 'classnames';
import './ResponsiveTable.css';

export function ResponsiveTable<RecordType extends AnyObject = AnyObject>(
  props: TableProps<RecordType> & {
    mobileUntilBreakpoint: Breakpoint;
    mobileColumns: ColumnsType<RecordType>;
  }
) {
  const breakpoint = useBreakpoint();
  const isMobile = !!props.mobileUntilBreakpoint && !breakpoint[props.mobileUntilBreakpoint || ''];

  const className = classNames(props.className, 'responsive-table', {
    mobile: isMobile
  });

  return isMobile ? (
    <Table showHeader={false} {...props} columns={props.mobileColumns} bordered={false} className={className} />
  ) : (
    <Table {...props} className={className} />
  );
}
