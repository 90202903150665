import SysAdminUserListComponent from 'components/SysAdmin/SysAdminUserListComponent/SysAdminUserListComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import SysAdminMenu, { SysAdminMenuEnum } from 'pages/SysAdmin/SysAdminMenu/SysAdminMenu';
import { QCActions } from './QCActions';
import './Users.css';

function Users() {
  return (
    <>
      <div className="sys-admin-users layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <>
            <SysAdminMenu selectedMenu={SysAdminMenuEnum.USERS} />

            <QCActions />
            <SysAdminUserListComponent />
          </>
        </ContainerComponent>

        <Footer />
      </div>
    </>
  );
}

export default Users;
