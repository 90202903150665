import {
  AudioOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  VideoCameraOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Flex, Space, Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Contact } from 'api/ContactsApi';
import { Message, MessageStatusEnum, MessageTypeEnum, useGetAllMessagesQuery } from 'api/MessagesApi';
import imagePreview from 'assets/preview.svg';
import { CreateMessageStepsEnum } from 'components/Message/CreateMessageComponent/CreateMessageComponent';
import PreviewMessageModalComponent, {
  PREVIEW_MESSAGE_MODAL_NAME
} from 'components/Message/PreviewMessageModalComponent/PreviewMessageModalComponent';
import RemoveMessageModalComponent, {
  DELETE_MESSAGE_MODAL_NAME
} from 'components/Message/RemoveMessageModalComponent/RemoveMessageModalComponent';
import { ResponsiveTable } from 'components/UiKit/ResponsiveTable/ResponsiveTable';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import { displayDeliveryDate } from 'utils/utils';
import './MessageListComponent.css';

const PAGE_SIZE = 10;

function MessageListComponent() {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: draftMessagesData, isLoading: isLoadingDraft } = useGetAllMessagesQuery({
    status: MessageStatusEnum.Draft,
    limit: 1
  });

  const { data: messagesData, isFetching } = useGetAllMessagesQuery({
    limit: PAGE_SIZE,
    offset: (currentPage - 1) * PAGE_SIZE
  });

  const onTableChanged = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
  };

  const previewMessage = (id: string) => {
    dispatch(openModal({ name: PREVIEW_MESSAGE_MODAL_NAME, data: { id } }));
  };

  const deleteMessage = (id: string) => {
    dispatch(openModal({ name: DELETE_MESSAGE_MODAL_NAME, data: { id } }));
  };

  const renderPreviewIcon = (message: Message) => {
    const hasPreview = message.type === MessageTypeEnum.Text || message.fileName !== '';

    return (
      hasPreview && (
        <Tooltip title="Preview">
          <img src={imagePreview} alt="preview" onClick={() => previewMessage(message.id)} className="cursor-pointer" />
        </Tooltip>
      )
    );
  };

  const renderTypeIcon = (type: MessageTypeEnum) => {
    if (type === MessageTypeEnum.Video) {
      return <VideoCameraOutlined />;
    } else if (type === MessageTypeEnum.Audio) {
      return <AudioOutlined />;
    } else {
      return <FormOutlined />;
    }
  };

  const renderContacts = (contacts: Contact[]) => {
    return (
      contacts &&
      contacts.map((contact) => (
        <div key={contact.id}>
          <Typography.Text>
            {contact.name} ({contact.email})
          </Typography.Text>
        </div>
      ))
    );
  };

  const renderStatus = (status: MessageStatusEnum) => {
    const statuses = {
      [MessageStatusEnum.Published]: <Tag color="success">Completed</Tag>,
      [MessageStatusEnum.Delivered]: <Tag color="geekblue">Delivered</Tag>
    };

    return statuses[status] ? statuses[status] : <Tag color="default">Draft</Tag>;
  };

  const renderActions = (message: Message) => {
    return (
      <Space size="middle">
        {message.status !== MessageStatusEnum.Delivered && (
          <Link to={`/message/${message.id}`}>
            <Tooltip title="Edit Message">
              <EditOutlined />
            </Tooltip>
          </Link>
        )}
        <a onClick={() => deleteMessage(message.id)}>
          <Tooltip title="Remove Message">
            <DeleteOutlined />
          </Tooltip>
        </a>
        {message.status === MessageStatusEnum.Draft && (
          <Link to={`/message/${message.id}`} state={{ step: CreateMessageStepsEnum.Review }}>
            Complete
          </Link>
        )}
      </Space>
    );
  };

  const mobileColumns: ColumnsType<Message> = [
    {
      render: (message: Message) => (
        <Space size={4} direction="vertical" className="responsive-table-card">
          <Flex justify="space-between">
            <Flex align="center" gap="small">
              {renderTypeIcon(message.type)}
              <span className="responsive-table-title">
                <Typography.Text>{message.name}</Typography.Text>
              </span>
              {renderPreviewIcon(message)}
            </Flex>
            <div>{renderStatus(message.status)}</div>
          </Flex>
          <div>
            <strong>Send To:</strong> {renderContacts(message.contacts)}
          </div>
          <Flex justify="space-between">
            <div>
              <strong>Send On:</strong> {displayDeliveryDate(message)}
            </div>
            <div>{renderActions(message)}</div>
          </Flex>
        </Space>
      )
    }
  ];

  const columns: ColumnsType<Message> = [
    {
      title: 'Message Name',
      key: 'name',
      render: (_, message) => (
        <div className="message-title">
          <Typography.Text>
            {message.name} {renderPreviewIcon(message)}
          </Typography.Text>
        </div>
      )
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (type) => renderTypeIcon(type)
    },
    {
      title: 'Recipient(s)',
      key: 'recipient',
      dataIndex: 'contacts',
      render: (contacts: Contact[]) => renderContacts(contacts)
    },
    {
      title: 'Delivery Date',
      key: 'delivery',
      render: (_, message) => displayDeliveryDate(message)
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: MessageStatusEnum) => renderStatus(status)
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, message) => renderActions(message)
    }
  ];

  return (
    <>
      <div className="messages-component" data-tour="message-list">
        <h3>
          <Space size="large" align="center">
            <div>My Messages ({messagesData?.total || 0}) </div>
            {!!draftMessagesData?.total && (
              <Tag icon={<WarningOutlined />} color="warning" className="draft-warning">
                {draftMessagesData?.total} draft messages
              </Tag>
            )}
          </Space>
        </h3>

        <ResponsiveTable
          mobileUntilBreakpoint="lg"
          mobileColumns={mobileColumns}
          columns={columns}
          dataSource={messagesData?.list || []}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            current: currentPage,
            total: messagesData?.total || 0
          }}
          onChange={onTableChanged}
          rowKey="id"
          bordered
          loading={isFetching}
        />
      </div>

      <PreviewMessageModalComponent />
      <RemoveMessageModalComponent />
    </>
  );
}

export default MessageListComponent;
