import { useMeQuery } from 'api/AuthApi';
import { useGetGiftsQuery } from 'api/GiftsApi';
import { UserStatusEnum } from 'api/UsersApi';
import ReceivedGiftModal, { RECEIVED_GIFT_MODAL_NAME } from 'components/Modals/ReceivedGiftModal/ReceivedGiftModal';
import WarningDeceasedStatusModal, {
  WARNING_DECEASED_STATUS_MODAL_NAME
} from 'components/Modals/WarningDeceasedStatusModal/WarningDeceasedStatusModal';
import Beneficiaries from 'pages/Admin/Beneficiaries/Beneficiaries';
import Beneficiary from 'pages/Admin/Beneficiary/Beneficiary';
import ConfirmEmail from 'pages/Auth/ConfirmEmail/ConfirmEmail';
import ForgotPassword from 'pages/Auth/ForgotPassword/ForgotPassword';
import Login from 'pages/Auth/Login/Login';
import ResetPassword from 'pages/Auth/ResetPassword/ResetPassword';
import SignUp from 'pages/Auth/SignUp/SignUp';
import Account from 'pages/Client/Account/Account';
import Billing from 'pages/Client/Billing/Billing';
import Contacts from 'pages/Client/Contacts/Contacts';
import CreateMessage from 'pages/Client/CreateMessage/CreateMessage';
import Dashboard from 'pages/Client/Dashboard/Dashboard';
import MessageTemplates from 'pages/Client/MessageTemplates/MessageTemplates';
import Pricing from 'pages/Client/Pricing/Pricing';
import SelectCreateMessage from 'pages/Client/SelectCreateMessage/SelectCreateMessage';
import AffiliateLink from 'pages/Common/AffiliateLink/AffiliateLink';
import ContactUs from 'pages/Common/ContactUs/ContactUs';
import Faq from 'pages/Common/Faq/Faq';
import { GiftPage } from 'pages/Gift/GiftPage';
import { MessagePage } from 'pages/Messages/MessagePage';
import SysAdminGifts from 'pages/SysAdmin/Gifts/Gifts';
import SysAdminPayments from 'pages/SysAdmin/Payments/Payments';
import SysAdminReport from 'pages/SysAdmin/Report/Report';
import SysAdminReportUser from 'pages/SysAdmin/ReportUser/ReportUser';
import SysAdminUserDetails from 'pages/SysAdmin/UserDetails/UserDetails';
import SysAdminUsers from 'pages/SysAdmin/Users/Users';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { setAdBeaconReferrer } from 'store/ecommerceSlice';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import PrivateRoutes from 'utils/Router/PrivateRoutes';
import UnauthorizedRoutes from 'utils/Router/UnauthorizedRoutes';
import { getReferrer } from 'utils/utils';
import './App.css';

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [referrer, setReferrer] = useState<string>(getReferrer(location));

  const { refetch: refetchMe, data: user } = useMeQuery();
  const { data: gifts } = useGetGiftsQuery(undefined, { skip: !user });

  useEffect(() => {
    if (referrer !== getReferrer(location)) {
      dispatch(setAdBeaconReferrer(referrer));

      setReferrer(getReferrer(location));
    }
  }, [location]);

  useEffect(() => {
    const timer = setInterval(refetchMe, 5 * 60 * 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (user && (user.status === UserStatusEnum.UNVERIFIED_DECEASED || user.status === UserStatusEnum.DECEASED)) {
      dispatch(openModal({ name: WARNING_DECEASED_STATUS_MODAL_NAME }));
    }
  }, [user && user.status]);

  useEffect(() => {
    if (gifts?.length) {
      dispatch(openModal({ name: RECEIVED_GIFT_MODAL_NAME, data: { gift: gifts[0] } }));
    }
  }, [gifts]);

  return (
    <div className="App">
      <Routes>
        <Route path="/messages/:messageContactId" element={<MessagePage />} />
        <Route path="/partner/:affiliateCode" element={<AffiliateLink />} />
        <Route path="/gift" element={<GiftPage />} />

        <Route element={<UnauthorizedRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/confirm-email" element={<ConfirmEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<SelectCreateMessage />} />
          <Route path="/message" element={<CreateMessage />} />
          <Route path="/message/:messageId" element={<CreateMessage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/beneficiaries" element={<Beneficiaries />} />
          <Route path="/beneficiaries/:adminAccessId" element={<Beneficiary />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/message-templates" element={<MessageTemplates />} />
          <Route path="/account" element={<Account />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/sysadmin">
            <Route index element={<Navigate to="/sysadmin/users" />} />
            <Route path="users" element={<SysAdminUsers />} />
            <Route path="users/:userId" element={<SysAdminUserDetails />} />
            <Route path="payments" element={<SysAdminPayments />} />
            <Route path="gifts" element={<SysAdminGifts />} />
            <Route path="report" element={<SysAdminReport />} />
            <Route path="report/:userId" element={<SysAdminReportUser />} />
          </Route>
        </Route>
      </Routes>

      <ReceivedGiftModal />
      <WarningDeceasedStatusModal />
      {/*<FloatTimerComponent firstLoginDate={user?.firstLoginDate || null} />*/}
    </div>
  );
}

export default App;
