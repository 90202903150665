import { PaymentPlanPrice } from 'api/PaymentApi';
import { GiftData } from 'components/CreateGiftComponent/CreateGiftComponent';

const SESSION_GIFT_DATA_ID = 'giftData';

type GiftStorage = Partial<GiftData> & {
  price?: PaymentPlanPrice;
  createdAt?: number;
};

export const saveGiftToStorage = (data: GiftStorage) => {
  sessionStorage.setItem(
    SESSION_GIFT_DATA_ID,
    JSON.stringify({ ...retrieveGiftFromStorage(), ...data, createdAt: new Date().getTime() })
  );
};

export const retrieveGiftFromStorage = (): GiftStorage | null => {
  const data = sessionStorage.getItem(SESSION_GIFT_DATA_ID);
  try {
    const parsedData: GiftStorage | null = data ? JSON.parse(data) : null;
    if (parsedData?.createdAt && new Date().getTime() - parsedData.createdAt < 30 * 60 * 1000) {
      return parsedData;
    }
  } catch (e) {}
  return null;
};

export const clearGiftStorage = () => sessionStorage.removeItem(SESSION_GIFT_DATA_ID);
