import WelcomeModalComponent, {
  WELCOME_MODAL_NAME
} from 'components/Account/WelcomeModalComponent/WelcomeModalComponent';
import SelectCreateMessageComponent from 'components/Message/SelectCreateMessageComponent/SelectCreateMessageComponent';
import ContainerComponent from 'components/Tool/ContainerComponent/ContainerComponent';
import Footer from 'pages/Client/Footer/Footer';
import Header from 'pages/Client/Header/Header';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'store/authSlice';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './SelectCreateMessage.css';

function SelectCreateMessage() {
  const dispatch = useAppDispatch();
  const user = useSelector(selectAuthUser);

  useEffect(() => {
    if (user && !user.isInitialized) {
      dispatch(openModal({ name: WELCOME_MODAL_NAME }));
    }
  }, [user]);

  return (
    <>
      <div className="dashboard layout-full-height">
        <Header />

        <ContainerComponent className="layout-main-content">
          <SelectCreateMessageComponent />
        </ContainerComponent>

        <Footer />
      </div>

      {/*{user?.isInitialized && user?.status !== UserStatusEnum.UNVERIFIED_DECEASED && <TourComponent />}*/}
      <WelcomeModalComponent />
    </>
  );
}

export default SelectCreateMessage;
