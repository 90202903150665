import { Button, Modal } from 'antd';
import { Gift, useApplyGiftMutation } from 'api/GiftsApi';
import { useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalName } from 'store/modalSlice';
import { useAppDispatch } from 'store/store';
import './ReceivedGiftModal.css';

export const RECEIVED_GIFT_MODAL_NAME = 'RECEIVED_GIFT_MODAL_NAME';

type AllProps = {
  gift: Gift;
};

function ReceivedGiftModal({ gift }: AllProps) {
  const dispatch = useAppDispatch();

  const [applyGift, { isLoading }] = useApplyGiftMutation();

  const handleIgnore = async () => {
    dispatch(closeModal());
  };

  const handleUseGift = async () => {
    try {
      await applyGift({ id: gift.id }).unwrap();

      // dispatch(addCreditAmount(gift.creditAmount));
      dispatch(closeModal());
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal open={true} footer={null} onCancel={handleIgnore}>
      <>
        <h2 className="text-center">You Have a Gift</h2>
        <p>
          {gift.senderName && (
            <>
              <strong>From:</strong> {gift.senderName}
            </>
          )}
        </p>
        <p>{gift.senderText}</p>
        <div className="text-center">
          <Button type="primary" loading={isLoading} onClick={handleUseGift}>
            Claim Gift
          </Button>
        </div>
      </>
    </Modal>
  );
}

function ReceivedGiftModalWrapper() {
  const modalName = useSelector(selectModalName);
  const modalData = useSelector(selectModalData);
  const gift = modalData?.gift;

  return modalName === RECEIVED_GIFT_MODAL_NAME ? <ReceivedGiftModal gift={gift} /> : null;
}

export default ReceivedGiftModalWrapper;
